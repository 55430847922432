/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Country = {
  id: number
  name: string
  abbr: string
  abbr1: string
  code: string
}

type CoutriesState = {
  countries: Country[]
}

const initialState: CoutriesState = {
  countries: [
    {
      id: 33,
      name: 'Brasil',
      abbr: 'BR',
      abbr1: 'BRA',
      code: '076',
    },
    {
      id: 68,
      name: 'Estados Unidos',
      abbr: 'US',
      abbr1: 'USA',
      code: '840',
    },
  ],
}

export const coutriesReducer = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setCountriesList: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload
    },
  },
})

export const { setCountriesList } = coutriesReducer.actions

export default coutriesReducer.reducer
