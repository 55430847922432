import { GameItemTeams } from '../Games/types/games'

export type BetStatus = 'lose' | 'win' | 'pending' | 'contested'
export type OddStatus = 'error' | 'success' | 'blank'

export type BetOdd = {
  // Aposta parcial
  oddInfo: {
    // Odd da aposta parcial
    betGamesOddId: string // Identificador da odd
    status: OddStatus
    market_id: string | number | null // Identificador do mercado da odd
    name: string // Nome da odd
    rate: number // Taxa da odd
    specifier_value: number | null // Valor dos especificador
  }
  gameInfo: {
    // Informacoes do jogo da odd
    gameStatus: 'notStarted' | 'live' | 'finished' // Status do jogo
    betGameId: number // Identificador do jogo
    gameStart?: string // Data e hora de inicio do jogo
    location: string // Local do jogo
    teams: GameItemTeams[]
  }
}

export type Bet = {
  odds: BetOdd[] // Vetor com todas as apostas parciais feitas
  betInfo: {
    betId: string
    betStatus: BetStatus // Status atual da aposta
    ableToFinish: boolean // Se e permitido finalizar a posta
    price: string // Valor total gasto no conjunto de apostas ou aposta unica
    potencialReturns: string // Retorno se a aposta for ganha
    returnOffered?: string | number // O quanto a casa paga o usuario encerrar a aposta
  }
}

export type MyBetResponse = {
  success: boolean
  data: Bet[]
}

export type MyBetRoutes = 'finish' | 'live' | 'pending' | 'finished' | 'all'

// export const externalMyBetsData: MyBetResponse = {
//   success: true,
//   data: [
//     {
//       betInfo: {
//         betId: '1',
//         price: '123.0',
//         potencialReturns: '123908.77',
//         ableToFinish: false,
//         betStatus: 'pending',
//       },
//       odds: [
//         {
//           oddInfo: {
//             betGamesOddId: '476',
//             market_id: null,
//             name: 'Casa',
//             oddStatus: 'blank',
//             rate: 1.01,
//             specifier_value: null,
//           },
//           gameInfo: {
//             betGameId: 31652929,
//             gameStatus: 'live',
//             location: '',
//             teams: [
//               {
//                 id: 9,
//                 name: 'Santos SP Sub-20',
//               },
//               {
//                 id: 10,
//                 name: 'América MG Sub-20',
//               },
//             ],
//           },
//         },
//         {
//           oddInfo: {
//             betGamesOddId: '333',
//             market_id: null,
//             name: 'Empate',
//             oddStatus: 'lose',

//             rate: 3.26,
//             specifier_value: null,
//           },
//           gameInfo: {
//             betGameId: 27751500,
//             gameStatus: 'finished',
//             location: '',
//             teams: [
//               {
//                 id: 15,
//                 name: 'Everton FC',
//               },
//               {
//                 id: 16,
//                 name: 'Aston Villa FC',
//               },
//             ],
//           },
//         },
//       ],
//     },
//     {
//       betInfo: {
//         betId: '2',
//         price: '123.0',
//         potencialReturns: '123.01',
//         ableToFinish: false,
//         betStatus: 'finished',
//       },
//       odds: [
//         {
//           oddInfo: {
//             betGamesOddId: '476',
//             market_id: null,
//             name: 'Casa',
//             oddStatus: 'win',

//             rate: 1.01,
//             specifier_value: null,
//           },
//           gameInfo: {
//             betGameId: 31652929,
//             gameStatus: 'live',
//             location: '',
//             teams: [
//               {
//                 id: 9,
//                 name: 'Santos SP Sub-20',
//               },
//               {
//                 id: 10,
//                 name: 'América MG Sub-20',
//               },
//             ],
//           },
//         },
//         {
//           oddInfo: {
//             betGamesOddId: '346',
//             market_id: null,
//             name: 'Fora',
//             oddStatus: 'blank',
//             rate: 1.29,
//             specifier_value: null,
//           },
//           gameInfo: {
//             betGameId: 27751508,
//             gameStatus: 'finished',
//             location: '',
//             teams: [
//               {
//                 id: 23,
//                 name: 'Southampton FC',
//               },
//               {
//                 id: 24,
//                 name: 'Manchester City FC',
//               },
//             ],
//           },
//         },
//       ],
//     },
//   ],
// }

// enum routeOptions {
//   finish = 'finish',
//   live = 'live',
//   pending = 'pending',
//   finished = 'finished',
//   all = 'all',
// }
type NoBetProps = { [key in MyBetRoutes]: string }

export const NoBetsDiscription: NoBetProps = {
  finish:
    'Apostas que podem ser encerradas em parte ou totalmente aparecerão aqui',
  live: 'As apostas ao vivo aparecerão aqui',
  pending: 'As apostas pendentes aparecerão aqui',
  finished: 'As apostas resolvidas nas últimas 24 horas aparecerão aqui',
  all: 'As apostas aparecerão aqui por 24 horas',
}
