import { GameRoute } from '../Games/views/GameDetails/GameDetailsNavigator'

export const myBetsRoutes: GameRoute[] = [
  {
    label: 'Encerrar aposta',
    route: 'finish',
    listOfIds: [], // TEMP Markets will not be here
  },
  {
    label: 'Ao vivo',
    route: 'live',
    listOfIds: [],
  },
  {
    label: 'Pendentes',
    route: 'pending',
    listOfIds: [],
  },
  {
    label: 'Resolvidas',
    route: 'finished',
    listOfIds: [],
  },
  {
    label: 'Todas',
    route: 'all',
    listOfIds: [],
  },
]
