import { Bet } from '../../data'
import { BetOddCard } from './BetOddCard'

interface Props {
  bet: Bet
}

export const BetCardBody = ({ bet }: Props) => {
  return (
    // <div className="container g-0">
    <div className="bet-card-body row g-0">
      {bet.odds.map((odd) => {
        return <BetOddCard key={odd.oddInfo.betGamesOddId} odd={odd} />
      })}
    </div>
    // </div>
  )
}
