import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getOddsByMarket } from '../../../../../../../../../common/functions/odds'
import { GameItem } from '../../../../types/games'
import { GameDropdown } from '../../../GameDetails/GameDropdown'
import { GamesCardsHeader } from '../../GamesCardsHeader'
import { GameTableRow } from '../../GameTableRow'
import './style.scss'

export type GroupCardItemProps = {
  title: string
  groupedGames: GameItem[]
  chosenMarketId: number
  index: number
}

type DropdownBodyProps = {
  gameList: GameItem[]
  chosenMarketId: number
}

const DropdownBody = ({ gameList, chosenMarketId }: DropdownBodyProps) => {
  return (
    <div>
      {gameList.map((gameItem) => {
        return (
          <GameTableRow
            key={`tableRow-${gameItem.info.betGameId}`}
            gameItem={gameItem}
            chosenMarketId={chosenMarketId}
          />
        )
      })}
    </div>
  )
}

export const GroupCardItem = ({
  groupedGames,
  chosenMarketId,
  title,
  index,
}: GroupCardItemProps) => {
  console.log('chosenMarketId', chosenMarketId)
  const { t } = useTranslation()
  const firstItemOddsByMarket = getOddsByMarket(
    chosenMarketId,
    groupedGames[0]?.odds,
  )
  const [isCardOpen, setisCardOpen] = useState<boolean>(index < 1)
  const handleChangeOpenState = (isDropdownOpen: boolean) => {
    setisCardOpen(!isDropdownOpen)
  }

  return (
    <GameDropdown
      initialOpenState={index === 0}
      onChangeOpenState={handleChangeOpenState}
      key={0}
      header={
        <GamesCardsHeader
          hideOdds={!isCardOpen}
          name={t(title)}
          firstItemOdds={firstItemOddsByMarket}
          className="groupTableHeader"
          firstItemTeams={groupedGames[0].teams}
        />
      }
      body={
        <DropdownBody gameList={groupedGames} chosenMarketId={chosenMarketId} />
      }
    />
  )
}
