/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { NumberFormatValues } from 'react-number-format'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../core/store/Reducers'
import {
  changeOddSelection,
  onChangeOddPrice,
} from '../../../../Games/store/gameList'
import { oddChecked, Odds } from '../../../../Games/types/odds'
import { ContainedCard } from './Card/ContainedCard'
import { ExpandedCard } from './Card/ExpandedCard'
import { MultipleBody } from './Multiple/MultipleBody'
import './WagersBody.css'

interface Props {
  total: number
  oddsChecked: oddChecked[]
  mustExpand: boolean
  submited: boolean
  isMobile: boolean
  refWagerModal: React.MutableRefObject<HTMLDivElement | undefined>
}
export const WagersBody = ({
  total,
  oddsChecked,
  mustExpand,
  refWagerModal,
  submited,
  isMobile,
}: Props) => {
  const dispatch = useAppDispatch()
  const { cardIsExpanded } = useAppSelector((state: RootState) => state.wagers)
  // const MAX_VAL = 1400
  // const withValueLimit = ({ floatValue: any }) => floatValue <= MAX_VAL
  // useEffect(() => {
  //   oddsChecked
  // }, [oddsChecked])
  const inputMaxLength = ({ value }: NumberFormatValues) => value.length <= 5

  const handlerOddRemove = async (odd: Odds) => {
    await dispatch(changeOddSelection(odd))
  }

  // const RenderMultiples = () => {
  //   if (showAllMultiples)
  //     for (let i = 1; i < total; i = +1) {
  //       return <MultipleCard />
  //     }
  //   return <></>
  // }

  const handlerPriceChange = (odd: Odds, price: number | undefined) => {
    dispatch(
      onChangeOddPrice({
        odd,
        price,
      }),
    )
  }
  // console.log(oddsChecked)
  // useEffect(() => {
  //   console.log('MUST EXPAND', mustExpand)
  // }, [mustExpand])

  return (
    <div id="wager_body" className="container-fluid wager_item g-0">
      {oddsChecked.map((item, index) => {
        // console.log('ITEEEEEM  dd', item)
        if (mustExpand) {
          return (
            <ExpandedCard
              key={item.odd.betGamesOddId}
              item={item}
              submited={submited}
              // isMobile={isMobile}
              index={index}
              handlerPriceChange={handlerPriceChange}
              refWagerModal={refWagerModal}
            />
          )
        }
        return (
          <ContainedCard
            item={item}
            key={item.odd.betGamesOddId}
            submited={submited}
          />
        )
      })}
      {total > 1 && (
        <MultipleBody
          total={total}
          refWagerModal={refWagerModal}
          oddsChecked={oddsChecked}
          submited={submited}
        />
      )}
      {/* {total > 1 && (
        <>
          <RenderMultiples />
        </>
      )} */}
    </div>
  )
}
