import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

interface Props {
  name: string
  setValue: any
  disabled?: boolean
  control: any
  // label?: string
  // placeholder?: string
  errors: any
  className?: string
  onBlurFunc?: () => void | Promise<void>
  trigger: any
  defaultValue?: string
}

export const CPFInput = ({
  name,
  control,
  // placeholder,
  // label,
  disabled,
  errors,
  setValue,
  className,
  onBlurFunc,
  trigger,
  defaultValue,
}: // formType,
Props) => {
  // const registerFunction = register as UseFormRegister<typeof formType>
  // const dddMaxLength = ({ value }: NumberFormatValues) => value.length <= 2

  return (
    <div key={name} className="col-12">
      <div className="inputContainer">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <NumberFormat
              // {...register(`${name}`)}
              name={name}
              value={value}
              onChange={onChange}
              className="form-field"
              format="###.###.###-##"
              placeholder=" "
              disabled={disabled}
              onValueChange={(v) => {
                setValue(name, v.value)
                trigger(name)
              }}
              // defaultValue={defaultValue}
              onBlur={() => trigger(name)}
            />
          )}
        />
        <label className="formPlaceholder" htmlFor={name}>
          CPF
        </label>
      </div>
      {errors[name]?.message && (
        <p className="input-error-warning">{errors[name]?.message}</p>
      )}
    </div>
  )
}
CPFInput.defaultProps = {
  disabled: false,
  defaultValue: null,
  className: '',
  onBlurFunc: null,
}
