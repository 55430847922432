export const getOddsChecked = () => {
  // const wagersSelectionTmp = [] as oddChecked[]
  // const wagersPersist = [] as OddsPersisted[]
  // gameList.items.forEach((item) => {
  //   const checkedOddsItens = item.odds.filter((odd) => odd.checked === true)
  //   if (checkedOddsItens.length) {
  //     checkedOddsItens.forEach((odd) => {
  //       if (odd.price) totalPrize += odd.price * odd.rate
  //       wagersSelectionTmp.push({ info: item.info, odd })
  //       wagersPersist.push({
  //         betGamesOddId: odd.betGamesOddId,
  //         price: odd.price,
  //       })
  //     })
  //   }
  // })
}

export const removeErrorMarkOnInputOddPrice = (props: {
  refWagerModal: React.MutableRefObject<HTMLDivElement | undefined>
}) => {
  const { refWagerModal } = props
  if (refWagerModal && refWagerModal.current) {
    const inputs = refWagerModal.current.querySelectorAll<HTMLInputElement>(
      'input.oddPriceDefault',
    )
    inputs.forEach((item) => {
      item.classList.remove('wager_ipt_error')
    })
  }
}

export const addErrorMarkOnInputOddPrice = (props: {
  refWagerModal: React.MutableRefObject<HTMLDivElement | undefined>
}) => {
  const { refWagerModal } = props
  if (refWagerModal && refWagerModal.current) {
    const inputs = refWagerModal.current.querySelectorAll<HTMLInputElement>(
      'input.oddPriceDefault',
    )
    inputs.forEach((item) => {
      item.classList.remove('wager_ipt_error')
      if (!item.value.trim()) {
        setTimeout(() => {
          item.classList.add('wager_ipt_error')
        }, 1)
      }
    })
  }
}
