import axios from 'axios'
import apiHelper from '../../../../../../core/services/apiHelper'
import { RegisterFormData } from '../types'

/**
 *  Conta do usuário
 * @param token
 * @returns
 */

// type SignUserProps = {
//   item: any
// }

export async function SignUser(data: RegisterFormData): Promise<any> {
  try {
    const newUser = {
      name: `${data.firstname} ${data.lastname}`,
      username: data.username,
      password: data.password,
      email: data.email,
      cpf: data.cpf,
      code: data.agentCode.toLowerCase(),
      idx: data.agentId,
      countryId: data.country,
    }

    const response = await axios
      .request({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: apiHelper.baseUrl('sign'),
        data: newUser,
      })
      .then((r) => {
        return r.data
      })
      .catch((error) => {
        return error
      })
    return response
  } catch (err) {
    console.log(err)
  }
}

export async function GetAgentInfo(agentCode: string): Promise<any> {
  try {
    const response = await axios
      .request({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: apiHelper.baseUrl(`agent/getInfo/${agentCode.toLowerCase()}`),
      })
      .then((r) => {
        return r.data
      })
      .catch((error) => {
        return error
      })
    return response
  } catch (err) {
    console.log(err)
  }
}
