import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import creditsReducer from '../../app/bet/Auth/views/Configs/Bank/store/credits'
import paymentReducer from '../../app/bet/Auth/views/Configs/Bank/store/payment'
import walletReducer from '../../app/bet/Auth/views/Wallet/store/wallet'
import dashboardReducer from '../../app/bet/Global/views/Dashboard/store/dashboard'
import gameListReducer from '../../app/bet/Global/views/Games/store/gameList'
import marketsReducer from '../../app/bet/Global/views/Games/store/markets'
import oddsReducer from '../../app/bet/Global/views/Games/store/odds'
import sportReducer from '../../app/bet/Global/views/Sports/store/sport'
import wagersReducer from '../../app/bet/Global/views/Wagers/store/wagers'
import appReducers from './items/app'
import coutriesReducer from './items/countries'
import menuReducer from './items/menu'
import toastReducers from './items/toasts'
import userReducer from './items/user'

const store = configureStore({
  reducer: {
    app: appReducers,
    menu: menuReducer,
    sports: sportReducer,
    // games: gameReducer,
    gameList: gameListReducer,
    user: userReducer,
    toast: toastReducers,
    odds: oddsReducer,
    credits: creditsReducer,
    payment: paymentReducer,
    wagers: wagersReducer,
    countries: coutriesReducer,
    markets: marketsReducer,
    dashboard: dashboardReducer,
    wallet: walletReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
