import { useState } from 'react'
import { WithdrawPixForm } from '../../components/Forms/Withdraw/Pix/WithdrawPix'
import { WithdrawTransferForm } from '../../components/Forms/Withdraw/WithdrawTransferForm'

type WithdrawOptionsValueType = 'transfer' | 'pix'
type WithdrawOptionsType = { label: string; value: WithdrawOptionsValueType }

export const BankWithdraw = () => {
  const [currentMethod, setCurrentMethod] =
    useState<WithdrawOptionsValueType>('pix')
  const withDrawOptions: WithdrawOptionsType[] = [
    { label: 'PIX', value: 'pix' },
    { label: 'TRÂNSFERENCIA BANCÁRIA', value: 'transfer' },
  ]
  const handleChangeMethod = (value: WithdrawOptionsValueType) => {
    setCurrentMethod(value)
  }

  const chooseWithdrawForm = (chosenMethod: WithdrawOptionsValueType) => {
    switch (chosenMethod) {
      case 'pix':
        return <WithdrawPixForm />
      case 'transfer':
        return <WithdrawTransferForm />
      default:
        return <WithdrawPixForm />
    }
  }
  return (
    <>
      <h1 className="minimalTitle d-none d-md-block">Gerenciar Saques</h1>
      <div className="form-field-column col-md-12" key="selectMethod">
        <label style={{ paddingBottom: '5px' }} htmlFor="selectMethod">
          Método de saque
        </label>
        <select
          className="form-field selectInput"
          onChange={(e) =>
            handleChangeMethod(e.target.value as WithdrawOptionsValueType)
          }>
          {withDrawOptions.map(({ label, value }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            )
          })}
        </select>
      </div>
      <div>
        <p>
          Esta funcionalidade oferece uma variedade de opções para ajudar a
          gerenciar seus saques:
        </p>
      </div>
      {chooseWithdrawForm(currentMethod)}
    </>
  )
}
