import axios from 'axios'
import apiHelper from '../../../../../../../../../../core/services/apiHelper'
import { WithdrawPixFormData } from './types'

/**
 *  Conta do usuário
 * @param token
 * @returns
 */

// type SignUserProps = {
//   item: any
// }

export async function WithdrawPixRequest(
  data: WithdrawPixFormData,
): Promise<any> {
  try {
    const response = await axios
      .request({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: apiHelper.baseUrl('sign'),
        data,
      })
      .then((r) => {
        return r.data
      })
      .catch((error) => {
        return error
      })
    return response
  } catch (err) {
    console.log(err)
  }
}
