import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { setModalForgot } from '../../../../../core/store/items/app'
// import { setLoggedIn } from '../../../../../core/store/items/user'
import {
  // RootState,
  useAppDispatch,
} from '../../../../../core/store/Reducers'
import { FormForgot } from './FormForgot'
// import { ForgotErrorBox } from './components/ForgotErrorBox'
import './ModalForgot.scss'
// import { FormForgotValues } from './type/forgot'
// import { onPostFormForgotRequest } from './xhr/auth.xhr'

export const ModalForgot = () => {
  // const user = useAppSelector((state: RootState) => state.user)
  const refModalForgotBody = useRef() as MutableRefObject<HTMLDivElement>
  const dispatch = useAppDispatch()
  // const [onPostError, setOnPostError] = useState<boolean | undefined>(undefined)
  const [isModalLoaded, setIsModalLoaded] = useState(false)

  const onHideModalForgot = () => {
    dispatch(setModalForgot(false))
  }

  // #START MINIMIZE MODAL WHEN CLICK OUTSIDE
  useEffect(() => {
    function handlerOutsideModalForgot(e: { target: any }) {
      const el = e.target.closest('div[id=modalForgotBody]')
      const formBody = e.target.closest('form[id=formBody]')

      // clicou no svg do display input password?
      const isClickOnPasswordDisplay = e.target.closest('.isShowPassword')

      // console.log(el, formBody)
      if (!el && !formBody && !isClickOnPasswordDisplay) {
        // console.log('click outside modal forgot')
        onHideModalForgot()
      }
    }
    if (isModalLoaded) {
      // console.log('BINDOU CLICK addEventListener')
      document.body.addEventListener('click', handlerOutsideModalForgot)
    }
    return () => {
      document.body.removeEventListener('click', handlerOutsideModalForgot)
    }
  }, [dispatch, isModalLoaded])
  // #END MINIMIZE MODAL WHEN CLICK OUTSIDE

  useEffect(() => {
    refModalForgotBody.current.style.transform = `translateY(105px)`
    setIsModalLoaded(true)
  }, [])

  // transform: translateY(105px);
  return (
    <div id="modalForgot">
      <div id="modalForgotDefault">
        <div className="overlay" />
        <div id="modalForgotBody" ref={refModalForgotBody}>
          <FormForgot />
        </div>
      </div>
    </div>
  )
}
