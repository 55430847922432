// project imports
// import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import MainLayout from '../../Global/layout/MainLayout/MainLayout'
import { Dashboard } from '../../Global/views/Dashboard/views/Dashboard'
import GameDetails from '../../Global/views/Games/views/GameDetails/GameDetails'
import { LiveGames } from '../../Global/views/Live/LiveGames'
import { MyBets } from '../../Global/views/MyBets/MyBets'
import { Championship } from '../../Global/views/Sports/views/Championship/Championship'
import { Sports } from '../../Global/views/Sports/views/Sports'
import { SporstNavigator } from '../../Global/views/Sports/views/SportsNavigator'
import { SportTables } from '../../Global/views/Sports/views/SportTables/SportTables'
import { ChangePassword } from '../views/Account/items/ChangePassword'
import { Address } from '../views/Account/items/Endereco'
import { PersonalData } from '../views/Account/items/PersonalData'
import { Preferences } from '../views/Account/items/Preferences/Preferences'
import { Verification } from '../views/Account/items/Verificacao'
import { AuthNavigator } from '../views/AuthNavigator'
import { BankBalance } from '../views/Configs/Bank/items/BankBalance'
import { BankFund } from '../views/Configs/Bank/items/BankFunds'
import { BankHistory } from '../views/Configs/Bank/items/BankHistory'
import { BankTransfers } from '../views/Configs/Bank/items/BankTransfers'
import { BankWithdraw } from '../views/Configs/Bank/items/BankWithdraw/BankWithdraw'
import { GameNavigator } from '../views/GameNavigator'
import { Logout } from '../views/Logout/Logout'
import { AccountRoutes } from './RoutesList/AccountRoutes'
import { BankRoutes } from './RoutesList/BankRoutes'

const RoutesAuth = {
  path: '/',
  element: <MainLayout isAuth />,
  children: [
    {
      path: '/',
      element: <Dashboard />,
    },
    {
      path: '/signup',
      index: true,
      element: <Navigate to="/" replace />,
    },
    {
      path: '/bank',
      element: <AuthNavigator title="Banco" routes={BankRoutes} />,
      children: [
        {
          path: '',
          index: true,
          element: <Navigate to="/bank/balance" replace />,
        },
        { path: 'balance', isMenu: true, element: <BankBalance /> },
        { path: 'withdraw', element: <BankWithdraw /> },
        { path: 'history', element: <BankHistory /> },
        {
          path: 'transfer',
          element: <BankTransfers />,
        },
        { path: 'funds', index: true, element: <BankFund /> },
      ],
    },
    {
      path: '/account',
      element: <AuthNavigator title="Minha Conta" routes={AccountRoutes} />,
      children: [
        {
          path: '',
          index: true,
          element: <Navigate to="/account/personal" replace />,
        },
        { path: 'personal', index: true, element: <PersonalData /> },
        { path: 'address', index: true, element: <Address /> },
        { path: 'preferences', index: true, element: <Preferences /> },
        { path: 'password', index: true, element: <ChangePassword /> },
        { path: 'verification', index: true, element: <Verification /> },
      ],
    },
    {
      path: '/games',
      element: <GameNavigator />,
      children: [
        {
          path: '/games/sport',
          element: <SporstNavigator />,
          children: [
            { path: '/games/sport/:sportRoute', element: <Sports /> },
            {
              path: '/games/sport/:sportRoute/:championshipId',
              element: <Championship />,
            },
            {
              path: '/games/sport/:sportRoute/lists/:listType',
              element: <SportTables />,
            },
            {
              path: '/games/sport/:sportRoute/:championshipId/:gameId',
              element: <GameDetails />,
            },
          ],
        },
      ],
    },
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      path: '/live',
      element: <LiveGames />,
    },
    {
      path: '/mybets',
      element: <MyBets />,
    },
    // {
    //   path: 'search',
    //   element: <Search />,
    // },
    {
      path: '/signup',
      element: <Navigate to="/" replace />,
    },
    {
      path: '/sign/active',
      element: <Navigate to="/" replace />,
    },
  ],
}

export default RoutesAuth
