// import { UseFormRegister } from 'react-hook-form'
// import { PreferencesFormData } from '../../../Auth/views/Account/items/Preferences/Preferences'
import './style.scss'

interface Props {
  type: 'password' | 'text' | 'email' | 'date' | 'number'
  disabled?: boolean
  // formType: (arg: Type) => Type
  register: any
  name: string
  label?: string
  placeholder?: string
  errors: any
  className?: string
  leftIcon?: JSX.Element
  defaultValue?: string | number
  onBlurFunc?: () => void | Promise<void>
}

export const MainInput = ({
  type,
  name,
  placeholder,
  disabled,
  label,
  register,
  errors,
  className,
  leftIcon,
  defaultValue,
  onBlurFunc,
}: // formType,
Props) => {
  // const registerFunction = register as UseFormRegister<typeof formType>
  return (
    <div key={name} className={className ? `col ${className}` : `col-12 `}>
      <div className={`inputContainer ${label ? 'withLabel' : ''}`}>
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        {leftIcon && <div className="leftIconContainer">{leftIcon}</div>}
        <input
          className={`form-field  ${leftIcon ? 'inputWithLeftIcon' : ''}`}
          type={type}
          placeholder=" "
          onBlur={onBlurFunc ? () => onBlurFunc() : null}
          defaultValue={defaultValue}
          {...register(`${name}`)}
          disabled={disabled}
        />
        {placeholder && (
          <label className="formPlaceholder" htmlFor={name}>
            {placeholder}
          </label>
        )}
      </div>
      {errors[name]?.message && (
        <p className="input-error-warning">{errors[name]?.message}</p>
      )}
    </div>
  )
}
MainInput.defaultProps = {
  label: '',
  placeholder: null,
  className: '',
  onBlurFunc: null,
  leftIcon: null,
  defaultValue: null,
  disabled: false,
}
