import { useEffect, useState } from 'react'
import { hasLiveGame } from '../../../../../common/functions/odds'
import { setMenuLeft } from '../../../../../core/store/items/app'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../core/store/Reducers'
import {
  GameDetailsNavigator,
  GameRoute,
} from '../Games/views/GameDetails/GameDetailsNavigator'
import { SideOffers } from '../Offers/SideOffers'
import { setHideWager } from '../Wagers/store/wagers'
import { BetCard } from './components/Card/BetCard'
import { BetCardSkeleton } from './components/Card/BetCardSkeleton'
import { NoBetsAvailable } from './components/Info/NoBetsAvailable'
import { Bet, MyBetResponse, MyBetRoutes, NoBetsDiscription } from './data'
import './MyBets.scss'
import { myBetsRoutes } from './routes'
import getMyBets from './xhr/myBets'

export const MyBets = () => {
  const dispatch = useAppDispatch()
  const app = useAppSelector((state: RootState) => state.app)

  const [activeRoute, setActiveRoute] = useState<GameRoute>(myBetsRoutes[0])
  const [myBetsData, setMyBetsData] = useState([] as Bet[])
  const [loading, setLoading] = useState(true)
  const handleChangeGameRoute = (route: GameRoute) => {
    setActiveRoute(route)
  }

  const loadBetsData = async () => {
    try {
      const response: MyBetResponse = await getMyBets()
      setMyBetsData(response.data)
      setLoading(false)
      console.log('Bets', response)
    } catch (err) {
      console.log(err)
    }
  }

  const validBetsRoute = [] as GameRoute[]

  myBetsRoutes.forEach((item) => {
    // console.log(item)
    // eslint-disable-next-line no-empty
    if (!app.client.isWagerCloseBefore && item.route === 'finish') {
      // eslint-disable-next-line no-empty
    } else if (!app.client.isWagerLived && item.route === 'live') {
    } else {
      validBetsRoute.push(item)
    }
  })

  useEffect(() => {
    setActiveRoute(validBetsRoute[0])
  }, [])
  console.log(validBetsRoute)
  // const validBetsRoutes = (myBetsRoutes) => {
  //   let arr = []

  //   if (app.client.isWagerCloseBefore && item.route === 'finish') {
  //   }
  //   if (app.client.isWagerLived && item.route === 'live') {
  //     return []
  //   }
  // }
  // myBetsRoutes.filter(item => item.route)

  // const validBetsRoutesx = myBetsRoutes.map((item) => {
  //   // if (app.client.isWagerCloseBefore && item.route === 'finish') {
  //   // }
  //   // if (app.client.isWagerLived && item.route === 'live') {
  //   //   return []
  //   // }
  //   return item
  // })
  // console.log(myBetsRoutes)
  // route: 'finish',
  // route: 'live',
  // route: 'pending',
  // route: 'finished',
  // route: 'all',

  const filterBetDataByRoute = (routeName: MyBetRoutes) => {
    switch (routeName) {
      case 'finish':
        return myBetsData.filter((bet) => bet.betInfo.ableToFinish)
      case 'live':
        return myBetsData.filter((bet) => hasLiveGame(bet.odds))
      case 'pending':
        return myBetsData.filter((bet) => bet.betInfo.betStatus === 'pending')
      case 'finished':
        return myBetsData.filter(
          (bet) =>
            bet.betInfo.betStatus === 'lose' || bet.betInfo.betStatus === 'win',
        )
      default:
        return myBetsData
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(setMenuLeft(false))
    dispatch(setHideWager(false))
    loadBetsData()
  }, [])

  const cardsSkeletons = (quantity: number) => {
    return (
      <div>
        {[...Array(quantity)].map((i) => {
          return <BetCardSkeleton key={Math.random() * 999} />
        })}
      </div>
    )
  }
  const betDataFiltered = filterBetDataByRoute(activeRoute.route as MyBetRoutes)
  return (
    // <div className="col-12" style={{ height: '100%' }}>
    <div className="row g-0" style={{ height: '100%' }}>
      <div className="col-12 col-md-8 myBets_MainPageView">
        <GameDetailsNavigator
          routes={validBetsRoute}
          activeRoute={activeRoute}
          handleChangeGameRoute={handleChangeGameRoute}
        />
        <div
          style={{
            height: '100%',
            overflowY: 'auto',
            paddingBottom: '3rem',
          }}>
          {loading && cardsSkeletons(3)}
          {!loading &&
            betDataFiltered.map((bet) => {
              return <BetCard key={bet.betInfo.betId} bet={bet} />
            })}
          {!loading && betDataFiltered.length === 0 && (
            <NoBetsAvailable
              description={NoBetsDiscription[activeRoute.route as MyBetRoutes]}
            />
          )}
        </div>
      </div>
      <div className="col-12 col-md-4 myBets_offersContainer">
        <SideOffers />
      </div>
    </div>
    // </div>
  )
}
