import { Link } from 'react-router-dom'
import { onToggleShowModalLogin } from '../../../../../../core/store/items/app'
import { useAppDispatch } from '../../../../../../core/store/Reducers'

export const BoxUnauthBtns = () => {
  const dispatch = useAppDispatch()

  return (
    <div
      className="headerFlexLinks-items"
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div className="d-none d-md-block">
        <Link to="/signup" className=" t-btn t-btn-transparent">
          Registre-se
        </Link>
      </div>
      <button
        type="button"
        className="t-btn t-btn-primary"
        onClick={() => {
          dispatch(onToggleShowModalLogin())
        }}>
        Login
      </button>
    </div>
  )
}
