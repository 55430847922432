import { useParams } from 'react-router-dom'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { GameTable } from '../../../Games/views/components/MainTable/GameTable'
import { useSportContext } from '../SportsNavigator'

export const Championship = () => {
  const { championshipId, sportRoute } = useParams()
  console.log('sportRoute', sportRoute)
  const { loadingSport } = useSportContext()
  const sports = useAppSelector((state: RootState) => state.sports)
  const chosenSport = sports.items.find((sport) => sport.route === sportRoute)
  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      {/* <h1>Campeonatos</h1>
      <h1>{loadingSport}</h1> */}
      {chosenSport ? (
        <GameTable
          sport={chosenSport}
          tournamentId={championshipId}
          isLoading={loadingSport}
        />
      ) : (
        <div>
          <h2> Esporte não encontrado</h2>
        </div>
      )}
    </div>
  )
}
