import { useState } from 'react'
import { GameDropdown } from '../../../Games/views/GameDetails/GameDropdown'
import { Bet } from '../../data'
import { BetCardBody } from './BetCardBody'
import { BetCardFooter } from './BetCardFooter'
import { BetCardHeader } from './BetCardHeader'

interface Props {
  bet: Bet
}

const DropdownBody = ({ bet }: Props) => {
  return (
    <>
      <BetCardBody bet={bet} />
      <BetCardFooter bet={bet} />
    </>
  )
}

export const BetCard = ({ bet }: Props) => {
  const [isCardOpen, setisCardOpen] = useState(true)
  const handleChangeOpenState = (isDropdownOpen: boolean) => {
    setisCardOpen(isDropdownOpen)
  }
  return (
    <GameDropdown
      header={<BetCardHeader bet={bet} isCardOpen={isCardOpen} />}
      body={<DropdownBody bet={bet} />}
      onChangeOpenState={handleChangeOpenState}
    />
  )
}
