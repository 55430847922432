/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useTranslation } from 'react-i18next'
import { AiOutlineClose } from 'react-icons/ai'
import { NumberFormatValues } from 'react-number-format'
import { getMarketById } from '../../../../../../../../../common/functions/market'
import { translateOddName } from '../../../../../../../../../common/functions/odds'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../core/store/Reducers'
import { changeOddSelection } from '../../../../../Games/store/gameList'
import { oddChecked, Odds } from '../../../../../Games/types/odds'

interface Props {
  item: oddChecked
  submited: boolean
}
export const ContainedCard = ({ item, submited }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const markets = useAppSelector((state: RootState) => state.markets)
  const homeTeam = item.teams[0].name
  const awayTeam = item.teams[1].name
  const marketName = getMarketById(item.odd.market_id, markets.items)?.name
  const inputMaxLength = ({ value }: NumberFormatValues) => value.length <= 5

  const handlerOddRemove = async (odd: Odds) => {
    await dispatch(changeOddSelection(odd))
  }
  // console.log('ITEEEM:', item)
  return (
    <div className="row g-0 showhim" style={{ padding: '10px' }}>
      {!submited && (
        // <div className="col-1 showme">
        <div className="col-1">
          <AiOutlineClose
            style={{ fontSize: 18 }}
            className="pointer"
            onClick={() => {
              handlerOddRemove(item.odd)
            }}
          />
        </div>
      )}
      <div className="col-11">
        <h3 className="containedCardTitle">
          {translateOddName(item.odd.oddName, homeTeam, awayTeam, t)}
        </h3>
        <span className="oddContained">{item.odd.rate}</span>
        <h6 className="cardSubtitle">
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          {marketName ? t(marketName) : 'Mercado Não Encontrado'}
        </h6>
        <h6 className="cardSubtitle">{`${homeTeam} x ${awayTeam}`} </h6>
      </div>
    </div>
  )
}
