/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  setModalForgot,
  setModalLogin,
} from '../../../../../core/store/items/app'
import { setLoggedIn } from '../../../../../core/store/items/user'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../core/store/Reducers'
import { MainInput } from '../../../Global/components/Inputs/MainInput'
import { forgotSchema } from './validation'
import { onPostFormForgotRequest } from './xhr/forgot.xhr'

export interface ForgotFormData {
  email: string
}

export const FormForgot = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state: RootState) => state.user)
  const { t, i18n } = useTranslation()
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotFormData>({
    resolver: yupResolver(forgotSchema),
  })

  const onPostFormForgot = async (formData: ForgotFormData) => {
    if (formData) {
      const resultLogged = await onPostFormForgotRequest(formData)
      //   setOnPostError(undefined)
      if (resultLogged.data.success) {
        dispatch(setModalForgot(false))
        await dispatch(
          setLoggedIn({
            token: resultLogged.data.token,
            info: resultLogged.data.user,
            isLogged: true,
            prefs: resultLogged.data.prefs,
          }),
        )
        // i18n.changeLanguage(resultLogged.data.prefs.language)
        i18n.changeLanguage(user.prefs.language)
      } else {
        // TODO -
        // alert(resultLogged.data.msg)
        // setOnPosting(false)
      }
    }
  }

  const handleOpenLogin = () => {
    dispatch(setModalForgot(false))
    dispatch(setModalLogin(true))
  }

  return (
    <form id="formBody" onSubmit={handleSubmit(onPostFormForgot)}>
      <h1 className="minimalTitle">Senha Esquecida</h1>
      <MainInput
        type="text"
        name="email"
        register={register}
        placeholder="Digite o email cadastrado"
        errors={errors}
        className="col-md-12"
      />

      <div className="formForgotInputBox">
        <button
          // disabled={onPosting}
          className="submit-button"
          type="submit">
          ENVIAR E-MAIL DE RECUPERAÇÃO
        </button>
      </div>
      {/* {onPostError && <ForgotErrorBox />} */}
      <div id="modalBoxForgot">
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenLogin()
          }}
          className="Link"
          // to="/forgot"
        >
          Fazer login
        </span>
      </div>
    </form>
  )
}
