/* eslint-disable react/no-array-index-key */
import { RiDeleteBack2Line } from 'react-icons/ri'
import './KeyBoard.scss'

interface Props {
  full?: boolean
}
const labelEnd = 'Terminar'
export const KeyBoard = ({ full }: Props) => {
  const keyboardCustomClass = full ? 'keyboard-large' : 'keyboard-min row g-0'
  return (
    <div className="bet-keyboard container g-0">
      <div className={`keyboard-buttons  ${keyboardCustomClass}`}>
        {[...Array(9)].map((x, i) => {
          return (
            <button key={i} data-value={i + 1} type="button">
              {i + 1}
            </button>
          )
        })}

        <button type="button" data-value="0">
          0
        </button>
        <button data-value="," className="decimal" type="button">
          ,
        </button>

        <button className="backspace" type="button" data-value="<">
          <RiDeleteBack2Line color="#fff" size="1.8rem" />
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="19"
            viewBox="0 0 28 19">
            <g fill="none" fillRule="evenodd">
              <path
                stroke="#666"
                strokeWidth="2"
                d="M1.566 10.942c-.758-.796-.751-2.095 0-2.884l6.032-6.333C7.979 1.325 8.737 1 9.291 1h15.897a2 2 0 0 1 2.005 2v13c0 1.105-.886 2-2.005 2H9.29c-.554 0-1.31-.323-1.693-.725l-6.032-6.333z"
              />
              <g className="qbs-DeleteButton_StrokeX" fill="#666">
                <path d="M18.121 5.464l1.415 1.415-5.657 5.657-1.415-1.415z" />
                <path d="M19.536 11.121l-1.415 1.415-5.657-5.657 1.415-1.415z" />
              </g>
            </g>
          </svg> */}
        </button>
        <button type="button" className="end" data-value="end">
          {labelEnd}
        </button>
      </div>
    </div>
  )
}
KeyBoard.defaultProps = {
  full: false,
}
