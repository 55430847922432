/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { AiOutlineClose } from 'react-icons/ai'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { getMarketById } from '../../../../../../../../../common/functions/market'
import { translateOddName } from '../../../../../../../../../common/functions/odds'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../core/store/Reducers'
import { changeOddSelection } from '../../../../../Games/store/gameList'
// import markets from '../../../../../Games/store/markets'
import { oddChecked, Odds } from '../../../../../Games/types/odds'
import { removeErrorMarkOnInputOddPrice } from '../../../../helpers/oddsCheckeds'
import { sanitizeKeyboardInnerText } from '../../KeyBoard/helpers/keyboard'
import { KeyBoard } from '../../KeyBoard/KeyBoard'

interface Props {
  item: oddChecked
  handlerPriceChange: any
  index: number
  submited: boolean
  // isMobile: boolean
  refWagerModal: React.MutableRefObject<HTMLDivElement | undefined>
}
export const ExpandedCard = ({
  item,
  handlerPriceChange,
  index,
  submited,
  // isMobile,
  refWagerModal,
}: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const markets = useAppSelector((state: RootState) => state.markets)
  const homeTeam = item.teams[0].name
  const awayTeam = item.teams[1].name
  const marketName = getMarketById(item.odd.market_id, markets.items)?.name

  const inputMaxLength = ({ value }: NumberFormatValues) => value.length <= 10
  const [fixedDecimal, setFixedDecimal] = useState(false)
  const [inputValue, setInputValue] = useState(item.odd.price)
  const handlerOddRemove = async (odd: Odds) => {
    await dispatch(changeOddSelection(odd))
  }
  const refOddPrice = useRef<HTMLInputElement | null>(null)
  const [isLoaded, setIsLoaded] = useState(false)

  // useEffect(() => {
  //   console.log('Shake Input', shakeInputs)
  // }, [shakeInputs])
  const handleChangeInput = async (value: number | undefined) => {
    handlerPriceChange(item.odd, value)
    setInputValue(value)
  }

  useEffect(() => {
    function findAncestor(el: HTMLElement | null, cls: string) {
      if (el) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el
      }
      return null
    }

    function handlerMobileTinyKeyboard(e: { target: any }) {
      // linha do jogo da odd
      const elParentOddPriceRow = findAncestor(e.target, 'oddPriceRow')

      // console.log(e.target)
      // console.log(elParentOddPriceRow)

      if (elParentOddPriceRow) {
        // INICIO esconde todos teclados existentes...
        if (refWagerModal.current) {
          const allLeyboardEl =
            refWagerModal.current.querySelectorAll<HTMLDivElement>(
              'div[class=keyboard_tiny]',
            )
          // console.log(allLeyboardEl)
          allLeyboardEl.forEach((keyboardItem) => {
            keyboardItem.style.display = 'none'
          })
        }
        // FINAL - esconde todos teclados existentes...

        // INICIO - TECLADO DA LINHA EM QUESTÃO - ATUAL
        const keyboardElActual =
          elParentOddPriceRow.querySelector<HTMLDivElement>(
            'div[class=keyboard_tiny]',
          )

        if (keyboardElActual) {
          // mostre o teclado...
          keyboardElActual.style.display = 'block'

          // todos os botoões do teclado atual
          const allButtonActualKeyboard =
            keyboardElActual.querySelectorAll<HTMLButtonElement>('button')

          // valor final da ODD digitada em cada botão é armazenada neste campo
          let priceUserInput = ''

          const decimalSize = 2
          const decimalSeparator = ','
          // intera todos botões e binda o clique deles
          allButtonActualKeyboard.forEach((btnKeyboard) => {
            btnKeyboard.addEventListener('click', function () {
              const innerText = btnKeyboard.dataset.value ?? ''

              if (innerText === 'end') {
                keyboardElActual.style.display = 'none'
                if (!e.target.value) return

                e.target.value = parseFloat(e.target.value.replace(',', '.'))
                  .toFixed(2)
                  .replace('.', ',')
                console.error(typeof e.target.value)
                return
              }
              priceUserInput = sanitizeKeyboardInnerText(
                priceUserInput,
                innerText,
                decimalSeparator,
                decimalSize,
              )

              e.target.value = priceUserInput
              // atualiza o estado no redux com o novo valor
              handlerPriceChange(
                item.odd,
                parseFloat(priceUserInput.replace(',', '.')),
              )
            })
          })
        }
      }
    }
    if (isLoaded && refOddPrice && refOddPrice.current) {
      refOddPrice.current.addEventListener('click', handlerMobileTinyKeyboard)
    }
    return () => {
      if (isLoaded && refOddPrice && refOddPrice.current) {
        refOddPrice.current.removeEventListener(
          'click',
          handlerMobileTinyKeyboard,
        )
      }
    }
    //   // if (index === 0) setFixedDecimal(true)
  }, [isLoaded])

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    setInputValue(item.odd.price)
    if (item.odd.price !== inputValue) setFixedDecimal(true)
    // console.log('item changed', item)
  }, [item])

  useEffect(() => {
    console.log('inputValue', inputValue)
  }, [inputValue])

  const handleInputFocus = () => {
    setFixedDecimal(false)
    const el = document.getElementById(
      `odd-input${item.odd.betGamesOddId}`,
    ) as HTMLInputElement
    if (el) {
      el.select() // select the input textfield and set the focus on it
    }
  }

  return (
    <div className="row g-0 oddPriceRow">
      {!submited && (
        <div className="col-1">
          <AiOutlineClose
            style={{ fontSize: 18 }}
            className="pointer"
            onClick={() => {
              handlerOddRemove(item.odd)
            }}
          />
        </div>
      )}
      <div
        className={`${submited ? 'col-6' : 'col-5'}`}
        style={{ paddingLeft: '0.5rem' }}>
        {/* <Col sm={6} style={{ maxWidth: '100%' }}> */}
        <h3 className="cardTitle">
          {translateOddName(item.odd.oddName, homeTeam, awayTeam, t)}
        </h3>
        <h6 className="cardSubtitle">
          {marketName ? t(marketName) : 'Mercado Não Encontrado'}
        </h6>
        <h6 className="cardSubtitle">{`${homeTeam} x ${awayTeam}`} </h6>
      </div>
      <div className="col-2">
        <h3 className="oddChanged oddCard">{item.odd.rate.toFixed(2)}</h3>
      </div>
      <div className="col-4">
        <div className="row g-0">
          {!submited ? (
            <div className="col-12">
              {isMobile ? (
                <input
                  ref={refOddPrice}
                  readOnly={isMobile}
                  className="oddPriceDefault oddPrice oddPriceMobile"
                  placeholder="Valor da aposta"
                  defaultValue=""
                />
              ) : (
                <NumberFormat
                  getInputRef={refOddPrice}
                  readOnly={isMobile}
                  id={`odd-input${item.odd.betGamesOddId}`}
                  style={{ width: '100%' }}
                  className="oddPrice oddPriceDefault"
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={fixedDecimal}
                  onBlur={() => setFixedDecimal(true)}
                  onFocus={() => handleInputFocus()}
                  decimalScale={2}
                  // isAllowed={inputMaxLength} // Causing a bug
                  onValueChange={(v) => {
                    removeErrorMarkOnInputOddPrice({ refWagerModal })
                    handleChangeInput(v.floatValue)
                  }}
                  value={inputValue}
                  placeholder="Valor da aposta"
                />
              )}
            </div>
          ) : (
            <div className="col-12 contentRight">
              {item.odd.price?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </div>
          )}
          {item.odd.prize && item.odd.prize > 0 && (
            <div className="col-12 wager_info_label contentRight">
              Retornos potenciais
            </div>
          )}
          {item.odd.prize && item.odd.prize > 0 && (
            <div className="col-12 wager_info_label contentRight">
              {`R$ ${item.odd.prize.toFixed(2)}`}
            </div>
          )}
        </div>
      </div>

      {isMobile && !submited && (
        <div className="keyboard_tiny">
          <KeyBoard />
        </div>
      )}
    </div>
  )
}
