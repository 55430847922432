/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */

// import UserIcon from '../../../common/icons/user.svg'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { AiOutlineSearch } from 'react-icons/ai'
import { setMenuLeftMobile } from '../../../../../core/store/items/app'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../core/store/Reducers'
import { Search } from '../../views/Search'
import BoxAuthBtns from './components/BoxAuthBtns/BoxAuthBtns'
import { CashbackHeader } from './components/BoxAuthBtns/CashbackHader'
import { BoxUnauthBtns } from './components/BoxUnauthBtns'
import { CenterMenu } from './components/CenterMenu'
import './HeaderDefault.scss'
import { HeaderLeft } from './itens/HeaderLeft'

export const HeaderDefault = () => {
  const { client, isShowLeftMenuMobile } = useAppSelector((state) => state.app)
  const dispatch = useAppDispatch()
  const user = useAppSelector((state: RootState) => state.user)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  // useEffect(() => {
  //   if(isMobile)

  // }, [isMobile])
  const handleCLosetSearch = () => {
    setIsSearchOpen(false)
  }
  const searchIconClass = isMobile ? 'searchIconClass' : ''
  return (
    <div id="header" className="container-fluid fixed-top ">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <div>
          <HeaderLeft
            name={client.name}
            onOpenMenu={() => {
              dispatch(setMenuLeftMobile(!isShowLeftMenuMobile))
            }}
          />
        </div>
        {!isSearchOpen && <CenterMenu />}

        {/* {!isMobile && (
          <div className="searchContainer">
            <Search />
          </div>
        )} */}
        <div
          className={`${searchIconClass}`}
          style={{
            alignItems: 'center',
            justifyContent: 'flex-end',
            display: 'flex',
            // marginLeft: 'auto',
            cursor: 'pointer',
          }}>
          <AiOutlineSearch
            className="d-none d-sm-block"
            color={isSearchOpen ? 'orange' : 'white'}
            size={40}
            onClick={() => setIsSearchOpen(!isSearchOpen)}
          />
          <div>{user.isLogged ?? <CashbackHeader />}</div>
          <CashbackHeader />
          <div>{!user.isLogged ? <BoxUnauthBtns /> : <BoxAuthBtns />}</div>
        </div>
      </div>
      {!isMobile && isSearchOpen && (
        // <div className="row justify-content-center">
        <div className="col-sm-12 col-md-6 searchMenuCol d-none d-sm-block">
          <Search className="col-12" handleCloseSearch={handleCLosetSearch} />
        </div>
        // </div>
      )}
    </div>
  )
}
