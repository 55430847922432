/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './MenuLeft.css'

export type menuLeft = {
  label: string
  type?: boolean
  route: string
}

type menuLeftProps = {
  items: menuLeft[]
  currentRoute: menuLeft
  selectedPage: any
  title: string
  handleToggleMenuMobile: () => void
  menuMobileOpen: boolean
  isMobile: boolean
}

export const MenuLeft = ({
  items,
  selectedPage,
  title,
  menuMobileOpen,
  handleToggleMenuMobile,
  isMobile,
  currentRoute,
}: menuLeftProps): JSX.Element => {
  // const menuHieght = menuMobileOpen ? '100vh' : '30px'

  // useEffect(() => {
  //   console.log('currentRoute', currentRoute)
  // }, [currentRoute])

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{
        alignItems: 'flex-start',
        marginLeft: '-10px',
        paddingBottom: '1rem',
      }}>
      <div className="container-fluid ">
        {menuMobileOpen ? (
          <h2 className=" d-block d-md-none menu-title">{title}</h2>
        ) : (
          <h2 className=" d-block d-md-none menu-title">
            {currentRoute?.label}
          </h2>
        )}
        {/* <a className="navbar-brand" href="#">
          Navbar
        </a> */}
        <button
          onClick={() => handleToggleMenuMobile()}
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className={`${
            !menuMobileOpen && 'collapse'
          } navbar-collapse menuLeft g-0`}
          id="navbarSupportedContent">
          <ul
            className={`menu-left-items ${
              menuMobileOpen ? 'activemenu' : 'menuClosed'
            }`}>
            {items.map(({ label, route }, index) => {
              return (
                <li
                  className={
                    selectedPage === route ? 'activeItem' : 'normalItem'
                  }
                  onClick={() => {
                    if (isMobile) handleToggleMenuMobile()
                  }}
                  role="none"
                  key={route}>
                  <Link to={route}>{label}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </nav>
  )

  /* return (
    <div className="menu-left">
      <ul
        className={`menu-left-items ${
          menuMobileOpen ? 'activemenu' : 'menuClosed'
        }`}>
        {items.map(({ label, route }, index) => {
          return (
            <li
              className={selectedPage === route ? 'activeItem' : 'normalItem'}
              role="none"
              key={route}>
              <Link to={route}>{label}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  ) */
}
