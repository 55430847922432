/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-enable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from 'react'
import { SecondaryOddCard } from '../../cards/SecondaryOddCard'
import { OddTableProps } from '../../types'
import './style.scss'

type ChangeScore = 'increase' | 'decrease'
type TeamScoreProps = {
  team: string
  handleChangeScore: (changeScore: ChangeScore) => void
  scoreNumber: number
  disableIncrease: boolean
}

export const TeamScore = ({
  team,
  scoreNumber,
  handleChangeScore,
  disableIncrease,
}: TeamScoreProps) => {
  return (
    <div className="col-6 col-sm-4 teamScoreContainer">
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      <h5>{team}</h5>
      <div className="col-12 changeScoreContainer">
        <div
          className={`changeScoreBoxButton ${
            scoreNumber === 0 ? 'disableScoreBox' : ''
          }`}
          onClick={() => handleChangeScore('decrease')}>
          -
        </div>
        <div className="scoreNumber">{scoreNumber}</div>
        <div
          className={`changeScoreBoxButton ${
            disableIncrease ? 'disableScoreBox' : ''
          }`}
          onClick={() => handleChangeScore('increase')}>
          +
        </div>
      </div>
    </div>
  )
}

export const ScoreTable = ({ gameDetails, odds }: OddTableProps) => {
  const [currentOdd, setcurrentOdd] = useState(odds[0])
  const home = gameDetails.teams[0].name
  const away = gameDetails.teams[1].name
  const [homeScore, setHomeScore] = useState(0)
  const [awayScore, setAwayScore] = useState(0)

  const [disableAwayIncrease, setDisableAwayIncrease] = useState<boolean>(false)
  const [disableHomeIncrease, setDisableHomeIncrease] = useState<boolean>(false)

  const findOddByScore = (homeGoals: number, awayGoals: number) => {
    let header = ''
    if (awayGoals < homeGoals) header = '1'
    else if (awayGoals === homeGoals) header = 'Draw'
    else if (awayGoals > homeGoals) header = '2'
    if (header === '2') {
      return odds.find(
        (odd) =>
          odd.oddName === `${awayGoals}-${homeGoals}` &&
          odd.oddHeader === header,
      )
    }
    return odds.find(
      (odd) =>
        odd.oddName === `${homeGoals}-${awayGoals}` && odd.oddHeader === header,
    )
  }

  // const getScoreNumber = (odd: GameDetailsOdds, teamType: 'home' | 'away') => {
  //   console.log(odd.oddName)
  //   switch (teamType) {
  //     case 'home':
  //       return parseInt(odd.oddName.split('-')[0], 10)
  //     case 'away':
  //       return parseInt(odd.oddName.split('-')[1], 10)
  //     default:
  //       return parseInt(odd.oddName.split('-')[0], 10)
  //   }
  // }

  const handleChangeHomeScore = (changeScore: ChangeScore) => {
    if (changeScore === 'decrease') {
      if (homeScore === 0) return
      setHomeScore(homeScore - 1)
    }
    if (changeScore === 'increase') {
      if (disableHomeIncrease) return
      setHomeScore(homeScore + 1)
    }
  }

  const handleChangeAwayScore = (changeScore: ChangeScore) => {
    if (changeScore === 'decrease') {
      if (awayScore === 0) return
      setAwayScore(awayScore - 1)
    }
    if (changeScore === 'increase') {
      if (disableAwayIncrease) return
      setAwayScore(awayScore + 1)
    }
  }

  // useEffect(() => {
  //   console.log('scoreOdds', odds)
  // }, [odds])

  useEffect(() => {
    const newOdd = findOddByScore(homeScore, awayScore)
    if (newOdd) setcurrentOdd(newOdd)

    const nextAwayGoal = findOddByScore(homeScore, awayScore + 1)
    if (nextAwayGoal) setDisableAwayIncrease(false)
    else setDisableAwayIncrease(true)

    const nextHomeGoal = findOddByScore(homeScore + 1, awayScore)
    if (nextHomeGoal) setDisableHomeIncrease(false)
    else setDisableHomeIncrease(true)
  }, [homeScore, awayScore])

  return (
    <div className="row g-0">
      <TeamScore
        team={home}
        handleChangeScore={handleChangeHomeScore}
        scoreNumber={homeScore}
        disableIncrease={disableHomeIncrease}
      />
      <TeamScore
        team={away}
        handleChangeScore={handleChangeAwayScore}
        scoreNumber={awayScore}
        disableIncrease={disableAwayIncrease}
      />
      <div
        className="col-12 col-sm-4"
        style={{ position: 'relative', minHeight: '3rem' }}>
        <div className="oddScoreTable">
          <SecondaryOddCard
            key={currentOdd.betGamesOddId}
            odd={currentOdd}
            teams={gameDetails.teams}
            // showLabel
            // styles={`mainOddContainer col-md-${colSize} col-${colSmSize}`}
          />
        </div>
      </div>
    </div>
  )
}
