/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../../../../../core/store/Reducers'
import { oddChecked } from '../../../../../Games/types/odds'
import { MultipleCard } from './MultipleCard'

interface Props {
  total: number
  oddsChecked: oddChecked[]
  submited: boolean
  // allowMultiple: boolean
  refWagerModal: React.MutableRefObject<HTMLDivElement | undefined>
}
export const MultipleBody = ({
  total,
  refWagerModal,
  oddsChecked,
  submited,
}: Props) => {
  const dispatch = useAppDispatch()
  const [multiplesCollapsed, setMultiplesCollapsed] = useState<boolean>(true)
  const [allowMultiple, setallowMultiple] = useState(false)
  const [totalUnique, setTotalUnique] = useState<number>(0)
  const [constructorArray, setConstructorArray] = useState<number[]>([])
  // const RenderMultiples = () => {
  //   if (showAllMultiples)
  //     for (let i = 1; i < total; i = +1) {
  //       return <MultipleCard />
  //     }
  //   return <></>
  // }
  const ArrayAux = (limit: number) => {
    const x = []
    for (let i = 1; i <= limit; i += 1) {
      x.push(i)
    }
    return x
  }

  useEffect(() => {
    if (oddsChecked) {
      const oddsIds: number[] = []
      oddsChecked.forEach((odd) => {
        if (oddsIds.find((item) => item === odd.info.betGameId)) {
          setallowMultiple(false)
        } else oddsIds.push(odd.info.betGameId)
      })
      setTotalUnique(oddsIds.length)
      setConstructorArray(ArrayAux(oddsIds.length))
    }
  }, [oddsChecked])

  // console.log('item', item)
  return (
    <div
      style={{
        backgroundColor: '#d6d6d6',
      }}>
      {/* <h2>totalUnique: {totalUnique}</h2>
      <h2>total: {total}</h2> */}
      {totalUnique === total ? (
        <>
          <MultipleCard
            submited={submited}
            index={total}
            mainCard
            collapseMultiples={setMultiplesCollapsed}
            collapsedMultiples={multiplesCollapsed}
            refWagerModal={refWagerModal}
          />
          {!multiplesCollapsed &&
            constructorArray.map((i) => {
              if (i === totalUnique) return null
              return (
                <MultipleCard
                  key={i}
                  index={i}
                  refWagerModal={refWagerModal}
                  submited={submited}
                />
              )
            })}
        </>
      ) : (
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <h3
            style={{
              fontSize: '1rem',
              padding: '1rem 0',
            }}>
            Não é possível realizar múltiplas com a mesma partida
          </h3>
        </div>
      )}
    </div>
  )
}
