import { Notify } from '../../../../../../../../common/components/toats/notify'
import { formatCurrency } from '../../../../../../../../common/functions/formatting'
import { DepositResponseType } from '../../types/bank'
import './payment.css'

interface Props {
  paymentData: DepositResponseType
  handleClearForm: () => void
}

export const PixPayment = ({ paymentData, handleClearForm }: Props) => {
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(paymentData.payload)
      Notify({ type: 'success', message: 'Copiado com sucesso' })
    } catch (err) {
      Notify({ type: 'error', message: 'Não foi possível copiar' })
    }
  }
  return (
    <div className="row ">
      <div
        className="col-12"
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0',
          fontSize: '1.3rem',
          fontWeight: 'bold',
        }}>
        <p>
          <span>Valor: </span>
          {formatCurrency(paymentData.price)}
        </p>
      </div>
      <div className="col-12">
        <button
          className="main-button"
          type="button"
          onClick={() => handleCopy()}>
          Pix Copia e Cola
        </button>
      </div>
      <div className="col-12" style={{ paddingTop: '8px' }}>
        <button
          className="main-button"
          type="button"
          onClick={() => handleClearForm()}>
          Voltar
        </button>
      </div>
      <div
        className="payment-field-column col-12"
        style={{ padding: '18px 0' }}>
        <img src={paymentData.qrcode} alt="QRcode" />
      </div>
      <div
        className="col-12 allowSelectText"
        style={{ wordBreak: 'break-all' }}>
        {paymentData.payload}
      </div>
    </div>
  )
}
