import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import SportItem from '../types/sport'

export const initialSport: SportItem = {
  id: 1,
  route: '',
  name: '',
  icon: '',
}

export const initialState = {
  items: [] as SportItem[],
}

export const sportReducer = createSlice({
  name: 'games',
  initialState,
  reducers: {
    // setSportActual: (state, action: PayloadAction<SportItem>) => {
    //   state.actual = action.payload
    // },
    setSports: (state, action: PayloadAction<{ items: SportItem[] }>) => {
      state.items = action.payload.items
    },
  },
})

export const { setSports } = sportReducer.actions

export default sportReducer.reducer
