/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import ChartIcon from '../../../../../../../common/icons/chart.svg'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../../../../../common/functions/formatting'
import { setMenuRight } from '../../../../../../../core/store/items/app'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import './style.scss'

const BoxAuthBtns = () => {
  const wallet = useAppSelector((state: RootState) => state.wallet)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const cash = formatCurrency(wallet.cash)
  const totalCash = formatCurrency(wallet.cash + wallet.cashback)

  const { isShowRightMenu } = useAppSelector((state) => state.app)
  const handleMenuUser = () => {
    dispatch(setMenuRight(!isShowRightMenu))
  }

  return (
    <div id="userTopBar" className="row g-0">
      <div className="col-6 userTopBarInfo col-sm-6">
        <div className="row g-0">
          <div className="col-12 text-right d-none d-sm-block">{totalCash}</div>
          <div className="col-12 text-right">
            <Link to="/bank/funds" className="fundLink d-none d-sm-block">
              {t('Fund')}
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6">
        <div className="userIcon" onClick={handleMenuUser} />
        <div
          className="col-12 text-right d-block d-sm-none"
          style={{ fontSize: '11px' }}>
          {totalCash}
        </div>
      </div>
    </div>
  )
}
export default BoxAuthBtns
