/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Notify } from '../../../../../../common/components/toats/notify'

export interface WalletState {
  cash: number
  cashback: number
}

const initialState: WalletState = {
  cash: 0,
  cashback: 0,
}
export const walletReducer = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setCash: (state, action: PayloadAction<number | null>) => {
      let value = action.payload
      if (typeof value === 'string') value = parseFloat(value)
      if (value && value >= 0) state.cash = value
      else state.cash = 0
    },
    setCashback: (state, action: PayloadAction<number | null>) => {
      if (action.payload && action.payload >= 0) state.cashback = action.payload
      else state.cashback = 0
    },
    decreaseCash: (
      state,
      action: PayloadAction<{ amount: number; cashbackActive: boolean }>,
    ) => {
      const { amount, cashbackActive } = action.payload
      const value = amount
      if (state.cashback && cashbackActive) {
        const rest = state.cashback - value
        if (rest >= 0) {
          state.cashback -= value
        } else {
          console.log('Rest', rest)
          state.cashback = 0
          state.cash -= Math.abs(rest)
        }
      } else if (!cashbackActive) state.cash -= value
      else Notify({ type: 'error', message: 'Não há saldo de cashback' })
    },

    assignWallet: (state, action: PayloadAction<{ data: WalletState }>) => {
      const { cash, cashback } = action.payload.data
      let value = cash
      if (typeof value === 'string') value = parseFloat(value)

      if (value && value >= 0) state.cash = value
      else state.cash = 0
      if (cashback && cashback >= 0) state.cashback = cashback
      else state.cashback = 0
    },
  },
})

export const { setCash, decreaseCash, assignWallet } = walletReducer.actions

export default walletReducer.reducer
