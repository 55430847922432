/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { GameItem } from '../../../Games/types/games'
import './highlight.scss'
import { HighlightCardList } from './highlightCardList'

interface Props {
  CardsList: GameItem[]
}

interface ArrowProps {
  // content: string
  handleClick: (direction: 'left' | 'right') => void
  position: 'left' | 'right'
}

const Arrow = ({ handleClick, position }: ArrowProps) => {
  return (
    <span
      className={`navigation-arrow ${
        position === 'left' ? 'arrow-left' : 'arrow-right'
      }`}
      onClick={() => handleClick(position)}>
      <span> {position === 'left' ? '＜' : '＞'}</span>
    </span>
  )
}

export const HighlightResponsive = ({ CardsList }: Props) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [element, setElement] = useState<HTMLElement>()
  const wraperId = 'highlightWrapper'

  const getScrollPosition = () => {
    const el = document.getElementById(wraperId) as HTMLElement
    // console.log('Scroll Left Value', el.scrollLeft)
    if (el) setScrollPosition(el.scrollLeft)
  }

  const handleClickArrow = async (direction: 'left' | 'right') => {
    const el = document.getElementById(wraperId) as HTMLElement
    getScrollPosition()
    // console.log(el)
    if (el) {
      // console.log('Parent', el.parentElement)
      // el.select() // select the input textfield and set the focus on it
      const scrollPath = el.parentElement?.offsetWidth || 250
      if (direction === 'right') {
        el.scrollLeft += scrollPath
        handleChangePosition(el, scrollPath, 'right')
        // setScrollPosition(el.scrollLeft)
      } else {
        el.scrollLeft -= scrollPath
        handleChangePosition(el, scrollPath, 'left')
      }
    }
  }

  const handleChangePosition = (
    el: HTMLElement,
    path: number,
    direction: 'left' | 'right',
  ) => {
    let total = 0
    if (direction === 'right') {
      total = el.scrollLeft + path
      if (total <= el.scrollWidth && el.scrollWidth !== el.offsetWidth)
        setScrollPosition(total)
      // else if (el.scrollWidth !== el.offsetWidth) {
      //   setScrollPosition(el.scrollWidth)
      // }
    } else if (direction === 'left') {
      total = el.scrollLeft - path
      if (total >= 0) setScrollPosition(total)
      else setScrollPosition(0)
    }
  }

  return (
    <div>
      {!isLoading ? (
        <div id="highlightContainer" className="highlightContainer">
          <div
            id="highlightWrapper"
            className={`highlightWrapper ${isMobile && 'overflow-x-auto'}`}>
            {HighlightCardList({ CardsList })}
            {!isMobile && (
              <>
                {scrollPosition !== 0 && (
                  <Arrow position="left" handleClick={handleClickArrow} />
                )}
                <Arrow position="right" handleClick={handleClickArrow} />
              </>
            )}
          </div>
        </div>
      ) : (
        <h2>Carregando</h2>
      )}
    </div>
  )
}
