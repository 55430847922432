import { toast } from 'react-toastify'
import './style.scss'

type NotificationProps = {
  type: 'success' | 'error' | 'warn' | 'info' | 'custom'
  message: string
  position?: 'TOP_RIGHT'
}

const defaultPosition = 'TOP_RIGHT'

export const Notify = ({ type, message, position }: NotificationProps) => {
  switch (type) {
    case 'success':
      toast.success(message, {
        position: toast.POSITION[position ?? defaultPosition],
      })
      break
    case 'error':
      toast.error(message, {
        position: toast.POSITION[position ?? defaultPosition],
        // className: 'errorToast',
      })
      break
    case 'warn':
      toast.error(message, {
        position: toast.POSITION[position ?? defaultPosition],
      })
      break
    case 'info':
      toast.error(message, {
        position: toast.POSITION[position ?? defaultPosition],
      })
      break
    case 'custom':
      toast.error(message, {
        position: toast.POSITION[position ?? defaultPosition],
        className: 'foo-bar',
      })
      break
    default:
      toast(message)
      break
  }
}

Notify.defaultProps = {
  position: 'TOP_RIGHT',
}
