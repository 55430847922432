/* eslint-disable react/jsx-no-constructed-context-values */

import React from 'react'
import ReactDOM from 'react-dom'
import { IconContext } from 'react-icons'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import './common/buttons.scss'
import './common/card.css'
import './common/index.scss'
import './common/notification.scss'
import './common/SportGames.css'
import store from './core/store/Reducers'

const RNApp = () => (
  <Provider store={store}>
    <BrowserRouter>
      <IconContext.Provider value={{ color: 'gray', className: '' }}>
        <SkeletonTheme
          // baseColor="#5294e0"
          highlightColor="orange"
          borderRadius="0.3rem"
          duration={10}>
          <App />
          <ToastContainer style={{ paddingTop: '60px' }} />
        </SkeletonTheme>
      </IconContext.Provider>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(
  <React.StrictMode>
    {/* <Helmet>
      <title>BETKING</title>
    </Helmet> */}
    <RNApp />
  </React.StrictMode>,
  document.getElementById('root'),
)
// import React from 'react'
// import ReactDOM from 'react-dom'
// import App from './App'

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root'),
// )
