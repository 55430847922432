import { MainInput } from '../../../../../Global/components/Inputs/MainInput'
import { FormSection } from '../../types'

export const AddressInfo = ({ errors, register }: FormSection) => {
  return (
    <div>
      <legend className="form-legend">Endereço</legend>
      <MainInput
        type="text"
        name="houseNumber"
        register={register}
        placeholder="Número da Casa"
        errors={errors}
        className="col-md-12"
      />
      <MainInput
        type="text"
        name="apartmentNumber"
        register={register}
        placeholder="Número do Apartamento"
        errors={errors}
        className="col-md-12"
      />
      <MainInput
        type="text"
        name="streetName"
        register={register}
        placeholder="Nome da Rua"
        errors={errors}
        className="col-md-12"
      />
      <MainInput
        type="text"
        name="postalCode"
        register={register}
        placeholder="Código Postal/CEP"
        errors={errors}
        className="col-md-12"
      />
      <hr className="solid" />
    </div>
  )
}
