import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getMarketById } from '../../../../../../../../common/functions/market'
import {
  getGamesBySport,
  getGamesByTournamentId,
  getOddsByMarket,
} from '../../../../../../../../common/functions/odds'
import { marketOptionsBySport } from '../../../../../../../../common/functions/sport'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../../core/store/Reducers'
import SportItem from '../../../../Sports/types/sport'
import { GameItem } from '../../../types/games'
import { GamesCardsHeader } from '../GamesCardsHeader'
import { GameTableRow } from '../GameTableRow'

export type HideHighlightOptions =
  | 'isHighlightHome'
  | 'isHighlight'
  | 'isHighlightDetail'
  | 'none'

interface GameTableProps {
  sport: SportItem
  limit?: number
  tournamentId?: string
  isLoading?: boolean
  headerTitle?: string
  hideHighlight?: HideHighlightOptions
}

interface HeaderProps {
  headerTitle: string
  icon: string
}

const Header = ({ headerTitle, icon }: HeaderProps) => {
  return (
    <div>
      <h1 style={{ color: '#ffffff', padding: '0 1rem', fontSize: '1.4rem' }}>
        {icon && (
          <img
            src={icon}
            alt="Icone do Esporte"
            style={{ maxHeight: '25px', paddingRight: '0.6em' }}
          />
        )}
        {headerTitle}
      </h1>
    </div>
  )
}

export const GameTable = ({
  sport,
  limit,
  tournamentId,
  isLoading,
  headerTitle,
  hideHighlight,
}: GameTableProps) => {
  const { t } = useTranslation()
  const gameList = useAppSelector((state: RootState) => state.gameList)
  const [tableTitle, setTableTitle] = useState(sport.name)
  const sportMainMarketList = marketOptionsBySport(sport.route)

  const marketList = useAppSelector((state: RootState) => state.markets).items
  const market = getMarketById(sportMainMarketList[0], marketList)
  const [gameSelection, setGameSelection] = useState([] as GameItem[])

  const firstItemOddsByMarket = getOddsByMarket(
    sportMainMarketList[0],
    gameSelection[0]?.odds,
  )

  useEffect(() => {
    if (gameSelection && tournamentId) {
      const firstItem = gameSelection[0]
      console.log(firstItem)
      setTableTitle(firstItem?.info.betCnfTournamentName ?? 'Não disponível')
    }
  }, [gameSelection])

  useEffect(() => {
    if (!tournamentId) setTableTitle(sport.name)
    if (tournamentId) {
      setGameSelection(getGamesByTournamentId(gameList.items, tournamentId))
    } else {
      setGameSelection(
        getGamesBySport(
          gameList.items,
          sport.id.toString(),
          hideHighlight ?? 'none',
        ),
      )
    }
  }, [sport])

  return (
    <div>
      {market && (
        <div className="card-live-games">
          <div className="card-table-body">
            {!isLoading ? (
              <>
                {tableTitle && (
                  <Header headerTitle={tableTitle} icon={sport.icon} />
                )}
                <GamesCardsHeader
                  name={t(market.name) || ''}
                  firstItemOdds={firstItemOddsByMarket}
                  firstItemTeams={gameSelection[0]?.teams}
                />
                {gameSelection?.slice(0, limit).map((gameItem) => {
                  return (
                    <GameTableRow
                      key={gameItem.info.betGameId}
                      gameItem={gameItem}
                      chosenMarketId={market.id}
                    />
                  )
                })}
              </>
            ) : (
              <div>Carregando</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

GameTable.defaultProps = {
  limit: 3000,
  tournamentId: undefined,
  isLoading: false,
  headerTitle: undefined,
  hideHighlight: 'none',
}
