import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getMarketById } from '../../../../../../../../common/functions/market'
import {
  getGamesBySport,
  getOddsByMarket,
  groupGamesByTournament,
} from '../../../../../../../../common/functions/odds'
import { marketOptionsBySport } from '../../../../../../../../common/functions/sport'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../../core/store/Reducers'
import SportItem from '../../../../Sports/types/sport'
import { Market } from '../../../types/markets'
import { GroupCardItem } from './components/GroupCardItem'
import { MarketSelector } from './components/MarketSelector/MarketSelector'

export type HideHighlightOptions =
  | 'isHighlightHome'
  | 'isHighlight'
  | 'isHighlightDetail'
  | 'none'

interface GroupTableProps {
  sport: SportItem
  listOfIds?: number[]
  limit?: number
  tournamentId?: string
  isLoading?: boolean
  hideHighlight?: HideHighlightOptions
}

export const GroupTable = ({
  sport,
  listOfIds,
  limit,
  tournamentId,
  isLoading,
  hideHighlight,
}: GroupTableProps) => {
  const { t } = useTranslation()
  const gameList = useAppSelector((state: RootState) => state.gameList)
  const [tableTitle, setTableTitle] = useState(sport.name)
  const sportMainMarketList = marketOptionsBySport(sport.route)
  const marketList = useAppSelector((state: RootState) => state.markets).items
  const [market, setMarket] = useState({} as Market)

  // console.log('market', market)

  const gameSelection = getGamesBySport(
    gameList.items,
    sport.id.toString(),
    hideHighlight ?? 'none',
  )
  const firstItemOddsByMarket = getOddsByMarket(
    sportMainMarketList[0],
    gameSelection[0]?.odds,
  )

  const groupedGames = groupGamesByTournament(gameSelection)
  console.log('groupedGames', groupedGames)
  useEffect(() => {
    if (gameSelection && tournamentId) {
      const firstItem = gameSelection[0]
      setTableTitle(firstItem?.info.betCnfTournamentName ?? 'Não disponível')
      // console.log('gameSelection', gameSelection)
    }
    // setGroupedGames(groupGamesByTournament(gameSelection))
  }, [gameSelection])

  const handleChangeMarket = (marketItem: Market) => {
    setMarket(marketItem)
  }

  useEffect(() => {
    if (!tournamentId) setTableTitle(sport.name)
  }, [sport])

  useEffect(() => {
    handleChangeMarket(getMarketById(sportMainMarketList[0], marketList))
  }, [])
  useEffect(() => {
    console.log('CHOSEN MARKET', market)
  }, [market])

  return (
    <div>
      {market && (
        <div className="card-live-games">
          <div className="card-table-body">
            {!isLoading && groupedGames ? (
              <>
                <MarketSelector
                  sport={sport}
                  onChangeOption={handleChangeMarket}
                />
                {/* <GamesCardsHeader
                  hideOdds
                  name={t(market.name) || ''}
                  firstItemOdds={firstItemOddsByMarket}
                  className="groupTableHeader"
                /> */}
                {Object.entries(groupedGames)
                  .filter(
                    (gameGroup) =>
                      listOfIds?.includes(parseInt(gameGroup[0], 10)) ||
                      !listOfIds?.length,
                  )
                  .map((gameGroup, index) => {
                    return (
                      <GroupCardItem
                        index={index}
                        key={gameGroup[0]}
                        title={gameGroup[1].title}
                        groupedGames={gameGroup[1].list}
                        chosenMarketId={market.id}
                      />
                    )
                  })}
              </>
            ) : (
              <div>Carregando</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

GroupTable.defaultProps = {
  limit: 3000,
  tournamentId: undefined,
  isLoading: false,
  hideHighlight: 'none',
  listOfIds: [],
}
