import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Notify } from '../../../../../../../../../../common/components/toats/notify'
import { SubmitButton } from '../../../../../../../../Global/components/buttons/SubmitButton'
import { CPFInput } from '../../../../../../../../Global/components/Inputs/CPFInput'
import { MainInput } from '../../../../../../../../Global/components/Inputs/MainInput'
import { NumberInput } from '../../../../../../../../Global/components/Inputs/NumberInput'
import { PasswordInput } from '../../../../../../../../Global/components/Inputs/PasswordInput'
import { WithdrawPixRequest } from './pix.xhr'
import { PixKeyOptionsType, WithdrawPixFormData } from './types'
import { withDrawPixSchema } from './yup'
// type FormData = {
//   firstName: string
//   lastName: string
// }

export const WithdrawPixForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm<WithdrawPixFormData>({
    resolver: yupResolver(withDrawPixSchema),
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

  const onSubmit = async (formData: WithdrawPixFormData) => {
    // console.log(errors)
    setLoading(true)
    setDisableSubmit(true)
    console.log(formData)
    if (formData) {
      const signResponse = await WithdrawPixRequest(formData)

      if (signResponse.success) {
        // Notify({ type: 'success', message: signResponse.msg })
      } else {
        setDisableSubmit(false)
        Notify({ type: 'error', message: signResponse.msg })
      }
    }
    setLoading(false)
  }

  console.log(errors)
  // firstName and lastName will have correct type
  const pixKeyOptions: PixKeyOptionsType[] = [
    { label: 'CPF', value: 'cpf' },
    { label: 'CNPJ', value: 'cnpj' },
    { label: 'Celular', value: 'phone' },
    { label: 'E-mail', value: 'email' },
    { label: 'Aleatória', value: 'random' },
  ]
  return (
    <div className="container g-0">
      <div className="row">
        <form
          style={{ width: '100%', paddingTop: '10px' }}
          onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12" key="selectMethod">
            <label style={{ paddingBottom: '5px' }} htmlFor="selectMethod">
              Escolha o tipo de chave
            </label>
            <select
              className="form-field selectInput"
              {...register('pixKeyType')}>
              {pixKeyOptions.map(({ label, value }) => {
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                )
              })}
            </select>
          </div>

          <MainInput
            type="text"
            name="pixKey"
            register={register}
            placeholder="Chave PIX"
            errors={errors}
            className="col-12"
          />

          <MainInput
            type="text"
            name="ownerName"
            register={register}
            placeholder="Nome completo do titular"
            errors={errors}
            className="col-12"
          />

          <CPFInput
            name="cpf"
            errors={errors}
            setValue={setValue}
            control={control}
            trigger={trigger}
          />

          <NumberInput
            name="amount"
            placeholder="Valor de saque"
            errors={errors}
            setValue={setValue}
            trigger={trigger}
          />

          <PasswordInput
            name="password"
            register={register}
            placeholder="Senha"
            errors={errors}
            className="col-md-12"
            hideRightIcon
          />

          <SubmitButton
            loading={loading}
            text="Solicitar saque"
            disableSubmit={disableSubmit || Object.keys(errors).length > 0}
          />
        </form>
      </div>
    </div>
  )
}
