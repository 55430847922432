import { useForm } from 'react-hook-form'
import ContriesList from '../../../../../../../../../common/Data/countriesArray.json'
import { MainInput } from '../../../../../../../Global/components/Inputs/MainInput'
import { PasswordInput } from '../../../../../../../Global/components/Inputs/PasswordInput'
// type FormData = {
//   firstName: string
//   lastName: string
// }

const FormData = {
  fields: [
    { name: 'bank', label: 'Nome do banco', type: 'text', defaulValue: '' },
    {
      name: 'address',
      label: 'Endereço Físico da Agência',
      type: 'text',
      defaulValue: '',
    },
    {
      name: 'userFullname',
      label: 'Nome do Titular da Conta',
      type: 'text',
      defaulValue: 'Emerson Rigaud',
    },
    { name: 'code', label: 'Código do Banco', type: 'text', defaulValue: '' },
    // { name: 'password', label: 'Senha', type: 'password', defaulValue: '' },
  ],
  submit: {
    label: 'Saque',
    endpoint: 'www.mobtex.com/credits/withdraw',
  },
}

export const WithdrawTransferForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = handleSubmit((data) => console.log(data))
  // firstName and lastName will have correct type

  return (
    <div className="container g-0">
      <div className="row">
        <form style={{ width: '100%' }} onSubmit={onSubmit}>
          <div className="col-12" style={{ paddingBottom: '10px' }}>
            <label style={{ paddingBottom: '5px' }} htmlFor="Countries">
              País do Banco
            </label>
            <select
              className="form-field"
              style={{
                width: '100%',
                paddingBottom: '8px',
                paddingTop: '8px',
              }}
              {...register(`bankCountry`)}>
              {ContriesList.map((country) => {
                return <option value={country.sigla3}>{country.nome} </option>
              })}
            </select>
          </div>
          {FormData.fields.map((field) => {
            return (
              <MainInput
                type="text"
                name={field.name}
                register={register}
                label={field.label}
                errors={errors}
                className="col-md-12"
              />
            )
          })}
          <PasswordInput
            name="password"
            register={register}
            label="Senha"
            errors={errors}
            className="col-md-12"
          />
          <div className="col-12" style={{ paddingTop: '1rem' }}>
            <button
              className="submit-button"
              style={{ width: '100%', padding: '10px' }}
              type="submit">
              {FormData.submit.label}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
