import { Col } from 'react-grid-system'
// import { UseFormRegister } from 'react-hook-form'
// import { PreferencesFormData } from '../../../Auth/views/Account/items/Preferences/Preferences'

type RadioItem = {
  label: string
  value: string | boolean
}

interface Props {
  type: 'password' | 'text' | 'email' | 'date'
  // formType: (arg: Type) => Type
  register: any
  name: string
  label?: string
  errors: any
  className?: string
  RadioArray: RadioItem[]
}

export const RadioInput = ({
  label,
  name,
  register,
  errors,
  className,
  RadioArray,
}: // formType,
Props) => {
  // const registerFunction = register as UseFormRegister<typeof formType>
  return (
    <div key={name} className={className ? `row` : `${className}`}>
      <label className="form-label" htmlFor={`${name}`}>
        {label}
      </label>
      {RadioArray.map((item) => {
        return (
          <div key={item.label} className="col-md-5">
            <div className="row">
              <div className="col-md-2">
                <input
                  // className="form-field"
                  type="radio"
                  value={item.value}
                  {...register(`${name}`)}
                />
              </div>
              <label
                className="col-md-8 form-label"
                // className="form-label"
                htmlFor={`${name}`}>
                {item.label}
              </label>
            </div>
          </div>
        )
      })}

      <p className="input-error-warning">{errors[name]?.message}</p>
    </div>
  )
}
RadioInput.defaultProps = {
  label: '',
  className: '',
}
