/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getMarketById } from '../../../../../../../../../../common/functions/market'
import { marketOptionsBySport } from '../../../../../../../../../../common/functions/sport'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../../../../core/store/Reducers'
import SportItem from '../../../../../../Sports/types/sport'
import { Market } from '../../../../../types/markets'
import './style.scss'

type MarketSelectorProps = {
  sport: SportItem
  onChangeOption: (market: Market) => void
}

export const MarketSelector = ({
  sport,
  onChangeOption,
}: MarketSelectorProps) => {
  const { t } = useTranslation()
  const sportMainMarketList = marketOptionsBySport(sport.route)
  const marketList = useAppSelector((state: RootState) => state.markets).items
  const [market, setMarket] = useState(
    getMarketById(sportMainMarketList[0], marketList) as Market,
  )

  const handleClickOption = (selectedMarket: string) => {
    console.log('selectedMarket', selectedMarket)
    // setMarket(selectedMarket)
    onChangeOption(getMarketById(selectedMarket, marketList))
  }

  return (
    <div style={{ color: '#ffffff' }}>
      <select
        className="form-field"
        style={{
          backgroundColor: '#383838',
          color: '#ffffff',
          maxWidth: '300px',
          outline: 'none',
          border: 'none',
          fontWeight: 'bold',
          fontSize: '1.1rem',
          cursor: 'pointer',
        }}
        onChange={(e) => handleClickOption(e.target.value)}
        defaultValue={market.id}>
        {sportMainMarketList.map((marketId) => {
          const marketOption = getMarketById(marketId, marketList)
          return (
            <option
              style={{
                color: '#000',
                backgroundColor: '#ffffff',
                fontWeight: 'bold',
                fontSize: '1.2rem',
                height: '5.5rem',
              }}
              value={marketOption.id}>
              {t(marketOption.name)}
            </option>
          )
        })}
      </select>
    </div>
  )
}
