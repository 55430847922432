/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  getWagersTotalPrice,
  getWagersTotalPrize,
} from '../../../../../../common/functions/odds'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import { onDeleteWager } from '../../Games/store/gameList'
import { setMinimized, setPriceSum, setTotalPrize } from '../store/wagers'
import { WagersBody } from './components/Body/WagersBody'
import WagersFooter from './components/Footer/WagersFooter'
import { WagersHeader } from './components/Header/WagersHeader'
import { NotEnoughCash } from './components/Warn/NotEnoughCash'
import './WagersSelections.css'

export const WagersSelections = () => {
  const wagers = useAppSelector((state: RootState) => state.wagers)
  const refWagerModal = useRef() as MutableRefObject<HTMLDivElement>

  // const wagerRef =  MutableRefObject<HTMLDivElement | null>

  const [hasGrow, setHasGrow] = useState<boolean>(false)
  const [isModalLoaded, setIsModalLoaded] = useState(false)

  const dispatch = useAppDispatch()

  const [mustExpand, setMustExpand] = useState<boolean>(false)
  const [submited, setSubmited] = useState<boolean>(false)

  // const isMinimized = wagers.selectionIsMinimized
  const handleSubmitState = () => {
    setSubmited(true)
  }
  const boxStyle = wagers.selectionIsMinimized ? { paddingBottom: 0 } : {}
  const handleMinimize = async () => {
    await dispatch(setMinimized(true))
  }
  const handleRestoreOfMinimized = () => {
    dispatch(setMinimized(false))
  }

  useEffect(() => {
    const handleCloseSubmited = async () => {
      if (submited && wagers.selectionIsMinimized) {
        await dispatch(onDeleteWager({ oddsList: wagers.odds }))
      }
    }
    handleCloseSubmited()
  }, [submited, wagers.selectionIsMinimized])

  useEffect(() => {
    function handlerOutsideWagerSelection(e: { target: any }) {
      const elIntoWagerSelection = e.target.closest('div[id=wagerSelection]')
      // console.log('wagers.odds clicando no dom ')
      // console.log(wagers.odds)
      // console.log(`${wagers.selectionIsMinimized} : isMinimized`)
      // console.log(`${wagers.totalWagers} : totalWagers????`)
      if (!elIntoWagerSelection) {
        // console.log('click outside wager selection')
        // console.log(`${wagers.totalWagers} : totalWagers????`)
        // if (!wagers.selectionIsMinimized && totalWagers > 1) {
        if (wagers.totalWagers > 1) {
          handleMinimize()
        }
      }
    }
    if (isModalLoaded) {
      // console.log('BIND CLICK outside addEventListener')
      document.body.addEventListener('click', handlerOutsideWagerSelection)
    }
    return () => {
      document.body.removeEventListener('click', handlerOutsideWagerSelection)
    }
  }, [isModalLoaded])

  useEffect(() => {
    setIsModalLoaded(true)
  }, [])

  const handlePriceSum = async () => {
    await dispatch(
      setPriceSum(getWagersTotalPrice(wagers.odds, wagers.multiples)),
    )
  }

  const handleTotalPrizeSum = async () => {
    await dispatch(
      setTotalPrize(getWagersTotalPrize(wagers.odds, wagers.multiples)),
    )
  }

  useEffect(() => {
    // console.log('TOTAL wagers', wagers.totalWagers)
    if (wagers.totalWagers === 1) {
      handleRestoreOfMinimized()
      // setMustExpand(false)
      // setHasGrow(false)
    }
    if (wagers.totalWagers === 2) {
      // if (!wagers.selectionIsMinimized) dispatch(setMinimized(true))
      setMustExpand(true)
      setHasGrow(true)
    }
    handlePriceSum()
    handleTotalPrizeSum()
  }, [wagers])

  useEffect(() => {
    if (wagers.totalWagers === 2) {
      if (!wagers.selectionIsMinimized) {
        // console.log('Entrou em minimizar')
        dispatch(setMinimized(true))
      }
    }
  }, [wagers.totalWagers])

  // useEffect(() => {
  //   console.log('-------------------------')
  //   console.log('mustExpand', mustExpand)
  //   console.log('Total wagers', wagers.totalWagers)
  //   console.log('IS MINIMIZED', wagers.selectionIsMinimized)
  //   console.log('hasGrow', hasGrow)
  //   console.log('-------------------------')
  // }, [wagers, mustExpand, hasGrow])

  // useEffect(() => {
  //   console.log('hideWager', wagers.hideWager)
  // }, [wagers])

  return (
    <div id="wagerSelectionModule">
      <div id="wagerSelectionDefault">
        {wagers.totalWagers > 1 && !wagers.selectionIsMinimized && (
          <div
            className="overlay"
            onClick={() => dispatch(setMinimized(true))}
          />
        )}
        <div
          className={` ${wagers.hideWager ? 'd-none' : ''} wagerSelection`}
          id="wagerSelection"
          ref={refWagerModal}
          style={boxStyle}>
          {wagers.notEnoughCash ? (
            <NotEnoughCash />
          ) : (
            <>
              {(wagers.totalWagers > 1 ||
                wagers.selectionIsMinimized ||
                mustExpand ||
                submited) && (
                <WagersHeader
                  total={wagers.totalWagers}
                  submited={submited}
                  isMinimized={wagers.selectionIsMinimized}
                  handleMinimize={handleMinimize}
                  handleRestoreOfMinimized={handleRestoreOfMinimized}
                  oddsChecked={wagers.odds}
                  hasGrow={hasGrow}
                />
              )}
              {refWagerModal.current && !wagers.selectionIsMinimized && (
                <WagersBody
                  isMobile={isMobile}
                  submited={submited}
                  mustExpand={mustExpand}
                  total={wagers.totalWagers}
                  oddsChecked={wagers.odds}
                  refWagerModal={refWagerModal}
                />
              )}
              {refWagerModal.current && !wagers.selectionIsMinimized && (
                <WagersFooter
                  total={wagers.totalWagers}
                  oddsChecked={wagers.odds}
                  hasGrow={hasGrow}
                  refWagerModal={refWagerModal}
                  handleSubmitState={handleSubmitState}
                  submited={submited}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
