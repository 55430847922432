/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link } from 'react-router-dom'

interface Props {
  name: string
  onOpenMenu: () => void
}
export const HeaderLeft = ({ name, onOpenMenu }: Props) => {
  return (
    <div className="row">
      {/* Maior do que MD esconde o hamburguer menu */}
      <div className="col-12 d-block d-md-none ">
        <div className="openMenuMobile" onClick={onOpenMenu}>
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className="col-12">
        <Link to="/">
          <img
            alt={name}
            className="logo d-none d-md-block"
            style={{ maxHeight: 30, marginTop: 0 }}
            src={`${process.env.PUBLIC_URL}/img/logo_3.png`}
          />
          {/* <img
            alt={name}
            className="logo d-block d-md-none"
            src={`${process.env.PUBLIC_URL}/img/logo.png`}
          /> */}
        </Link>
      </div>
    </div>
  )
}
