/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import { formatCurrency } from '../../../../../../../../common/functions/formatting'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../core/store/Reducers'
import { onDeleteWager } from '../../../../Games/store/gameList'
import { oddChecked } from '../../../../Games/types/odds'
import { SubmitHeaderInfo } from './SubmitedHeaderInfo'
import './WagersHeader.css'

interface Props {
  total: number
  hasGrow: boolean
  submited: boolean
  isMinimized: boolean
  oddsChecked: oddChecked[]
  handleMinimize: () => void
  handleRestoreOfMinimized: () => void
}
export const WagersHeader = ({
  total,
  isMinimized,
  handleMinimize,
  handleRestoreOfMinimized,
  oddsChecked,
  hasGrow,
  submited,
}: Props) => {
  const wallet = useAppSelector((state: RootState) => state.wallet)
  const [contentColSizeClassName, setContentColSizeClassName] =
    useState('col-10')
  const dispatch = useAppDispatch()
  const handleRemoveAll = () => {
    dispatch(onDeleteWager({ oddsList: oddsChecked }))
  }
  useEffect(() => {
    if (isMinimized) {
      setContentColSizeClassName('col-12')
    } else {
      setContentColSizeClassName('col-8')
    }
  }, [isMinimized])
  return (
    <div id="wager_header" className="container g-0">
      {!submited ? (
        <>
          <div className="row wager_header bb  g-0">
            <div
              className={contentColSizeClassName}
              onClick={() => {
                // se  estiver minimizado
                if (isMinimized) {
                  handleRestoreOfMinimized()
                }
              }}>
              <div className="wager_header_content">
                <span
                  className="badge"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '14px',
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}>
                  {total}
                </span>
                <h4 className="wager_title">Caderneta de aposta</h4>
              </div>
            </div>
            {!isMinimized && (
              <>
                <div className="col-3">
                  <div
                    className="wager_header_content"
                    style={{
                      textAlign: 'center',
                      flexDirection: 'column',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <h6 className="wager_subtitle_2">Saldo</h6>
                    <h6 className="wager_subtitle_2">
                      {formatCurrency(wallet.cash)}
                    </h6>
                  </div>
                </div>
                <div
                  className="col-1 bl"
                  onClick={() => {
                    handleMinimize()
                  }}>
                  <div className="wager_header_content closeIcon">
                    <BsChevronDown style={{ fontSize: 20 }} />
                  </div>
                </div>
              </>
            )}
          </div>
          {hasGrow && !isMinimized && (
            <div
              className="row bb  g-0"
              style={{
                padding: '6px',
              }}>
              <div className="col-6">
                <h6
                  className="wager_subtitle"
                  onClick={() => handleRemoveAll()}>
                  Remover tudo
                </h6>
              </div>
              <div className="col-6 text-right">
                <h6 className="wager_subtitle">Simples e Múltiplas</h6>
              </div>
            </div>
          )}
        </>
      ) : (
        <SubmitHeaderInfo
          refCode="RSD7A27E2S"
          handleCloseWager={handleRemoveAll}
        />
      )}
    </div>
  )
}
