import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../../../../../common/functions/formatting'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { assignWallet } from '../../../Wallet/store/wallet'
import { getBalance } from '../xhr/bank.xhr'
import './BankBalance.css'

export const BankBalance = () => {
  // const [isLoading, setIsLoading] = useState(true)
  const dispatch = useAppDispatch()

  const credits = useAppSelector((state: RootState) => state.credits)
  const wallet = useAppSelector((state: RootState) => state.wallet)
  const user = useAppSelector((state: RootState) => state.user)
  const cashback = formatCurrency(wallet.cashback)
  // const [currentRoute, isMobile] = useOutletContext();
  // console.log(credits.items)
  // useffect api call... xhr...

  useEffect(() => {
    ;(async () => {
      const resultBalance = await getBalance(user.token)
      // console.log('SALDO')
      await dispatch(
        assignWallet({
          data: resultBalance.data,
        }),
      )
      // console.log(balance)
    })()
  }, [])

  return (
    <div className="row">
      <h1 className="config-title d-none d-md-block">Saldo</h1>
      <div className="col-12 col-md-6">
        <div className="">
          <div className="card-info-bank-item">
            <table className="table-responsive table-info-bank">
              <tbody className="border-bottom-2">
                {credits.items.map((item) => {
                  return (
                    <tr>
                      <td className="text-left">
                        <h2>{item.name}</h2>
                      </td>
                      <td className="text-right">
                        <h1>{item.balance}</h1>
                      </td>
                    </tr>
                  )
                })}
                <tr style={{ padding: '0px' }}>
                  <td className="text-left" style={{ padding: '0px' }}>
                    <h2 className="config-subtitle">Disponível</h2>
                  </td>
                  <td className="text-right">
                    <h1 className="config-title">
                      {formatCurrency(wallet.cash)}
                    </h1>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-info-bank-item">
            <table className="table-responsive table-info-bank">
              <tbody>
                <tr
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <td style={{ width: '45%' }}>
                    <Link to="/bank/funds">
                      <button type="button" className="main-button">
                        Depósito
                      </button>
                    </Link>
                  </td>
                  <td style={{ width: '45%' }}>
                    <Link to="/bank/withdraw">
                      <button className="main-button" type="button">
                        Saque
                      </button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 border-left-2">
        <div className="card-sub-pages-flex-item ">
          <div className="card-info-bank-item ">
            <table className="table-responsive table-info-bank table-info-bank">
              <tbody className="border-bottom-2">
                <tr>
                  <td className="text-left">
                    <h2 className="config-subtitle">Bônus e Créditos</h2>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-info-bank-item">
            <table className="table-responsive table-info-bank">
              <tbody>
                <tr key="cashback">
                  <td className="text-left">
                    <h3>Cashback</h3>
                  </td>
                  <td className="text-right">
                    <h5>{cashback}</h5>
                  </td>
                </tr>
                {/* {credits.bonus.map((section) => {
                  return (
                    <tr key={section.id}>
                      <td className="text-left">
                        <h3>{section.name}</h3>
                      </td>
                      <td className="text-right">
                        <h5>{`$${section.balance.toFixed(2)}`}</h5>
                      </td>
                    </tr>
                  )
                })} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
