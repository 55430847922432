import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GameItem } from '../../Games/types/games'
import { DashboardItems } from '../types/dashboard'
import Slideshow from '../types/slideshow'

export const initialStateDashboardItems = {
  slideshow: [] as Slideshow[],
  highlight: [] as GameItem[],
}
export const initialState = {
  item: initialStateDashboardItems,
}
export const dashboardReducer = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardItens: (state, action: PayloadAction<DashboardItems>) => {
      state.item = action.payload
    },
  },
})
export const { setDashboardItens } = dashboardReducer.actions

export default dashboardReducer.reducer
