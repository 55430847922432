/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { useTranslation } from 'react-i18next'
import { translateOddName } from '../../../../../../../common/functions/odds'
import { GameItemTeams } from '../../types/games'
import { Odds } from '../../types/odds'
import '../Games.scss'

type Props = {
  name: string
  firstItemOdds: Odds[]
  firstItemTeams: GameItemTeams[]
  hideOdds?: boolean
  className?: string
  // icon: string
}
export const GamesCardsHeader = ({
  name,
  hideOdds,
  firstItemOdds,
  firstItemTeams,
  className,
}: Props) => {
  const { t } = useTranslation()
  // props:{name:string}
  // console.log('DEFAULT ODDS', defaultOdds)

  return (
    <div
      className={`card-live-games-header ${className}`}
      style={{ borderBottom: '1px solid orange' }}>
      <div className="card-live-game-header-name">
        <h3 style={{ paddingLeft: '0.5rem', fontSize: '1.1rem' }}>{name}</h3>
      </div>
      <div
        className={`card-live-game-header-numbers ${hideOdds ? 'd-none' : ''}`}>
        {firstItemOdds.map((item) => {
          const { oddName, oddHeader } = item
          return (
            <div
              key={oddHeader.length ? oddHeader : oddName}
              className="card-live-game-header-numbers-items">
              <p style={{ fontSize: '1.1rem', color: 'white' }}>
                {translateOddName(
                  oddHeader.length ? oddHeader : oddName,
                  firstItemTeams[0].name,
                  firstItemTeams[1].name,
                  t,
                  true,
                )}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

GamesCardsHeader.defaultProps = {
  hideOdds: false,
  className: ' ',
}
