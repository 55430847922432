import ClipLoader from 'react-spinners/ClipLoader'
import './style.scss'

type SubmitButtonProps = {
  disableSubmit: boolean
  text: string
  ContainerClass?: string
  ButtonClass?: string
  loading?: boolean
}

export const SubmitButton = ({
  disableSubmit,
  ContainerClass,
  ButtonClass,
  loading,
  text,
}: SubmitButtonProps) => {
  const buttonLabel = loading ? 'Enviando' : text
  return (
    <div
      style={{ position: 'relative' }}
      className={`col-12 SubmitButtonContainer ${ContainerClass}`}>
      {loading && (
        <div className="SpinnerContainer">
          <ClipLoader color="#ffffff" />
        </div>
      )}
      <button
        className={`submit-button ${ButtonClass}`}
        type="submit"
        disabled={disableSubmit}>
        {buttonLabel}
      </button>
    </div>
  )
}

SubmitButton.defaultProps = {
  ContainerClass: '',
  ButtonClass: '',
  loading: false,
}
