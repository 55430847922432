import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  RootState,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import { SubmitButton } from '../../../../Global/components/buttons/SubmitButton'
import { PasswordInput } from '../../../../Global/components/Inputs/PasswordInput'
import { updatePassword } from '../xhr/dados.xhr'

export type PasswordFormData = {
  newPassword: string
  confirmPassword: string
  password: string
}

export const ChangePassword = () => {
  const user = useAppSelector((state: RootState) => state.user)

  const btnSubmitDefaultLabel = 'Atualizar'

  const requiredMsg = 'Esse campo é obrigatório!'
  const minLenghtMsg = 'A senha deve ter no mínimo 6 caracteres'
  const maxLenghtMsg = 'A senha deve ter no máximo 32 caracteres'

  const schema = yup
    .object({
      newPassword: yup
        .string()
        .required(requiredMsg)
        .min(6, minLenghtMsg)
        .max(32, maxLenghtMsg),
      confirmPassword: yup
        .string()
        .required(requiredMsg)
        .oneOf([yup.ref('newPassword'), null], 'As senhas devem ser iguais')
        .min(6, minLenghtMsg)
        .max(32, maxLenghtMsg),
      password: yup
        .string()
        .required(requiredMsg)
        .min(6, minLenghtMsg)
        .max(32, maxLenghtMsg),
    })
    .required()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordFormData>({ resolver: yupResolver(schema) })

  const onSubmit = async (data: PasswordFormData) => {
    console.log(data)
    const response = await updatePassword(user.token, data)
    if (!response.success) alert(response.msg)
    else console.log(response)
  }

  return (
    <div className="container g-0">
      <h1 className="config-title d-none d-md-block minimalTitle">
        Alterar Senha
      </h1>

      <div style={{ paddingTop: '20px' }}>
        <p>
          Por favor utilize apenas letras, números e símbolos aceitos, sem
          espaços, com um mínimo de 6 e um máximo de 32 caracteres.
        </p>
        <br />
        <p>
          A sua senha não deverá conter o seu nome de usuário, o seu nome, o seu
          e-mail nem o seu ano de nascimento. Você poderá aumentar o grau de
          segurança da sua senha utilizando uma mistura de letras maiúsculas ou
          minúsculas, números e símbolos. Por favor lembre-se de que as senhas
          são sensíveis a maiúsculas.
        </p>
        <p>Os símbolos que se seguem podem ser utilizados na sua senha:</p>
        <p>
          ! &quot; # $ % &amp; ( ) * + , - . / : ; &lt; = &gt; ? _ @ [ \ ] ^ ` |
          ~
        </p>
      </div>
      <form
        style={{ width: '100%', paddingTop: '30px' }}
        onSubmit={handleSubmit(onSubmit)}
        noValidate>
        {/* <div className="row"> */}
        <PasswordInput
          name="newPassword"
          register={register}
          placeholder="Nova Senha"
          errors={errors}
          className="col-12"
          hideRightIcon
        />
        <PasswordInput
          name="confirmPassword"
          register={register}
          placeholder="Confirmar Senha"
          errors={errors}
          className="col-12"
          hideRightIcon
        />
        <PasswordInput
          name="password"
          register={register}
          placeholder="Senha Atual"
          errors={errors}
          className="col-12"
          hideRightIcon
        />
        <SubmitButton
          text={btnSubmitDefaultLabel}
          disableSubmit={Object.entries(errors).length > 0}
        />
      </form>
    </div>
  )
}
