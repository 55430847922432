import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Market } from '../types/markets'

const initialState = {
  items: [] as Market[],
}

export const marketsReducer = createSlice({
  name: 'markets',
  initialState,
  reducers: {
    setMarkets: (state, action: PayloadAction<Market[]>) => {
      state.items = action.payload
    },
  },
})

export const { setMarkets } = marketsReducer.actions

export default marketsReducer.reducer
