import { useEffect, useState } from 'react'
import {
  DefineMultipleLabel,
  formatCurrency,
} from '../../../../../../../common/functions/formatting'
import { Bet, BetStatus } from '../../data'
import './style.scss'

interface Props {
  bet: Bet
  isCardOpen: boolean
}

interface HeaderRightInfoProps {
  title: string
}

const HeaderRightInfo = ({ title }: HeaderRightInfoProps) => {
  return <div className="HeaderRightInfo">{title}</div>
}

export const BetCardHeader = ({ bet, isCardOpen }: Props) => {
  const [headerRightInfoTitle, setHeaderRightInfoTitle] = useState<string>('')

  const chooseHeaderRightInfoTitle = (betStatus: BetStatus) => {
    switch (betStatus) {
      case 'lose':
        return 'Perdida'
      case 'win':
        return `Ganhou ${formatCurrency(bet.betInfo.potencialReturns)}`
      default:
        return ''
    }
  }
  useEffect(() => {
    setHeaderRightInfoTitle(chooseHeaderRightInfoTitle(bet.betInfo.betStatus))
  }, [bet])

  return (
    <div className="bet-card-header">
      <h5>{`${formatCurrency(bet.betInfo.price)} ${DefineMultipleLabel(
        bet.odds.length,
      )}`}</h5>
      {headerRightInfoTitle !== '' && (
        <HeaderRightInfo title={headerRightInfoTitle} />
      )}
    </div>
  )
}
