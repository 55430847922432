interface Props {
  register: any
  name: string
  label: string
}
export const ToggleInput = ({ name, label, register }: Props) => {
  return (
    <div
      className="col-12 orm-field-column"
      key="notification"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '0.5em',
      }}>
      <label className="form-label" htmlFor="notification">
        {label}
      </label>
      <input
        type="checkbox"
        className="input-radio-checked input-radio-checked-min"
        {...register(`${name}`)}
      />
    </div>
  )
}
