import { Link } from 'react-router-dom'
import './style.scss'

export const SportTableList = () => {
  return (
    <div className="SportTableListContainer">
      <h2>Listas de futebol</h2>
      <div className="SportTableList">
        <Link className="SportTableItem" to="lists/12">
          Próximas 12 horas
        </Link>
        <Link className="SportTableItem" to="lists/bl">
          Melhores Ligas
        </Link>
      </div>
    </div>
  )
}
