import { setLogout } from '../../../../../core/store/items/user'
import { useAppDispatch } from '../../../../../core/store/Reducers'

export const Logout = () => {
  const dispatch = useAppDispatch()
  dispatch(setLogout())

  return <>xxxxx</>
  // return <Redirect to="/" />
}
