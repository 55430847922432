import { GameRoute } from '../../../Games/views/GameDetails/GameDetailsNavigator'

export const SportTableRoutes: GameRoute[] = [
  {
    label: 'Melhores Ligas',
    route: 'bestMatches',
    listOfIds: [10037440, 10041045, 10041809, 10043205, 10037075],
  },
  {
    label: 'Todas as Partidas',
    route: 'allMatches',
    listOfIds: [],
  },
]
