import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ToastsState {
  toastOpen: boolean
  toastLabel: string | undefined
  toastType: string
  isReload?: boolean
  isDurationTime?: number
  isDurationTimeReload?: number
  posX?: string
}

const initialState: ToastsState = {
  toastOpen: false,
  toastLabel: '',
  toastType: 'error',
  isReload: false,
  isDurationTime: 2500,
  isDurationTimeReload: 1800,
  posX: 'toast-align-bottom',
}

export const toastReducers = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action: PayloadAction<{ items: ToastsState }>) => {
      const stateCopy = state
      stateCopy.toastOpen = action.payload.items.toastOpen
      stateCopy.toastType = action.payload.items.toastType
      stateCopy.toastLabel = action.payload.items.toastLabel

      if (action.payload.items.isReload) {
        stateCopy.isReload = action.payload.items.isReload
      }

      if (action.payload.items.isDurationTimeReload) {
        stateCopy.isDurationTimeReload =
          action.payload.items.isDurationTimeReload
      }

      if (action.payload.items.isDurationTime) {
        stateCopy.isDurationTime = action.payload.items.isDurationTime
      }

      return stateCopy
    },
  },
})

export const { setToast } = toastReducers.actions

export default toastReducers.reducer
