import { OfferItem } from './SideOffers'

export const offersList: OfferItem[] = [
  {
    title: 'Cashback',
    description:
      'Tenha 10% de cashback sobre o valor apostado após o inicio da partida',
    footer: '',
    route: 'cashback',
  },
  {
    title: 'Multiplas',
    description: 'Ganhe um bonus de ate 20% nas odds de multiplas',
    footer: '',
    route: 'multiple',
  },
  {
    title: 'Outras ofertas',
    description: 'Vejas mais novidades na pagina de ofertas',
    footer: '',
    route: 'offers',
  },
]
