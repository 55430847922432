export const formatCurrency = (value: number | string) => {
  let auxValue = value
  if (auxValue === null) {
    // eslint-disable-next-line no-param-reassign
    // console.log('NULL')
    auxValue = 0
  }
  if (typeof auxValue === 'string') auxValue = parseFloat(auxValue)
  // else auxValue = value
  return auxValue.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const DefineMultipleLabel = (oddsAmount: number) => {
  switch (oddsAmount) {
    case 1:
      return 'Simples'
    case 2:
      return 'Duplas'
    case 3:
      return 'Triplas'

    default:
      return `${oddsAmount} Múltiplas`
  }
}
