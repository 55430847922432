export const marketOptionsBySport = (sportRoute: string) => {
  switch (sportRoute) {
    case 'futebol':
      return [1000002, 1000014]
    case 'futebol-americano':
      return [1000002]
    case 'tennis':
      return [1000020]
    case 'basquete':
      return [1000040]
    case 'volei':
      return [1000040]
    case 'e-sports\r\n':
      return [1000002]
    case 'boxe':
      return [1000047]
    default:
      return [1000020]
  }
}
