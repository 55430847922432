import axios from 'axios'
import apiHelper from '../services/apiHelper'

/**
 * Retorna os atributos quando o usuário está logado.
 * @param token
 * @returns
 */
export const getBaseAuthAttr = async (token: string) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('base_auth'),
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const getBaseUnauthAttr = async () => {
  const response = await axios
    .request({
      method: 'post',
      url: apiHelper.baseUrl('base_unauth'),
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      //   console.log(error);
      return error
    })
  return response
}

export default {
  getBaseAuthAttr,
  getBaseUnauthAttr,
}
