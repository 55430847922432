import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { AddressType } from '../../../../../../core/store/items/user'
// import { setUser } from '../../../../../../core/store/items/user'
import {
  RootState,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import { SubmitButton } from '../../../../Global/components/buttons/SubmitButton'
import { MainInput } from '../../../../Global/components/Inputs/MainInput'
import { PasswordInput } from '../../../../Global/components/Inputs/PasswordInput'
import { CountryInfo } from '../../../../Unauth/views/SignUp/components/FormSections/CountryInfo'
import { updateAddress } from '../xhr/dados.xhr'

export interface AddressFormData extends AddressType {
  password: string
}

export const Address = () => {
  const user = useAppSelector((state: RootState) => state.user)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  // console.log('USER', user)
  const {
    cep,
    streetName,
    streetNumber,
    apartmentNumber,
    city,
    state,
    countryId,
    countryName,
  } = user.address!

  const requiredMsg = 'Esse campo é obrigatório!'
  const minLenghtMsg = 'Muito curto'
  const [isEditing, setIsEditing] = useState(false)
  const schema = yup
    .object({
      cep: yup.string().required(requiredMsg).min(8, minLenghtMsg),
      streetName: yup.string().required(requiredMsg),
      streetNumber: yup.string().required(requiredMsg),
      city: yup.string().required(requiredMsg),
      state: yup.string().required(requiredMsg),
      countryId: yup.string().required(requiredMsg),
      password: yup.string().required(requiredMsg).min(1, minLenghtMsg),
    })
    .required()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AddressFormData>({ resolver: yupResolver(schema) })

  const onSubmit = async (data: AddressFormData) => {
    console.log(data)
    setLoading(true)
    const response = await updateAddress(user.token, data)
    setLoading(false)

    if (!response.success) alert(response.msg)
    else console.log(response)
  }
  // console.log('USER', user)
  // console.error(errors)
  return (
    <>
      <h1 className="minimalTitle">Endereço</h1>
      <div className="container g-0">
        {isEditing ? (
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <CountryInfo
                register={register}
                errors={errors}
                defaultValue={countryId}
              />
              <MainInput
                type="text"
                name="cep"
                register={register}
                placeholder="Código Postal / CEP"
                errors={errors}
                defaultValue={cep}
                className="col-6"
              />
              <MainInput
                type="text"
                name="state"
                register={register}
                placeholder="Estado"
                errors={errors}
                defaultValue={state}
                className="col-6"
              />

              <MainInput
                type="text"
                name="city"
                register={register}
                placeholder="Cidade"
                errors={errors}
                defaultValue={city}
                className="col-12"
              />

              <MainInput
                type="text"
                name="streetName"
                register={register}
                placeholder="Nome da rua"
                errors={errors}
                defaultValue={streetName}
                className="col-12"
              />

              <MainInput
                type="text"
                name="streetNumber"
                register={register}
                placeholder="Número"
                errors={errors}
                defaultValue={streetNumber}
                className="col-md-6 col-sm-12"
              />
              <MainInput
                type="text"
                name="apartmentNumber"
                register={register}
                placeholder="Apartamento"
                errors={errors}
                defaultValue={apartmentNumber}
                className="col-md-6 col-sm-12"
              />

              <PasswordInput
                name="password"
                register={register}
                placeholder="Senha"
                errors={errors}
                className="col-12"
                hideRightIcon
              />
              <SubmitButton
                loading={loading}
                text={t('Update')}
                disableSubmit={Object.keys(errors).length > 0 || loading}
              />
            </div>
          </form>
        ) : (
          <div className="row">
            <div
              className="form-field-column col-12"
              style={{ paddingTop: '20px' }}>
              <p>
                {countryName}
                <br />
                {streetName} - {streetNumber}
                <br />
                {city} - {state}
                <br />
                {cep}
              </p>
            </div>
            <div
              className="form-field-column col-12"
              style={{ paddingTop: '20px' }}>
              <button
                className="main-button"
                type="button"
                onClick={() => setIsEditing(true)}>
                {' '}
                Alterar Endereço
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
