import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../../../core/containers/Loading/Loading'
import {
  RootState,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import PageTitle from '../../../../../../core/ui/PagesTitle/PagesTitle'
import { GameTable } from '../../Games/views/components/MainTable/GameTable'
import { sportsProps } from '../types/sport'
import './Sports.css'
import { useSportContext } from './SportsNavigator'
import { SportTableList } from './SportTableList/SportTableList'

export const Sports = () => {
  const { sportRoute } = useParams<sportsProps>()
  const sports = useAppSelector((state: RootState) => state.sports)

  const sportActual = sports.items.find((item) => item.route === sportRoute)
  const { loadingSport } = useSportContext()
  console.log('loadingSport', loadingSport)
  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <Helmet>
        <title>BETKING - {sportActual?.name ?? 'SportName'}</title>
      </Helmet>
      {loadingSport && <Loading />}
      {!loadingSport && sportActual && <PageTitle sport={sportActual} />}
      <SportTableList />
      {!loadingSport && sportActual && <GameTable sport={sportActual} />}
    </div>
  )
}
