import NumberFormat from 'react-number-format'

interface Props {
  name: string
  setValue: any
  disabled?: boolean
  label?: string
  placeholder?: string
  errors: any
  className?: string
  onBlurFunc?: () => void | Promise<void>
  trigger: any
  defaultValue?: string
  prefix?: string
}

export const NumberInput = ({
  name,
  placeholder,
  label,
  disabled,
  errors,
  setValue,
  prefix,
  className,
  onBlurFunc,
  trigger,
  defaultValue,
}: // formType,
Props) => {
  // const registerFunction = register as UseFormRegister<typeof formType>
  // const dddMaxLength = ({ value }: NumberFormatValues) => value.length <= 2

  return (
    <div key={name} className="col-12">
      <div className={`inputContainer ${label ? 'withLabel' : ''}`}>
        {/* {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )} */}
        <NumberFormat
          prefix={prefix}
          className="form-field"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          // format="###.###.###-##"
          placeholder=" "
          disabled={disabled}
          onValueChange={(v) => {
            setValue(name, v.value)
            trigger(name)
          }}
          defaultValue={defaultValue}
          onBlur={() => trigger(name)}
        />
        {placeholder && (
          <label className="formPlaceholder" htmlFor={name}>
            {placeholder}
          </label>
        )}
      </div>
      {errors[name]?.message && (
        <p className="input-error-warning">{errors[name]?.message}</p>
      )}
    </div>
  )
}
NumberInput.defaultProps = {
  placeholder: null,
  label: null,
  disabled: false,
  defaultValue: null,
  className: '',
  onBlurFunc: null,
  prefix: '',
}
