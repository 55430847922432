import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const AppVersion = 1

export interface Client {
  name: string
  isWagerCloseBefore: boolean
  isWagerLived: boolean
  // wagerDisabledMyBets: []
}
const initialClientState: Client = {
  name: 'BetkingBR',
  // wagerDisabledMyBets: ['finish', 'live'],
  isWagerCloseBefore: false,
  isWagerLived: false,
}

// TODO: Estado global da aplicação!
export interface AppState {
  showWagersSelected: number | boolean
  isCheckRemoteUserLoggedIn: boolean
  loading: boolean
  loginFormType: string
  appVersion: number
  isShowPopup: boolean
  isOpenExitConfirm: boolean
  isShowWalkthrough: boolean
  isShowGlobalLoading: boolean
  isGlobalLoadingDefaultChecks: boolean // se o global loading verifica se está logado etc etc...
  isShowRightMenu: boolean
  isShowLeftMenu: boolean
  isShowLeftMenuMobile: boolean
  isShowModalLogin: boolean
  isShowModalForgot: boolean
  isShowPopUp: boolean
  client: Client
}

const initialState: AppState = {
  showWagersSelected: 0,
  loginFormType: 'small',
  isCheckRemoteUserLoggedIn: true,
  isShowPopup: false,
  isShowRightMenu: false,
  isShowLeftMenu: true,
  isShowLeftMenuMobile: false,
  isShowModalLogin: false,
  isShowModalForgot: false,
  isShowPopUp: true,
  loading: false,
  appVersion: AppVersion,
  isOpenExitConfirm: false,
  isShowWalkthrough: true,
  isShowGlobalLoading: false,
  isGlobalLoadingDefaultChecks: true,
  client: initialClientState,
}

export const appReducers = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setShowWagersSelected: (state, action: PayloadAction<number | boolean>) => {
      state.showWagersSelected = action.payload
    },
    setMenuRight: (state, action: PayloadAction<boolean>) => {
      state.isShowRightMenu = action.payload
      // state.isShowLeftMenu = false
      state.isShowLeftMenuMobile = false
    },
    setMenuLeft: (state, action: PayloadAction<boolean>) => {
      state.isShowLeftMenu = action.payload
    },
    setMenuLeftMobile: (state, action: PayloadAction<boolean>) => {
      state.isShowLeftMenu = action.payload
      state.isShowLeftMenuMobile = action.payload
      state.isShowRightMenu = false
    },
    setModalLogin: (state, action: PayloadAction<boolean>) => {
      state.isShowModalLogin = action.payload
    },
    onToggleShowModalLogin: (state) => {
      state.isShowModalLogin = !state.isShowModalLogin
      // console.log(state.isShowModalLogin)
    },
    onHideModalLogin: (state) => {
      state.isShowModalLogin = false
    },
    setModalForgot: (state, action: PayloadAction<boolean>) => {
      state.isShowModalForgot = action.payload
    },
    onHidePopUp: (state) => {
      state.isShowPopup = false
    },
    showPopUp: (state) => {
      const lastPopUp = sessionStorage.getItem('lastPopUp')
      const todaysDate = new Date()
      if (lastPopUp) {
        const lastPopUpDate = new Date(JSON.parse(lastPopUp))
        const hoursDifference = Math.abs(
          (todaysDate.getTime() - lastPopUpDate.getTime()) / 36e5,
        )
        // console.log('hoursDifference', hoursDifference)
        if (hoursDifference > 12) {
          sessionStorage.setItem('lastPopUp', JSON.stringify(new Date()))
          state.isShowPopup = true
        }
      } else {
        sessionStorage.setItem('lastPopUp', JSON.stringify(new Date()))
        state.isShowPopup = true
      }
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      // state.modalLogin = action.payload
    },
    replaceGlobalUIStateFromStorage: (state, params) => {
      // state = state;

      // console.log('params');
      // console.log(params);
      state.isShowGlobalLoading = false

      state.isShowWalkthrough = params.payload.isShowWalkthrough
    },
    setOpenExitConfirm: (state) => {
      state.isOpenExitConfirm = true
    },
    setCloseExitConfirm: (state) => {
      state.isOpenExitConfirm = false
    },
    setOpenGlobalLoading: (state) => {
      state.isShowGlobalLoading = true
    },
    setCloseGlobalLoading: (state) => {
      state.isShowGlobalLoading = false
    },

    setOpenPreloadWithtoutDefaultChecks: (state) => {
      state.isShowGlobalLoading = true
      state.isGlobalLoadingDefaultChecks = false
    },

    setClosePreloadWithtoutDefaultChecks: (state) => {
      state.isShowGlobalLoading = false
      state.isGlobalLoadingDefaultChecks = false
    },

    setWalkthroughComplete: (state) => {
      state.isShowWalkthrough = false
      // console.log('state');
      // console.log(state);
    },
    setCloseIsCheckRemoteUserLoggedIn: (state) => {
      // console.log('setCloseIsCheckRemoteUserLoggedIn')
      state.isCheckRemoteUserLoggedIn = false
    },
  },
})

export const {
  setShowWagersSelected,
  replaceGlobalUIStateFromStorage,
  setOpenGlobalLoading,
  setCloseGlobalLoading,
  setCloseIsCheckRemoteUserLoggedIn,
  setCloseExitConfirm,
  setOpenExitConfirm,
  setWalkthroughComplete,
  setOpenPreloadWithtoutDefaultChecks,
  setClosePreloadWithtoutDefaultChecks,
  setMenuRight,
  setModalLogin,
  setLoading,
  onHideModalLogin,
  setModalForgot,
  onHidePopUp,
  showPopUp,
  onToggleShowModalLogin,
  setMenuLeft,
  setMenuLeftMobile,
} = appReducers.actions

export default appReducers.reducer
