export const PreferencesDescription = () => {
  return (
    <div className="container g-0">
      <h1 className="config-title d-none d-md-block minimalTitle">
        Preferências
      </h1>

      <div style={{ paddingTop: '20px' }}>
        <p>
          Por favor utilize apenas letras, números e símbolos aceitos, sem
          espaços, com um mínimo de 6 e um máximo de 32 caracteres.
        </p>
        <br />
        <p>
          A sua senha não deverá conter o seu nome de usuário, o seu nome, o seu
          e-mail nem o seu ano de nascimento. Você poderá aumentar o grau de
          segurança da sua senha utilizando uma mistura de letras maiúsculas ou
          minúsculas, números e símbolos. Por favor lembre-se de que as senhas
          são sensíveis a maiúsculas.
        </p>
        <p>Os símbolos que se seguem podem ser utilizados na sua senha:</p>
        <p>
          ! &quot; # $ % &amp; ( ) * + , - . / : ; &lt; = &gt; ? _ @ [ \ ] ^ ` |
          ~
        </p>
      </div>
    </div>
  )
}
