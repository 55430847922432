import { useState } from 'react'
import WalletIcon from '../../../../../../../common/icons/funds.svg'
import UserConfigIcon from '../../../../../../../common/icons/user_config.svg'
import { useAppDispatch } from '../../../../../../../core/store/Reducers'

type ContaProps = {
  clickPage: (page: any) => void
}

const Conta = ({ clickPage }: ContaProps) => {
  const dispatch = useAppDispatch()

  const [selectConfig, setSelectConfig] = useState('Conta')

  const account = () => {
    // history.push('/account')
  }

  return (
    <div className="UserMenuDropDown-dados-flex">
      <div className="UserMenuDropDown-dados-flex-item text-center flex-item-100">
        <ul role="main" className="ListUserConfigs-flex">
          <li
            role="none"
            onClick={() => clickPage('bank')}
            className="ListUserConfigs-flex-items">
            <a href="/bank">
              <img alt="icon" src={WalletIcon} />
            </a>
            <h4>Banco</h4>
          </li>

          {/* <li
            role="none"
            onClick={() => clickPage('ME')}
            className="ListUserConfigs-flex-items">
            <img alt="icon" src={TalkIcon} />
            <h4>Mensagens</h4>
          </li> */}
          <li
            role="none"
            onClick={() => clickPage('account')}
            className="ListUserConfigs-flex-items">
            <a href="/account">
              <img alt="icon" src={UserConfigIcon} />
            </a>
            <h4>Minha conta</h4>
          </li>
        </ul>
        {/* <ul role="main" className="ListUserConfigs-flex"> */}
        {/* <li
            role="none"
            onClick={() => clickPage('controls')}
            className="ListUserConfigs-flex-items">
            <img alt="icon" src={ProtectionProtectiveIcon} />
            <h4>Controles de Aposta</h4>
          </li>
          <li
            role="none"
            onClick={() => clickPage('controls')}
            className="ListUserConfigs-flex-items">
            <img alt="icon" src={ChartIcon} />
            <h4>Minha Atividade</h4>
          </li> */}
        {/* <li
            role="none"
            onClick={() => clickPage('controls')}
            className="ListUserConfigs-flex-items">
            <img alt="icon" src={WatchIcon} />
            <h4>Histórico</h4>
          </li> */}
        {/* </ul> */}
        {/* <ul role="main" className="ListUserConfigs-flex">
          <li
            role="none"
            onClick={() => clickPage('pause')}
            className="ListUserConfigs-flex-items">
            <img alt="icon" src={LogoutIcon} />
            <h4>Fazer uma pausa</h4>
          </li>
        </ul> */}
      </div>
    </div>
  )
}

export default Conta
