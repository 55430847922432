import * as yup from 'yup'

const requiredMsg = 'Esse campo é obrigatório!'
const minPassLengthMsg = 'A senha deve ter no mínimo 6 caracteres'
const maxPassLengthMsg = 'A senha deve ter no máximo 32 caracteres'
// const minPhoneLenghtMsg = 'Número muito curto'
const minCPFLengthMsg = 'CPF muito curto'

export const withDrawPixSchema = yup
  .object({
    ownerName: yup.string().required(requiredMsg),
    // Validate CPF pattern?
    cpf: yup.string().min(11, minCPFLengthMsg).required(requiredMsg),
    amount: yup.string().required(requiredMsg),
    password: yup
      .string()
      .required(requiredMsg)
      .min(1, minPassLengthMsg)
      .max(32, maxPassLengthMsg),
  })
  .required()
