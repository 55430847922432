import { Outlet } from 'react-router-dom'
import '../../../../common/config.css'
import '../../../../common/forms.scss'

// type AuthRoute = {
//   label: string
//   route: string
// }

// interface Props {
// title: string
// routes: AuthRoute[]
// }

// type OutletContextType = { currentRoute: menuLeft; isMobile: boolean }

export const GameNavigator = () => {
  // const location = useLocation()
  // const dispatch = useAppDispatch()
  // const wagers = useAppSelector((state: RootState) => state.wagers)
  // const gameList = useAppSelector((state: RootState) => state.gameList)
  // useEffect(() => {
  //   // console.log('mudou game list')
  //   ;(async () => {
  //     let totalPrize = 0
  //     let pricesSum = 0
  //     const wagersSelectionTmp = [] as oddChecked[] // cria uma relação
  //     await gameList.items.forEach(async (item) => {
  //       const checkedOddsItens = item.odds.filter((odd) => odd.checked === true)
  //       if (checkedOddsItens.length) {
  //         checkedOddsItens.forEach((odd) => {
  //           // console.log('TOTAL PRIZE BEFORE', totalPrize)
  //           // console.log(odd.price, odd.rate)
  //           if (odd.price) {
  //             totalPrize += odd.price * odd.rate
  //             pricesSum += odd.price
  //           }
  //           // console.log('TOTAL PRIZE AFTER', totalPrize)
  //           wagersSelectionTmp.push({ info: item.info, odd })
  //         })
  //       }
  //     })
  //     await dispatch(setPriceSum(pricesSum))
  //     // Causing 1 rerender
  //     await dispatch(setOdds(wagersSelectionTmp))
  //     await dispatch(
  //       setTotalWagersAndPrize({
  //         totalPrize,
  //         totalWagers: wagersSelectionTmp.length,
  //       }),
  //     )
  //   })()
  // }, [gameList])

  // useEffect(() => {
  //   ;(async () => {
  //     let totalChecked = 0
  //     await gameList.items.map(async (item) =>
  //       item.odds.forEach((odd) => {
  //         if (odd.checked) {
  //           totalChecked += 1
  //         }
  //       }),
  //     )
  //     await dispatch(setTotalWagers(totalChecked))
  //   })()
  // }, [gameList])

  return <Outlet />
}
