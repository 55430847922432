/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserPicture {
  url: string
  status: boolean
}

const photo: UserPicture = {
  url: '',
  status: false,
}

export interface UserPrefs {
  language: string
  timezone: string
  oddsFormat: string
  // decimalSeparator: ',' | '.'
  maxInactivityPeriod: string
  notification: boolean
  textMessage: boolean
  emailNotification: boolean
  onlineMessage: boolean
  onlinePopUps: boolean
}

export interface AddressType {
  cep: string
  streetName: string
  streetNumber: string
  houseNumber: string
  apartmentNumber: string
  city: string
  state: string
  countryId: string
  countryName: string
}
export interface UserInfo {
  name: string
  email: string
  phone: string
  photo: UserPicture
  cpf: string
}

type UserState = {
  token: string
  isLogged: boolean
  info: UserInfo
  address?: AddressType
  prefs: UserPrefs
}
const initialUserPrefs: UserPrefs = {
  language: 'ptBR',
  timezone: 'America/Sao_Paulo',
  oddsFormat: 'Decimais',
  // decimalSeparator: ',',
  maxInactivityPeriod: '3 Horas',
  notification: true,
  textMessage: true,
  emailNotification: true,
  onlineMessage: true,
  onlinePopUps: true,
}

const initialState: UserState = {
  token: '',
  info: {
    name: '',
    email: '',
    phone: '',
    cpf: '09090846235',
    photo,
  },
  address: {
    cep: '',
    streetName: '',
    streetNumber: '',
    houseNumber: '',
    apartmentNumber: '',
    city: '',
    state: '',
    countryId: '',
    countryName: 'Brasil',
  },
  prefs: initialUserPrefs,
  isLogged: false,
}

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedInFromStorage: (state, action: PayloadAction<UserState>) => {
      state.token = action.payload.token
      state.info = action.payload.info
      state.isLogged = true
    },
    setLoggedIn: (state, action: PayloadAction<UserState>) => {
      localStorage.setItem('prefs', JSON.stringify(action.payload.prefs))
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem(
        'isLoggedIn',
        JSON.stringify(action.payload.isLogged),
      )
      if (action.payload.address) {
        state.address = action.payload.address
      }
      state.token = action.payload.token
      state.info = action.payload.info
      state.isLogged = true
    },
    setPrefs: (state, action: PayloadAction<UserPrefs>) => {
      console.log('SET PREFS', action.payload)
      state.prefs = action.payload
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.prefs.language = action.payload
    },
    setLoggedUser: (
      state,
      action: PayloadAction<{
        user: UserInfo
        token: string
        address: AddressType
      }>,
    ) => {
      state.info = action.payload.user
      if (action.payload.token) {
        state.token = action.payload.token
      }
      // console.log('action.payload.address', action.payload.address)
      if (action.payload.address) {
        state.address = action.payload.address
      }

      // console.log(state.user.email)
      state.isLogged = true
    },
    setLogout: (state) => {
      localStorage.clear()
      state.isLogged = false
      state.token = ''
    },
  },
})

export const {
  setLoggedInFromStorage,
  setLoggedIn,
  setLogout,
  setLoggedUser,
  setPrefs,
  setLanguage,
} = userReducer.actions

export default userReducer.reducer
