/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { setModalLogin } from '../../../../../../../core/store/items/app'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { changeOddSelection } from '../../store/gameList'
import { Odds } from '../../types/odds'
import '../Games.scss'

type Props = {
  odds: Odds[]
  // onOddClick: (odd: Odds) => void
}
export const GameCardOdds = ({ odds }: Props) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state: RootState) => state.user)
  // console.log(item.info.betGameId)
  // console.log('Odds', odds)

  const onOddClick = async (odd: Odds) => {
    if (!user.isLogged) {
      dispatch(setModalLogin(true))
    } else {
      await dispatch(changeOddSelection(odd))

      // console.log(wagers.totalPrize)
    }
  }

  return (
    <div className="card-live-games-items">
      {odds.map((odd) => {
        const classChecked = odd.checked ? 'on_wager' : ''
        return (
          <div
            onClick={() => {
              onOddClick(odd)
            }}
            key={odd.betGamesOddId}
            className={`card-live-game-tobet ${classChecked}`}>
            <div>
              {/* TEM QUE MUDAR O FIXED PARA VARIAVEL */}
              <h1>{odd.rate.toFixed(2)}</h1>
            </div>
          </div>
        )
      })}
    </div>
  )
}
