import { FcOk } from 'react-icons/fc'
import { MdCancel, MdPending } from 'react-icons/md'
import { getMarketById } from '../../../../../../../common/functions/market'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { BetOdd, OddStatus } from '../../data'

interface Props {
  odd: BetOdd
}

const chooseBetCardOddName = (odd: BetOdd) => {
  const oddName = odd.oddInfo.name
  const specifier = odd.oddInfo.specifier_value
  if (specifier) return `${oddName} ${specifier}` // Falta specifier label
  switch (oddName.toLocaleLowerCase()) {
    case 'casa' || '1':
      return odd.gameInfo.teams[0].name
    case 'fora' || '1':
      return odd.gameInfo.teams[1].name
    default:
      return oddName
  }
}

const switchOddIcon = (oddStatus: OddStatus) => {
  switch (oddStatus) {
    case 'success':
      return <FcOk color="green" size="0.85rem" />
    case 'error':
      return <MdCancel color="red" size="0.9rem" />
    case 'blank':
      return <MdPending color="gray" size="0.9rem" />
    default:
      return <MdPending color="gray" size="0.9rem" />
  }
}

export const BetOddCard = ({ odd }: Props) => {
  const markets = useAppSelector((state: RootState) => state.markets)
  // console.log('OOOOOOOODDDeaeae', odd)
  return (
    <div className="col-12 col-lg-6">
      <div className="bet-odd-card">
        <div className="bet-odd-card-header">
          <div className="odd-icon-container">
            {switchOddIcon(odd.oddInfo.status)}
          </div>
          <b>{chooseBetCardOddName(odd)}</b>
          <p>{odd.oddInfo.rate} </p>
        </div>
        <p>{getMarketById(odd.oddInfo.market_id ?? 1, markets.items)?.name}</p>
        <p>{`${odd.gameInfo.teams[0].name} X ${odd.gameInfo.teams[1].name}`}</p>
      </div>
    </div>
  )
}
