/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-template */
/* eslint-disable no-eval */
/* eslint-disable no-extend-native */
/* eslint-disable no-template-curly-in-string */

import { useState } from 'react'

interface GameDropdownProps {
  onChangeOpenState?: (isOpen: boolean) => void
  body: JSX.Element
  header: JSX.Element | string
  initialOpenState?: boolean
}

export const GameDropdown = ({
  body,
  header,
  onChangeOpenState,
  initialOpenState,
}: GameDropdownProps) => {
  const [isOpen, setIsOpen] = useState(initialOpenState as boolean)
  const handleChangeOpenState = () => {
    setIsOpen(!isOpen)
    if (onChangeOpenState) onChangeOpenState(isOpen)
  }

  return (
    <div className="oddsDropdown">
      <div className="dropdownHeader" onClick={() => handleChangeOpenState()}>
        {header}
      </div>
      <div className="oddsListContainer">
        <div className={`oddsList ${!isOpen ? 'hideOdds' : ''}`}>{body}</div>
      </div>
    </div>
  )
}

const handleDefaultOpen = () => {
  return null
}
GameDropdown.defaultProps = {
  onChangeOpenState: handleDefaultOpen,
  initialOpenState: false,
}
