import { GameRoute } from './GameDetailsNavigator'

export const GameDetailRoutes: GameRoute[] = [
  {
    label: 'Todos', // Main
    route: 'all',
    listOfIds: [
      1000002, 1000003, 1000004, 1000005, 1000007, 1000008, 1000009, 1000010,
      10000011, 10000012, 10000013, 10000014, 10000015, 10000016,
    ],
  },
  // {
  //   label: 'Bet Builder',
  //   route: 'bet-builder',
  //   markets: [1, 2, 3, 4, 12, 13, 14],
  // },
  // {
  //   label: 'Odds Asiáticas',
  //   route: 'asian-odds',
  //   markets: [1000004],
  // },
  {
    label: 'Gols',
    route: 'score',
    listOfIds: [1000003, 1000016],
  },
  {
    label: '1º Tempo/2º Tempo',
    route: 'halfs',
    listOfIds: [1000006],
  },
]
