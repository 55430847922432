import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slideshow from '../types/slideshow'

export const MainCarousel = (props: { slideshow: Slideshow[] }) => {
  const { slideshow } = props
  // console.log(slideshow)
  return (
    <Carousel
      showArrows={false}
      showThumbs={false}
      autoPlay
      //   showIndicators={false}
      showStatus={false}
      infiniteLoop>
      {slideshow.map((item) => {
        return (
          <div key={item.img} className="">
            <img alt={item.text} className="d-block d-sm-none" src={item.img} />
            <img
              // style={{ objectFit: 'contain' }}
              alt={item.text}
              className="d-none d-md-block"
              src={item.img_large}
            />
          </div>
        )
      })}
    </Carousel>
  )
}
