import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { onHideModalLogin } from '../../../../../core/store/items/app'
// import { setLoggedIn } from '../../../../../core/store/items/user'
import {
  // RootState,
  useAppDispatch,
} from '../../../../../core/store/Reducers'
import { FormLogin } from './components/FormLogin'
// import { LoginErrorBox } from './components/LoginErrorBox'
import './ModalLogin.scss'
// import { FormLoginValues } from './type/login'
// import { onPostFormLoginRequest } from './xhr/auth.xhr'

export const ModalLogin = () => {
  // const user = useAppSelector((state: RootState) => state.user)
  const refModalLoginBody = useRef() as MutableRefObject<HTMLDivElement>
  const dispatch = useAppDispatch()
  // const [onPostError, setOnPostError] = useState<boolean | undefined>(undefined)
  const [isModalLoaded, setIsModalLoaded] = useState(false)

  const handleClickSignUp = () => {
    dispatch(onHideModalLogin())
  }

  // #START MINIMIZE MODAL WHEN CLICK OUTSIDE
  useEffect(() => {
    function handlerOutsideModalLogin(e: { target: any }) {
      const el = e.target.closest('div[id=modalLoginBody]')
      const formBody = e.target.closest('form[id=formBody]')

      // clicou no svg do display input password?
      const isClickOnPasswordDisplay = e.target.closest('.isShowPassword')

      // console.log(el, formBody)
      if (!el && !formBody && !isClickOnPasswordDisplay) {
        // console.log('click outside modal login')
        dispatch(onHideModalLogin())
      }
    }
    if (isModalLoaded) {
      // console.log('BINDOU CLICK addEventListener')
      document.body.addEventListener('click', handlerOutsideModalLogin)
    }
    return () => {
      document.body.removeEventListener('click', handlerOutsideModalLogin)
    }
  }, [dispatch, isModalLoaded])
  // #END MINIMIZE MODAL WHEN CLICK OUTSIDE

  useEffect(() => {
    refModalLoginBody.current.style.transform = `translateY(105px)`
    setIsModalLoaded(true)
  }, [])
  // transform: translateY(105px);
  return (
    <div id="modalLogin">
      <div id="modalLoginDefault">
        <div className="overlay" />
        <div id="modalLoginBody" ref={refModalLoginBody}>
          <FormLogin />
          <div className="modalLoginSignUp">
            <p>
              Novo usuário?{' '}
              <Link onClick={() => handleClickSignUp()} to="/signup">
                {' '}
                Crie uma nova conta.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
