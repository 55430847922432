/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-autofocus */

import { useState } from 'react'
import { isMobile } from 'react-device-detect'
// import SearchIcon from '../../../../../common/icons/search.svg'
import { RootState, useAppSelector } from '../../../../../core/store/Reducers'
import { GameSearchCard } from './components/gameSearchCard'
import { searchGamesAPI, SearchItem } from './search.xhr'
import './searchLink.scss'

interface Props {
  className?: string
  handleCloseSearch: () => void
}

export const Search = ({ className, handleCloseSearch }: Props) => {
  const [searchResult, setsearchResult] = useState<SearchItem[]>(
    [] as SearchItem[],
  )
  const gameList = useAppSelector((state: RootState) => state.gameList)
  const [showResults, setShowResults] = useState<boolean>(true)
  const [inputValue, setInputValue] = useState<string>('')
  // const searchGames = (query: string) => {
  //   // console.log(query)
  //   // console.log('Game List', gameList)
  //   const result = gameList.items.filter(
  //     (game) =>
  //       game.teams[0].name
  //         .toLocaleLowerCase()
  //         .includes(query.toLocaleLowerCase()) ||
  //       game.teams[1].name
  //         .toLocaleLowerCase()
  //         .includes(query.toLocaleLowerCase()),
  //   )
  //   if (result.length > 0) {
  //     setShowResults(true)
  //   }
  //   // console.log(result)
  //   setsearchResult(result)
  // }

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
  }

  const inputOnBlur = async () => {
    await timeout(500)
    // setShowResults(false)
    setsearchResult([])
    setInputValue('')
  }
  // const inputWidth = isMobile ? '77%' : '90%'
  const classMobileContainer = isMobile ? 'SearchResultContainerMobile' : ''
  let filterTimeout: any

  const debounce = (value: string) => {
    clearTimeout(filterTimeout)
    filterTimeout = setTimeout(() => {
      handleSearch(value)
    }, 500)
  }
  const handleSearch = async (query: string) => {
    console.log(query)
    try {
      const result = await searchGamesAPI(query)
      if (result) {
        setsearchResult(result)
      } else setsearchResult([])
    } catch (err) {
      console.log(err)
    }
  }

  // useEffect(() => {
  //   debounce(inputValue)
  // }, [inputValue])

  return (
    <div className={className}>
      <div className="col-12" style={{ padding: '0 1rem' }}>
        <input
          className="col-10 col-sm-8 no-outiline-focus"
          autoFocus
          style={{
            fontSize: '1rem',
            borderRadius: '0.4em',
            padding: '0.4rem 10px',
          }}
          placeholder="Buscar partidas"
          // value={inputValue} // Atrapalha o debounce usar o value // Resolver
          onChange={(e) => {
            debounce(e.target.value)
            // setInputValue(e.target.value)
          }}
          onBlur={() => inputOnBlur()}
        />
        <span
          className="closeSearchText col-2 col-sm-4"
          onClick={() => {
            handleCloseSearch()
          }}>
          Fechar
        </span>
      </div>
      {searchResult.length > 0 && (
        <div
          className={`container g-0 searchResultContainer ${classMobileContainer}`}>
          {showResults &&
            searchResult.map((game) => {
              return <GameSearchCard key={game.info.betGameId} game={game} />
            })}
        </div>
      )}
    </div>
  )
}

Search.defaultProps = {
  className: '',
}
