import axios from 'axios'
import apiHelper from '../../../../../../auth/services/apiHelper'
import { Bet } from '../data'

/**
 * Retorna os atributos quando o usuário está logado.
 * @param token
 * @returns
 */

const token = localStorage.getItem('token')

export const getMyBets = async () => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('wagers_user/getAll'),
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const finishBet = async (bet: Bet) => {
  const data = {
    bet_wager_id: bet.betInfo.betId.toString(),
    returnOffered: bet.betInfo.returnOffered,
  }
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: apiHelper.baseUrl('wagers_actions/cancel'),
      data,
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export default getMyBets
