export const sanitizeKeyboardInnerText = (
  priceUserInput: string,
  innerText: string,
  decimalSeparator: string,
  decimalSize: number,
) => {
  let newText = ` ${priceUserInput}`.slice(1)

  // console.log(newText)
  if (innerText === '<') {
    if (priceUserInput === '0,') {
      newText = ''
    } else {
      newText = priceUserInput.slice(0, -1)
    }
  } else if (priceUserInput.length) {
    // INICIO já existe algo digitado..
    if (
      // SE JÁ EXISTIR O SEPARADOR DE DECIMAL NA STRING
      innerText === decimalSeparator &&
      priceUserInput.indexOf(innerText) >= 0
    ) {
      newText = priceUserInput
    }
    // SE JÁ TIVER A CASA DE DECIMAL
    else if (priceUserInput.indexOf(decimalSeparator) >= 0) {
      // se já tiver decimal
      if (priceUserInput.split(decimalSeparator)[1].length < decimalSize) {
        newText += innerText
      }
    } else {
      newText += innerText
    }
    // FINAL se já tiver algo digitado no keyboard.
  } else if ([',', '0'].indexOf(innerText) >= 0) {
    newText = '0,'
  } else {
    newText += innerText
  }
  // console.log(newText)

  return newText
}
