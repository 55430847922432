import React from 'react'
import { Link } from 'react-router-dom'
import {
  RootState,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import { SearchItem } from '../search.xhr'
// import SearchIcon from '../../../../../../common/icons/search.svg'

interface Props {
  game: SearchItem
}

export const GameSearchCard = ({ game }: Props) => {
  const {
    betCnfTournamentId,
    betGameId,
    betCnfSportId,
    gameStart,
    betCnfTournamentName,
  } = game.info
  const homeTeam = game.teams[0]
  const awayTeam = game.teams[1]
  const sports = useAppSelector((state: RootState) => state.sports)
  const sportActual = sports.items.find((item) => item.id === betCnfSportId) // Alterar para ser dinamico por esporte, falta o sport id
  // console.log('Game', game)
  return (
    <Link
      to={`/games/sport/${sportActual?.route}/${betCnfTournamentId}/${betGameId}`}
      className="row  g-0 Link SearchLink"
      style={{ padding: '0.8rem 0.5rem' }}>
      <p
        style={{ color: 'yellow' }}
        className="col-md-12 linkItem">{`${game.info.location} ${betCnfTournamentName}`}</p>
      <p className="col-md-12 linkItem">{`${homeTeam.name} X ${awayTeam.name}`}</p>
      <p className="col-md-12 linkItem">{gameStart}</p>
    </Link>
  )
}
