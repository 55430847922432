import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { getHighlightHome } from '../../../../../../common/functions/odds'
import { setMenuLeft, showPopUp } from '../../../../../../core/store/items/app'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import { GameTable } from '../../Games/views/components/MainTable/GameTable'
import { SportsMenu } from '../../Sports/Menu/SportsMenu'
import { setHideWager } from '../../Wagers/store/wagers'
import { MainCarousel } from '../components/carousel'
import { HighlightResponsive } from '../components/highlight/highlightResponsive'
import './Dashboard.css'

export type ScreenSize = {
  dynamicWidth: number
  dynamicHeight: number
}

export const Dashboard = () => {
  const dispatch = useAppDispatch()
  const { isShowPopup } = useAppSelector((state) => state.app)
  const dashboard = useAppSelector((state: RootState) => state.dashboard)
  const gameList = useAppSelector((state: RootState) => state.gameList)
  const sports = useAppSelector((state: RootState) => state.sports)
  const chosenSport = sports.items.find((sport) => sport.route === 'futebol')

  const [screenSize, getDimension] = useState<ScreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  })
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    })
  }
  const hightlightList = getHighlightHome(gameList.items)

  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [screenSize])

  useEffect(() => {
    if (isMobile) dispatch(setMenuLeft(true))
    else dispatch(setMenuLeft(true))
    dispatch(setHideWager(false))
    dispatch(showPopUp())
    // console.log('hightlightList', hightlightList)
  }, [])

  useEffect(() => {
    // console.log('isShowPopup', isShowPopup)
  }, [isShowPopup])

  // console.log('CHOSEN SPORT =', chosenSport)

  return (
    <div className="row" style={{ overflowY: 'auto', height: '100%' }}>
      <div className="col-12 col-md-12">
        <SportsMenu />
        <div id="caroselContainer">
          <MainCarousel slideshow={dashboard.item.slideshow} />
        </div>
        {hightlightList.length > 0 && (
          <>
            <h3 className="main-subtitle">Partidas em Destaque</h3>
            <HighlightResponsive CardsList={hightlightList} />
          </>
        )}
        {chosenSport && (
          <GameTable sport={chosenSport} hideHighlight="isHighlightHome" />
        )}
      </div>
      {/* {isShowPopup && <ModalMarketing />} */}
    </div>
  )
}
