import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { UserPrefs } from '../../../../../../../core/store/items/user'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { SubmitButton } from '../../../../../Global/components/buttons/SubmitButton'
import { PasswordInput } from '../../../../../Global/components/Inputs/PasswordInput'
import { ToggleInput } from '../../../../../Global/components/Inputs/ToggleInput'
import { updatePreferences } from '../../xhr/dados.xhr'
import { PreferencesDescription } from './Description'
import maxInactivityPeriodList from './maxInactivityPeriodList.json'
import oddsFormatList from './oddsFormatList.json'
import timezoneList from './timezonesList.json'

export type PreferencesFormData = {
  language: string
  timezone: string
  oddsFormat: string
  maxInactivityPeriod: string
  notification: boolean
  textMessage: boolean
  phone: string
  emailNotification: boolean
  email: string
  onlineMessage: boolean
  onlinePopUps: boolean
  password: string
}

export const Preferences = () => {
  const user = useAppSelector((state: RootState) => state.user)
  const { t } = useTranslation()

  const requiredMsg = 'Esse campo é obrigatório!'
  const minPassLengthMsg = 'A senha deve ter no mínimo 6 caracteres'
  const maxPassLengthMsg = 'A senha deve ter no máximo 32 caracteres'
  const LanguageList = [
    { label: 'Português', value: 'ptBR' },
    { label: 'Inglês', value: 'en' },
  ]
  const schema = yup
    .object({
      // email: yup.string().required(requiredMsg),
      // phone: yup.string().required(requiredMsg),
      password: yup
        .string()
        .required(requiredMsg)
        .min(6, minPassLengthMsg)
        .max(32, maxPassLengthMsg),
    })
    .required()

  const { phone, email } = user.info
  const {
    language,
    timezone,
    maxInactivityPeriod,
    oddsFormat,
    notification,
    textMessage,
    emailNotification,
    onlineMessage,
    onlinePopUps,
  } = user.prefs

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
    handleSubmit,
  } = useForm<PreferencesFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      language,
      timezone,
      maxInactivityPeriod,
      oddsFormat,
      notification,
      textMessage,
      phone,
      emailNotification,
      email,
      onlineMessage,
      onlinePopUps,
    },
  })

  useEffect(() => {
    Object.entries(user.prefs).forEach((pref) => {
      setValue(pref[0] as keyof PreferencesFormData, pref[1])
    })
  }, [user.prefs])

  const onSubmit = async (data: PreferencesFormData) => {
    // console.log(data)
    const response = await updatePreferences(user.token, data)
    if (!response.success) alert(response.msg)
    else console.log(response)
    const userPrefs: UserPrefs = {
      language: data.language,
      timezone: data.timezone,
      oddsFormat: data.oddsFormat,
      maxInactivityPeriod: data.maxInactivityPeriod,
      notification: data.notification,
      textMessage: data.textMessage,
      emailNotification: data.emailNotification,
      onlineMessage: data.onlineMessage,
      onlinePopUps: data.onlinePopUps,
    }
    localStorage.setItem('prefs', JSON.stringify(userPrefs))
  }

  return (
    <div className="container g-0" style={{ maxWidth: '600px' }}>
      <PreferencesDescription />
      <div className="row">
        <form
          style={{ width: '100%', paddingTop: '30px' }}
          onSubmit={handleSubmit(onSubmit)}
          noValidate>
          <div className="form-field-column col-md-12" key="language">
            <label style={{ paddingBottom: '5px' }} htmlFor="Language">
              {t('Language')}
            </label>
            <select
              className="form-field selectInput"
              {...register(`language`)}>
              {LanguageList.map((lang) => {
                return (
                  <option key={lang.value} value={lang.value}>
                    {lang.label}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="form-field-column col-md-12" key="timezone">
            <label style={{ paddingBottom: '5px' }} htmlFor="timezone">
              {t('timezone')}
            </label>
            <select
              className="form-field selectInput"
              {...register(`timezone`)}>
              {timezoneList.map(({ name, offset }) => {
                return (
                  <option key={name} value={name}>
                    {name} - {offset}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="form-field-column col-md-12" key="oddsFormat">
            <label style={{ paddingBottom: '5px' }} htmlFor="oddsFormat">
              {t('OddsFormat')}
            </label>
            <select
              className="form-field selectInput"
              {...register('oddsFormat')}>
              {oddsFormatList.map((oddsFormatItem) => {
                return (
                  <option key={oddsFormatItem} value={oddsFormatItem}>
                    {oddsFormatItem}
                  </option>
                )
              })}
            </select>
          </div>

          <div
            className="form-field-column col-md-12"
            key="maxInactivityPeriod">
            <label
              style={{ paddingBottom: '5px' }}
              htmlFor="maxInactivityPeriod">
              {t('MaxInactivityPeriod')}
            </label>
            <select
              className="form-field selectInput"
              {...register('maxInactivityPeriod')}>
              {maxInactivityPeriodList.map((period) => {
                return (
                  <option key={period} value={period}>
                    {period}
                  </option>
                )
              })}
            </select>
          </div>
          <h3 className="form-subtitle">{t('ChooseHowToRecieveOffers')}</h3>
          <legend className="form-legend">{t('ExternalComunication')}</legend>
          <ToggleInput
            label={t('Notification')}
            name="notification"
            register={register}
          />
          <ToggleInput
            label={t('TextMesage')}
            name="textMessage"
            register={register}
          />
          {/* {(watch('textMessage') || watch('notification')) && (
            <PhoneInput
              name1="countryCode"
              name2="phone"
              defaultValue1={55}
              setValue={setValue}
              register={register}
              errors={errors}
              trigger={trigger}
              className="row"
            />
          )} */}
          <ToggleInput
            label={t('email')}
            name="emailNotification"
            register={register}
          />
          {/* {watch('emailNotification') && ( */}
          {/* <MainInput
            name="email"
            type="email"
            placeholder="email"
            register={register}
            errors={errors}
          /> */}
          {/* )} */}
          <PasswordInput
            name="password"
            register={register}
            placeholder="Senha"
            errors={errors}
            className="col-12"
            hideRightIcon
          />
          <SubmitButton
            text={t('Update')}
            disableSubmit={Object.entries(errors).length > 0}
          />
        </form>
      </div>
    </div>
  )
}
