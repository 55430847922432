import { useEffect, useState } from 'react'
import { Collapse } from 'react-collapse'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../../../../common/functions/formatting'
import { setMenuRight } from '../../../../../../core/store/items/app'
import { setLogout } from '../../../../../../core/store/items/user'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import Conta from './OptionsMenu/Conta'
import { Offers } from './OptionsMenu/Offers'
import { Preferences } from './OptionsMenu/Preferences'
import './UserMenu.scss'

const UserMenu = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const wallet = useAppSelector((state: RootState) => state.wallet)
  const cash = formatCurrency(wallet.cash)
  const cashback = formatCurrency(wallet.cashback)
  const totalCash = formatCurrency(wallet.cash + wallet.cashback)

  const [selectConfig, setSelectConfig] = useState('account')

  const userInfo = useAppSelector((state: RootState) => state.user)
  const [isModalLoaded, setIsModalLoaded] = useState(false)

  const clickPage = () => {
    dispatch(setMenuRight(false))
    // history.push(`/${page}`)
  }

  const logout = () => {
    // history.push('/logout')
    dispatch(setMenuRight(false))
    dispatch(setLogout())
  }
  const toogleSelectConfig = (page: any) => {
    setSelectConfig(page)
  }

  useEffect(() => {
    function handlerOutsideWagerSelection(e: { target: any }) {
      const elIntoWagerSelection = e.target.closest('div[id=UserMenuDropDown]')
      // console.log('elIntoWagerSelection', elIntoWagerSelection)
      if (!elIntoWagerSelection) {
        console.log('click outside menu')
        dispatch(setMenuRight(false))
      }
    }
    if (isModalLoaded) {
      // console.log('BIND CLICK outside UserMenu addEventListener')
      document.body.addEventListener('click', handlerOutsideWagerSelection)
    }
    return () => {
      document.body.removeEventListener('click', handlerOutsideWagerSelection)
    }
  }, [dispatch, isModalLoaded])

  useEffect(() => {
    setIsModalLoaded(true)
  }, [])

  useEffect(() => {
    console.log('selectConfig', selectConfig)
  }, [selectConfig])

  return (
    <div id="UserMenuDropDownModule" className="UserMenuDropDownModule">
      <div className="overlay" />
      <div id="UserMenuDropDown" className="UserMenuDropDown">
        <div className="UserMenuDropDown-dados-flex">
          <div className="UserMenuDropDown-dados-flex-item">
            <h4>{userInfo.info.name}</h4>
            <h1>{totalCash}</h1>
          </div>
          <div className="UserMenuDropDown-dados-flex-item insertCoin">
            <button type="button" className="btn ">
              {t('Fund')}
            </button>
          </div>
        </div>
        <div className="UserMenuDropDown-dados-flex">
          <div className="UserMenuDropDown-dados-flex-item">
            <h4>Disponivel</h4>
            <h3>{cash}</h3>
          </div>
          <div className="UserMenuDropDown-dados-flex-item">
            <h4>Cashback</h4>
            <h3>{cashback}</h3>
          </div>
        </div>
        <div className=" style-bg-grey-300" style={{ padding: '0px' }}>
          <ul
            role="main"
            className="UserMenuDropDown-dados-list"
            style={{ padding: '0px' }}>
            <li
              role="none"
              onKeyPress={() => toogleSelectConfig('account')}
              onClick={() => toogleSelectConfig('account')}>
              Conta
            </li>
            {/* <li
              role="none"
              onKeyPress={() => toogleSelectConfig('/')}
              onClick={() => toogleSelectConfig('toasts')}>
              Alertas
            </li> */}
            <li
              role="none"
              onKeyPress={() => toogleSelectConfig('offers')}
              onClick={() => toogleSelectConfig('offers')}>
              Ofertas
            </li>
            <li
              role="none"
              onKeyPress={() => toogleSelectConfig('preferences')}
              onClick={() => toogleSelectConfig('preferences')}>
              Preferências
            </li>
          </ul>
          <div
            className="UserMenuDropDown-dados-flex-item text-center"
            style={{
              marginRight: 5,
            }}
          />
        </div>
        <Collapse isOpened={selectConfig === 'account'}>
          <Conta clickPage={clickPage} />
        </Collapse>
        <Collapse isOpened={selectConfig === 'offers'}>
          <Offers />
        </Collapse>
        <Collapse isOpened={selectConfig === 'preferences'}>
          <Preferences />
        </Collapse>

        <div className="UserMenuDropDown-dados-flex">
          <div className="UserMenuDropDown-dados-flex-item text-center flex-item-100">
            <ul className="ListUserfull-flex">
              <li>
                <Link to="/ajuda">Ajuda</Link>
              </li>

              {/* <li>
                <Link to="/ajuda">Jogo Responsável</Link>
              </li> */}

              <li role="none" onKeyPress={logout} onClick={() => logout()}>
                <Link to="/logout">Sair</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default UserMenu
