import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setMenuLeftMobile } from '../../../../../../core/store/items/app'
import {
  RootState,
  useAppDispatch,
} from '../../../../../../core/store/Reducers'
import { Search } from '../../../views/Search'
import './MenuMain.css'

const MenuList = () => {
  const dispatch = useAppDispatch()
  const [currentPathname, setCurrentPathname] = useState<string>()
  const [openSearch, setOpenSearch] = useState<boolean>(false)
  const menu = useSelector((state: RootState) => state.menu)
  const handleCloseMenuMobile = () => {
    if (isMobile) dispatch(setMenuLeftMobile(false))
  }

  const handleClickMenuItem = (newPathname: string) => {
    handleCloseMenuMobile()
    setCurrentPathname(newPathname)
  }

  const handleCloseSearch = () => {
    setOpenSearch(false)
  }

  useEffect(() => {
    const url = new URL(document.location.toString())
    // console.log(url.pathname)
    setCurrentPathname(url.pathname)
  }, [currentPathname])

  return (
    <ul>
      {isMobile && (
        <li style={{ paddingBottom: '0.5rem' }}>
          <Search handleCloseSearch={handleCloseSearch} />
        </li>
      )}
      <li>
        <Link
          className={currentPathname === '/' ? 'MenuMainActiveItem' : ''}
          style={{ paddingLeft: '3.5rem' }}
          to="/"
          onClick={() => handleClickMenuItem('/')}>
          Destaques
        </Link>
      </li>
      {menu.items.length && !openSearch
        ? menu.items.map((item) => {
            return (
              <li key={item.route}>
                <Link
                  className={
                    currentPathname === `/games/sport/${item.route}`
                      ? 'MenuMainActiveItem'
                      : ''
                  }
                  to={`games/sport/${item.route}`}
                  onClick={() =>
                    handleClickMenuItem(`games/sport/${item.route}`)
                  }>
                  {item.icon && <img src={item.icon} alt={item.name} />}
                  {item.name}
                </Link>
              </li>
            )
          })
        : ''}
    </ul>
  )
}

export const MenuMain = () => {
  return (
    // eslint-disable-next-line react/style-prop-object
    <div className="MenuMain">
      {/* <h2 className="text-color-white">Lista de jogos</h2> */}

      <MenuList />
    </div>
  )
}

export default MenuMain
