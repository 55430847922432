import { useSelector } from 'react-redux'
import { useRoutes } from 'react-router-dom'
// import '../node_modules/bootstrap/scss/'
import RoutesAuth from './app/bet/Auth/routes/RoutesAuth'
import './app/bet/Global/i18n'
import CheckRemoteLoggedIn from './app/bet/Global/views/CheckRemoteLoggedIn/CheckRemoteLoggedIn'
import RoutesUnauth from './app/bet/Unauth/routes/RoutesUnauth'
import { RootState } from './core/store/Reducers'

const AppContainer = () => {
  const app = useSelector((state: RootState) => state.app)
  const isLogged = useSelector((state: RootState) => state.user.isLogged)

  // const location = useLocation()
  // const route = useRoutes()
  // console.log(location)
  const { isCheckRemoteUserLoggedIn } = app
  const authRouteComponents = useRoutes([RoutesAuth])
  const unauthRouteComponents = useRoutes([RoutesUnauth])
  return (
    <>
      {/* {app.isShowGlobalLoading && <Loading />} */}
      {isCheckRemoteUserLoggedIn && !isLogged && <CheckRemoteLoggedIn />}

      {/* is not logged in ... */}
      {!isCheckRemoteUserLoggedIn && !isLogged && unauthRouteComponents}

      {/*  logged . */}
      {!isCheckRemoteUserLoggedIn && isLogged && authRouteComponents}
    </>
  )
}

const App = () => {
  return <AppContainer />
}

export default App
