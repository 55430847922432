import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { GroupTable } from '../../../Games/views/components/GroupTable/GroupTable'
import {
  GameDetailsNavigator,
  GameRoute,
} from '../../../Games/views/GameDetails/GameDetailsNavigator'
import { useSportContext } from '../SportsNavigator'
import { SportTableRoutes } from './routes'

export const SportTables = () => {
  const { championshipId, sportRoute } = useParams()
  console.log('sportRoute', sportRoute)
  const { loadingSport } = useSportContext()
  const sports = useAppSelector((state: RootState) => state.sports)
  const chosenSport = sports.items.find((sport) => sport.route === sportRoute)
  const [activeRoute, setActiveRoute] = useState(SportTableRoutes[0])

  const handleChangeGameRoute = (route: GameRoute) => {
    setActiveRoute(route)
  }

  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      <div style={{ padding: '1rem' }}>
        <h1 className="GameDetailTitle">Próximas 24 Horas</h1>
      </div>
      <GameDetailsNavigator
        routes={SportTableRoutes}
        activeRoute={activeRoute}
        handleChangeGameRoute={handleChangeGameRoute}
      />
      {chosenSport ? (
        <GroupTable
          listOfIds={activeRoute.listOfIds}
          sport={chosenSport}
          tournamentId={championshipId}
          isLoading={loadingSport}
        />
      ) : (
        <div>
          <h2> Esporte não encontrado</h2>
        </div>
      )}
    </div>
  )
}
