import { MainInput } from '../../../../../Global/components/Inputs/MainInput'
import { FormSection } from '../../types'

export const SecurityInfo = ({ errors, register }: FormSection) => {
  return (
    <div>
      <legend className="form-legend">Segurança</legend>
      <p className="form-description">
        Será necessário fornecer seu número de segurança de 4 dígitos ao entrar
        em contato conosco
      </p>
      <MainInput
        type="password"
        name="secretCode"
        register={register}
        placeholder="Número de segurança de 4 dígitos"
        errors={errors}
        className="col-md-12"
      />
      <MainInput
        type="password"
        name="confirmSecretCode"
        register={register}
        placeholder="Confirme número de segurança de 4 dígitos"
        errors={errors}
        className="col-md-12"
      />
      <hr className="solid" />
    </div>
  )
}
