import { Market } from '../../app/bet/Global/views/Games/types/markets'

export const getMarketById = (
  marketId: number | string,
  marketList: Market[],
) => {
  let formatedMarketId = 0
  if (typeof marketId === 'string') formatedMarketId = parseInt(marketId, 10)
  else formatedMarketId = marketId
  const marktFound = marketList.find((market) => market.id === formatedMarketId)
  if (marktFound) return marktFound
  return marketList[1000002]
}
