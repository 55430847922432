import { useEffect, useState } from 'react'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { CreditCardForm } from '../components/Forms/Fund/creditCardForm'
import { FundDefaultForm } from '../components/Forms/Fund/FundDefaultForm'
import { setPaymentMethods } from '../store/payment'
import { PaymentsMethod } from '../types/bank'
import { getFunds } from '../xhr/bank.xhr'

export const BankFund = () => {
  const user = useAppSelector((state: RootState) => state.user)
  const dispatch = useAppDispatch()
  const payment = useAppSelector((state: RootState) => state.payment)

  const [chosenMethod, setChosenMethod] = useState<string>()
  const [currentMethod, setCurrentMethod] = useState<PaymentsMethod>(
    {} as PaymentsMethod,
  )

  const handlePaymentMethods = async (paymentMethods: PaymentsMethod[]) => {
    if (paymentMethods.length) {
      await dispatch(
        setPaymentMethods({
          paymentMethods,
        }),
      )
      // if first run payment method current is undefined, set first row of list
      if (!currentMethod.addFundsFormType) {
        setCurrentMethod(paymentMethods[0])
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      const response = await getFunds(user.token)
      console.log('SALDO')
      console.log(response)
      handlePaymentMethods(response.cnfPaymentMethods)
    })()
  }, [])

  const handleChangeMethod = (value: string) => {
    setChosenMethod(value)
    // alert(value)
    const method = payment.paymentMethods.filter((obj) => {
      return obj.name === value
    })
    setCurrentMethod(method[0])
  }

  const renderForm = (param: string) => {
    switch (param) {
      case 'card':
        return <CreditCardForm currentMethod={currentMethod} />

      case 'default':
        return <FundDefaultForm currentMethod={currentMethod} />

      default:
        return null
    }
  }

  return (
    <>
      <h1 className="config-title d-none d-md-block">Depositar</h1>
      <div className="" style={{ maxWidth: 500 }}>
        <div className="row">
          <div className="col-12" style={{ paddingBottom: '10px' }}>
            <label
              className="form-label"
              style={{ paddingBottom: '5px' }}
              htmlFor="PaymentMethod">
              Método de Pagamento
            </label>
            <select
              className="form-field selectInput"
              value={chosenMethod}
              onChange={(e) => handleChangeMethod(e.target.value)}>
              {payment.paymentMethods.map(({ id, name }, key) => {
                return (
                  <option key={id} value={name}>
                    {name}
                  </option>
                )
              })}
            </select>
          </div>

          {currentMethod.addFundsFormType &&
            renderForm(currentMethod.addFundsFormType)}
        </div>
        {/* <FundForm paymentMethods={payment.paymentMethods} /> */}
      </div>
    </>
  )
}
