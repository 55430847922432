import { Link } from 'react-router-dom'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { GameItem } from '../../types/games'
import '../Games.scss'

type GameInfoProps = {
  gameItem: GameItem
}

export const GameCardItemInfo = ({ gameItem }: GameInfoProps) => {
  const { betGameId, betCnfSportId, betCnfTournamentId, location, gameStart } =
    gameItem.info
  const sports = useAppSelector((state: RootState) => state.sports)
  const sportActual = sports.items.find((item) => item.id === betCnfSportId)
  return (
    <Link
      className="card-live-games-items Link"
      to={`/games/sport/${sportActual?.route}/${betCnfTournamentId}/${betGameId}`}>
      <div className="card-live-games-teams">
        {gameItem.teams.map((team) => {
          return <p key={team.id}>{team.name}</p>
        })}
        <div className="card-live-games-teams-header">
          {/* <p>{betGameId}</p> */}
          <p>{`${location} ${location ? '-' : ''} ${gameStart}`}</p>
        </div>
      </div>
    </Link>
  )
}
