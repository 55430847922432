import axios from 'axios'
import apiHelper from '../../../../../../auth/services/apiHelper'
import { ForgotFormData } from '../FormForgot'

export const onPostFormForgotRequest = async (data: ForgotFormData) => {
  const response = axios
    .request({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('forgot'),
      data,
    })
    .then((r) => {
      return r
    })
    .catch((error) => {
      return error
    })
  return response
}
