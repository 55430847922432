import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { SideOffers } from '../../../Offers/SideOffers'
import { setGameList } from '../../store/gameList'
import { GameItem } from '../../types/games'
import './GameDetails.scss'
import { GameDetailsNavigator, GameRoute } from './GameDetailsNavigator'
import { GameDetailRoutes } from './GameDetailsRoutes'
import { GameDropdown } from './GameDropdown'
import { DefaultOddTable } from './tables/defaultTable'
import { ScoreTable } from './tables/Score/scoreTable'
import { SecondaryOddTable } from './tables/secondaryTable'
import { GameDetailsOdds, MarketsList } from './types'
import { getGameDetails } from './xhr'

type GameDetailsDropdownHeaderProps = {
  title: string
}

export const GameDetailsDropdownHeader = ({
  title,
}: GameDetailsDropdownHeaderProps) => {
  return <div style={{ paddingLeft: '0.5rem' }}>{title}</div>
}

const GameDetails = () => {
  const { t } = useTranslation()
  const [activeRoute, setActiveRoute] = useState<GameRoute>(GameDetailRoutes[0])
  const handleChangeGameRoute = (route: GameRoute) => {
    setActiveRoute(route)
  }
  const [isLoading, setIsLoading] = useState(true)
  const { gameId, sportRoute } = useParams()
  const gameList = useAppSelector((state: RootState) => state.gameList)
  const markets = useAppSelector((state: RootState) => state.markets)
  const wagers = useAppSelector((state: RootState) => state.wagers)

  const dispatch = useAppDispatch()
  // const [game, setGame] = useState<GameItem>()
  const [gameDetails, setGameDetails] = useState<GameItem>({} as GameItem)
  const [OddsMarketList, setOddsMarketList] = useState({} as MarketsList)

  // console.log('gameDetails.info', gameDetails.info)

  const sports = useAppSelector((state: RootState) => state.sports)
  const sportActual = sports.items.find((item) => item.route === sportRoute)

  const separeteOdds = (Odds: GameDetailsOdds[]) => {
    const objMarketAux = {} as MarketsList
    Odds.forEach((odd) => {
      if (objMarketAux[odd.market_id]) {
        objMarketAux[odd.market_id] = [...objMarketAux[odd.market_id], odd]
      } else objMarketAux[odd.market_id] = [odd]
    })
    console.log('separeteOdds', objMarketAux)
    setOddsMarketList(objMarketAux)
  }
  // console.log('Market', markets)

  useEffect(() => {
    const getGameFromList = async () => {
      if (gameId) {
        const currentItem = gameList.items[gameId]
        // .find(
        //   (item) => item.info.betGameId === parseInt(gameId, 10),
        // )
        // console.log('Jogo achado', currentItem)
        if (currentItem) {
          setGameDetails(currentItem)
          await separeteOdds(currentItem.odds)
        } else {
          console.log('Jogo nao encontrado')
          // await addNewGame()
        }
      }
    }
    getGameFromList()
    console.log('OddsMarketList', OddsMarketList)
    // console.log('Game List', gameList)
  }, [gameList])

  const defaultMarkets: number[] = [1000002, 1000006]
  const secondaryMarkets: number[] = [1000003, 1000008]
  const chooseTable = (odds: GameDetailsOdds[], marketId: number) => {
    // console.log(odds)
    // odds.forEach((item) => {
    //   console.log(item)
    // })
    if (marketId === 1000016) {
      return <ScoreTable odds={odds} gameDetails={gameDetails} />
    }
    // if (defaultMarkets.includes(marketId)) {
    // if (odds[0].oddHeader) {

    //   return <DefaultOddTable odds={odds} gameDetails={gameDetails} />
    // }
    if (odds[0].oddHeader) {
      return <SecondaryOddTable odds={odds} gameDetails={gameDetails} />
    }
    return <DefaultOddTable odds={odds} gameDetails={gameDetails} />
  }

  useEffect(() => {
    const getData = async () => {
      // console.log('ENTROU EM GET DATA')
      try {
        if (gameId) {
          const response: GameItem = await getGameDetails(gameId)
          // APAGAR QUANDO ESTIVER DINAMICO
          response.info.betGameId = parseInt(gameId, 10)
          response.odds = response.odds.filter((odd) => {
            odd.betGameId = parseInt(gameId, 10)
            return odd
          })
          // APAGAR QUANDO ESTIVER DINAMICO
          await separeteOdds(response.odds)
          setGameDetails(response)
          dispatch(
            setGameList({
              items: [response],
              oddsChecked: wagers.odds,
            }),
          )
          // console.log('DADOS DO JOGO', response)
          setIsLoading(false)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getData()
  }, [])

  // console.log('gameList', gameList)

  return (
    <div className="hideScrolls" style={{ height: '100%', overflowY: 'auto' }}>
      <Helmet>
        <title>BETKING - Detalhes</title>
      </Helmet>
      {!isLoading ? (
        <div className="g-0 col-12" style={{ height: '100%' }}>
          <div
            className="row g-0"
            style={{ height: '100%', position: 'relative' }}>
            <div className="gameDetailsMainContainer col-lg-9 col-md-12">
              <div className="col-12" style={{ padding: '0.8rem 1rem' }}>
                {sportActual && (
                  <div className="SportsNavigatorRoute">
                    <Link
                      className="Link"
                      to={`/games/sport/${sportActual?.route}`}>
                      {sportActual?.name}
                    </Link>
                    -
                    <Link
                      className="Link"
                      to={`/games/sport/${sportActual?.route}/${gameDetails.info.betCnfTournamentId}`}>
                      {gameDetails.info.betCnfTournamentName}
                    </Link>
                  </div>
                )}
                <h1 className="GameDetailTitle">{`${gameDetails.teams[0].name} X ${gameDetails.teams[1].name}`}</h1>
              </div>
              <div
                className="col-12 myBets_offersContainer"
                style={{ height: '100%', paddingBottom: '3rem' }}>
                {/* <div className="d-block d-lg-none col-lg-4 col-md-12">
                  <SideOffers />
                </div> */}
                <div className="col-md-12 MyBetsOddsContainer">
                  <GameDetailsNavigator
                    routes={GameDetailRoutes}
                    activeRoute={activeRoute}
                    handleChangeGameRoute={handleChangeGameRoute}
                  />
                  {Object.entries(OddsMarketList).map((Market) => {
                    const currentMarket = markets.items.find(
                      (market) =>
                        (activeRoute.listOfIds.includes(market.id) ||
                          activeRoute.route === 'all') &&
                        market.id === parseInt(Market[0], 10),
                    )
                    if (currentMarket) {
                      return (
                        <GameDropdown
                          key={Market[0]}
                          header={
                            <GameDetailsDropdownHeader
                              title={t(currentMarket.name)}
                            />
                          }
                          body={chooseTable(Market[1], parseInt(Market[0], 10))}
                        />
                      )
                    }
                    return null
                  })}
                </div>
              </div>
              {/* <div className="d-block d-md-none col-12 myBets_offersContainer">
                <SideOffers />
              </div> */}
            </div>
            <div className="col-lg-3 col-md-12 myBets_offersContainer">
              <SideOffers />
            </div>
          </div>
        </div>
      ) : (
        <h2>Carregando</h2>
      )}
    </div>
  )
}

export default GameDetails
