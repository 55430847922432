import axios from 'axios'
import apiHelper from '../../../../../auth/services/apiHelper'

export const getCoutriesRemote = async () => {
  const response = axios
    .request({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('misc/getCountries'),
    })
    .then((r) => {
      return r
    })
    .catch((error) => {
      return error
    })
  return response
}
