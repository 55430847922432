import { getOddsByMarket } from '../../../../../../../common/functions/odds'
import { GameItem } from '../../types/games'
import { GameCardItemInfo } from './GameCardItemInfo'
import { GameCardOdds } from './GameCardOdds'

type GameTableRowProps = { gameItem: GameItem; chosenMarketId: number }

export const GameTableRow = ({
  gameItem,
  chosenMarketId,
}: GameTableRowProps) => {
  return (
    <div key={gameItem.info.betGameId} className="card-live-games-content">
      <GameCardItemInfo gameItem={gameItem} />
      <GameCardOdds odds={getOddsByMarket(chosenMarketId, gameItem.odds)} />
    </div>
  )
}
