import { RadioInput } from '../../../../../Global/components/Inputs/RadioInput'
import { FormSection } from '../../types'

export const OffersInfo = ({ errors, register }: FormSection) => {
  const sendPromotions = [
    { label: 'Me envie ofertas', value: true },
    { label: 'Não obrigado(a)', value: false },
  ]
  return (
    <div>
      <legend className="form-legend">
        Receba informações sobre promoções e ofertas
      </legend>
      <p className="form-description">
        Nós utilizaremos os dados de contato que nos forneceu
      </p>
      <RadioInput
        RadioArray={sendPromotions}
        type="text"
        name="sendPromotions"
        register={register}
        errors={errors}
        className="row"
      />
      <hr className="solid" />
    </div>
  )
}
