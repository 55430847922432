import { useEffect, useState } from 'react'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { setCloseGlobalLoading } from '../../../../../../core/store/items/app'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import { setGameList } from '../../Games/store/gameList'
import { sportsProps } from '../types/sport'
import getSportConf from '../xhr/getSport.xhr'

type ContextType = { loadingSport: boolean }

export const SporstNavigator = () => {
  const dispatch = useAppDispatch()

  const { sportRoute } = useParams<sportsProps>()
  const user = useAppSelector((state: RootState) => state.user)
  const sports = useAppSelector((state: RootState) => state.sports)
  const gameList = useAppSelector((state: RootState) => state.gameList)
  const [loadingSport, setLoadingSport] = useState(true)
  const wagers = useAppSelector((state: RootState) => state.wagers)
  const sportActual = sports.items.find((item) => item.route === sportRoute)

  // const loadSport = async () => {
  //   await setLoadingSport(true)
  //   // if (gameList.items.length) {
  //   //   await dispatch(resetGameList())
  //   // }

  //   const resultSportItem = await getSportConf(user.token, sportRoute)
  //   console.log('Sport Item', resultSportItem)
  //   // await setIsLoaded(true)
  //   if (resultSportItem.success) {
  //     if (sportActual) {
  //       await console.log(`TOTAL JOGOS: ${resultSportItem.data.games.length}`)
  //       await dispatch(
  //         setGameList({
  //           items: resultSportItem.data.games,
  //         }),
  //       )
  //       // await setIsLoaded(true)
  //     }
  //   } else {
  //     // erro ao carregar.
  //     // al ert();
  //   }
  //   await setLoadingSport(false)
  //   await dispatch(setCloseGlobalLoading())
  // }

  // useEffect(() => {
  //   loadSport()
  //   console.log('sportRoute', sportRoute)
  // }, [sportRoute])
  const loadSport = async () => {
    // console.error('SPORT NAVIGATOR')
    setLoadingSport(true)

    const resultSportItem = await getSportConf(user.token, sportRoute)
    console.log('Sport Item', resultSportItem)
    if (resultSportItem.success) {
      if (sportActual) {
        await console.log(`TOTAL JOGOS: ${resultSportItem.data.games.length}`)

        await dispatch(
          setGameList({
            items: resultSportItem.data.games,
            oddsChecked: wagers.odds,
          }),
        )
        setLoadingSport(false)
      }
    } else {
      // erro ao carregar.
      // al ert();
    }
    await dispatch(setCloseGlobalLoading())
  }
  useEffect(() => {
    loadSport()
    // console.log('gameList', gameList)
    // console.log('sportRoute', sportRoute)
  }, [sportRoute])

  return <Outlet context={loadingSport} />
}

export function useSportContext() {
  return useOutletContext<ContextType>()
}
