/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { GrClose } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '../../../../../../../../core/store/Reducers'
import { setNotEnoughCash } from '../../../store/wagers'
import './style.scss'

export const NotEnoughCash = () => {
  const dispatch = useAppDispatch()
  const handleCloseWarn = () => {
    dispatch(setNotEnoughCash(false))
  }
  const fudsRoute = '/bank/funds'
  return (
    <div className="NotEnoughCashWager">
      <b>Você não possui saldo o suficiente</b>
      <p>Faça um depósito para realizar esta aposta</p>
      <Link
        className="main-button Link"
        to={fudsRoute}
        onClick={() => handleCloseWarn()}>
        Depositar Agora
      </Link>
      <p className="CancelLink" onClick={() => handleCloseWarn()}>
        Cancelar
      </p>
      <GrClose
        onClick={() => handleCloseWarn()}
        className="CloseWarnNotEnoughCash"
      />
    </div>
  )
}
