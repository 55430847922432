import './Loading.css'

export const Loading = () => {
  return (
    <div className="preload">
      <div className="preload-item">
        <img
          alt="Carregando..."
          style={{ maxWidth: 400 }}
          src={`${process.env.PUBLIC_URL}/img/loading.gif`}
        />
      </div>
    </div>
  )
}
