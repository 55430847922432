import Skeleton from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'

// interface Props {
//   loading: boolean
// }

export const BetCardSkeleton = () => {
  return (
    <div style={{ padding: '0.4rem 0.5rem' }}>
      <div className="row g-2">
        <Skeleton height={35} />
        <Skeleton height={60} />
        <div className="col-4">
          <Skeleton height={35} />
        </div>
        <div className="col-4">
          <Skeleton height={35} />
        </div>
        <div className="col-4">
          <Skeleton height={35} />
        </div>
      </div>
    </div>
  )
}
