/* eslint-disable no-param-reassign */
/* eslint-disable no-cond-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { DefineMultipleLabel } from '../../../../../../../../../common/functions/formatting'
import {
  calcRateCombination,
  getOddsCombinations,
} from '../../../../../../../../../common/functions/odds'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../core/store/Reducers'
import { onChangeAllSimples } from '../../../../../Games/store/gameList'
import { removeErrorMarkOnInputOddPrice } from '../../../../helpers/oddsCheckeds'
import { setMultiple } from '../../../../store/wagers'
import { Multiple } from '../../../../types/wagers'
import { sanitizeKeyboardInnerText } from '../../KeyBoard/helpers/keyboard'
import { KeyBoard } from '../../KeyBoard/KeyBoard'

interface Props {
  index: number
  mainCard?: boolean
  submited: boolean
  collapseMultiples?: any
  collapsedMultiples?: boolean
  refWagerModal: React.MutableRefObject<HTMLDivElement | undefined>
}
export const MultipleCard = ({
  index,
  mainCard,
  submited,
  collapseMultiples,
  collapsedMultiples,
  refWagerModal,
}: Props) => {
  const wagers = useAppSelector((state: RootState) => state.wagers)
  const dispatch = useAppDispatch()
  const inputMaxLength = ({ value }: NumberFormatValues) => value.length <= 10
  const [isLoaded, setIsLoaded] = useState(false)
  const refOddPrice = useRef<HTMLInputElement | null>(null)

  const [fixedDecimal, setFixedDecimal] = useState(false)

  const [label, setLabel] = useState('Simples')

  useEffect(() => {
    setLabel(DefineMultipleLabel(index))
  }, [])

  const handleSetMultiple = async (price: number | undefined) => {
    const allCombinations = getOddsCombinations(wagers.odds, index)
    const multiple = [] as Multiple
    allCombinations.forEach((combination) => {
      multiple.push({
        combination,
        type: index,
        price,
        rate: calcRateCombination(wagers.odds, combination),
      })
    })

    await dispatch(setMultiple({ multiple, index }))
  }
  const handleChangeAllSimples = async (price: number | undefined) => {
    await dispatch(onChangeAllSimples({ price }))
  }
  const collapseLabel = collapsedMultiples
    ? 'Mostrar mais múltiplas'
    : 'Mostrar menos múltiplas'
  // console.log('item', item)

  const handleInputFocus = () => {
    setFixedDecimal(false)
    const el = document.getElementById(
      `multiple-input${index}`,
    ) as HTMLInputElement
    if (el) {
      el.select() // select the input textfield and set the focus on it
    }
  }

  useEffect(() => {
    function findAncestor(el: HTMLElement | null, cls: string) {
      if (el) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el
      }
      return null
    }

    function handlerMobileTinyKeyboard(e: { target: any }) {
      // linha do jogo da odd
      const elParentOddPriceRow = findAncestor(e.target, 'oddPriceRow')

      // console.log(e.target)
      // console.log(elParentOddPriceRow)

      if (elParentOddPriceRow) {
        // INICIO esconde todos teclados existentes...
        if (refWagerModal.current) {
          const allLeyboardEl =
            refWagerModal.current.querySelectorAll<HTMLDivElement>(
              'div[class=keyboard_tiny]',
            )
          // console.log(allLeyboardEl)
          allLeyboardEl.forEach((keyboardItem) => {
            keyboardItem.style.display = 'none'
          })
        }
        // FINAL - esconde todos teclados existentes...

        // INICIO - TECLADO DA LINHA EM QUESTÃO - ATUAL
        const keyboardElActual =
          elParentOddPriceRow.querySelector<HTMLDivElement>(
            'div[class=keyboard_tiny]',
          )

        if (keyboardElActual) {
          // mostre o teclado...
          keyboardElActual.style.display = 'block'

          // todos os botoões do teclado atual
          const allButtonActualKeyboard =
            keyboardElActual.querySelectorAll<HTMLButtonElement>('button')

          // valor final da ODD digitada em cada botão é armazenada neste campo
          let priceUserInput = ''

          const decimalSize = 2
          const decimalSeparator = ','
          // intera todos botões e binda o clique deles
          allButtonActualKeyboard.forEach((btnKeyboard) => {
            btnKeyboard.addEventListener('click', function () {
              const innerText = btnKeyboard.dataset.value ?? ''

              if (innerText === 'end') {
                keyboardElActual.style.display = 'none'
                if (!e.target.value) return

                e.target.value = parseFloat(e.target.value.replace(',', '.'))
                  .toFixed(2)
                  .replace('.', ',')
                // console.error(typeof e.target.value)
                return
              }
              priceUserInput = sanitizeKeyboardInnerText(
                priceUserInput,
                innerText,
                decimalSeparator,
                decimalSize,
              )

              e.target.value = priceUserInput
              // atualiza o estado no redux com o novo valor
              if (index === 1)
                handleChangeAllSimples(
                  parseFloat(priceUserInput.replace(',', '.')),
                )
              else
                handleSetMultiple(parseFloat(priceUserInput.replace(',', '.')))
            })
          })
        }
      }
    }
    if (isLoaded && refOddPrice && refOddPrice.current) {
      refOddPrice.current.addEventListener('click', handlerMobileTinyKeyboard)
    }
    return () => {
      if (isLoaded && refOddPrice && refOddPrice.current) {
        refOddPrice.current.removeEventListener(
          'click',
          handlerMobileTinyKeyboard,
        )
      }
    }
    //   // if (index === 0) setFixedDecimal(true)
  }, [isLoaded])

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <div className="row g-0 oddPriceRow" style={{ backgroundColor: '#d6d6d6' }}>
      <div
        className="col-8"
        style={{ maxWidth: '100%', paddingLeft: '10px', paddingTop: '10px' }}>
        <h5 className="multipleLabel">{label}</h5>
        {mainCard && (
          <div
            className="toggleMultiples"
            onClick={() => collapseMultiples(!collapsedMultiples)}>
            {collapseLabel}
          </div>
        )}
      </div>
      {/* <Col sm={1}>
        <h3>{item.odd.rate.toFixed(2)}</h3>
      </Col> */}
      <div className="col-4">
        <div className="row g-0">
          <div className="col-12">
            {isMobile ? (
              <input
                ref={refOddPrice}
                readOnly={isMobile}
                className="oddPriceDefault oddPrice oddPriceMobile"
                placeholder="Valor da aposta"
                defaultValue=""
              />
            ) : (
              <NumberFormat
                id={`multiple-input${index}`}
                style={{ width: '100%' }}
                className="oddPrice oddPriceDefault"
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale={fixedDecimal}
                onBlur={() => setFixedDecimal(true)}
                onFocus={() => handleInputFocus()}
                decimalScale={2}
                isAllowed={inputMaxLength}
                onValueChange={(v) => {
                  removeErrorMarkOnInputOddPrice({ refWagerModal })
                  if (index === 1) handleChangeAllSimples(v.floatValue)
                  else handleSetMultiple(v.floatValue)
                }}
                placeholder="Valor da aposta"
              />
            )}
          </div>
          {/* {item.odd.prize > 0 && (
          <Col sm={12} className="wager_info_label">
            Retornos potenciais
          </Col>
        )}
        {item.odd.prize > 0 && (
          <Col sm={12} className="wager_info_label">
            {`R$ ${item.odd.prize.toFixed(2)}`}
          </Col>
        )} */}
        </div>
      </div>
      {isMobile && !submited && (
        <div className="keyboard_tiny">
          <KeyBoard />
        </div>
      )}
    </div>
  )
}

MultipleCard.defaultProps = {
  mainCard: false,
  collapseMultiples: null,
  collapsedMultiples: true,
}
