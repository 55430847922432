import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Notify } from '../../../../../../../../common/components/toats/notify'
import { SubmitButton } from '../../../../../../Global/components/buttons/SubmitButton'
import { RegisterFormData } from '../../../types'
import { SignUser } from '../../../xhr/SignUp.xhr'
import { AcceptTerms } from '../../FormSections/AcceptTerms'
import { AddressInfo } from '../../FormSections/AddressInfo'
import { AgentInfo } from '../../FormSections/AgentInfo'
import { ContactInfo } from '../../FormSections/ContactInfo'
import { CountryInfo } from '../../FormSections/CountryInfo'
import { LoginInfo } from '../../FormSections/LoginInfo'
import { OffersInfo } from '../../FormSections/OffersInfo'
import { PersonalInfo } from '../../FormSections/PersonalInfo'
import { SecurityInfo } from '../../FormSections/SecurityInfo'
import { SuccessfulSubmit } from '../../Submit/SuccessfulSubmit'
import { registerUserSchema } from './yup'

export const FormSignLarge = () => {
  const { t } = useTranslation()
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [submited, setSubmited] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (dataUser: RegisterFormData) => {
    setLoading(true)
    setDisableSubmit(true)
    console.log(dataUser)
    if (dataUser) {
      const signResponse = await SignUser(dataUser)

      if (signResponse.success) {
        setSubmited(true)

        // Toast
        // Notify({ type: 'success', message: signResponse.msg })
      } else {
        setDisableSubmit(false)

        // Toast
        Notify({ type: 'error', message: signResponse.msg })
      }
    }
    setLoading(false)
  }

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors },
    handleSubmit,
  } = useForm<RegisterFormData>({
    resolver: yupResolver(registerUserSchema),
    defaultValues: {
      country: 33,
      pronoun: 'Sr.',
    },
  })
  return (
    <div
      style={{ backgroundColor: '#ebeded', overflowY: 'auto', height: '100%' }}>
      <div className="container signUpContainer">
        <div className="row signUpRow">
          {submited ? (
            <SuccessfulSubmit />
          ) : (
            <form
              style={{ width: '100%' }}
              onSubmit={handleSubmit(onSubmit)}
              noValidate>
              <h1 className="form-title">Abrir conta</h1>
              <CountryInfo register={register} errors={errors} />
              <PersonalInfo register={register} errors={errors} />
              <ContactInfo register={register} errors={errors} />
              <AddressInfo register={register} errors={errors} />
              <LoginInfo register={register} errors={errors} />
              <SecurityInfo register={register} errors={errors} />
              <OffersInfo register={register} errors={errors} />
              <AgentInfo
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
                setDisableSubmit={setDisableSubmit}
              />
              <AcceptTerms
                register={register}
                errors={errors}
                value={watch('acceptTerms')}
                setValue={setValue}
                trigger={trigger}
              />
              <SubmitButton
                disableSubmit={disableSubmit}
                text={t('Register')}
                loading={loading}
              />
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
