/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { BsCheckCircleFill } from 'react-icons/bs'

interface Props {
  refCode: string
  handleCloseWager: () => void
}

export const SubmitHeaderInfo = ({ refCode, handleCloseWager }: Props) => {
  return (
    <div
      className="row g-0"
      style={{
        color: 'white',
        backgroundColor: 'orange',
        padding: '0.3rem 0',
      }}>
      <div className="col-1">
        <BsCheckCircleFill
          style={{
            fontSize: '2.4rem',
            paddingLeft: '0.5rem',
            color: '#ffffff',
          }}
          className="pointer"
        />
      </div>
      <div className="col-8" style={{ paddingLeft: '0.5rem' }}>
        <h2
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
          }}>
          Aposta Feita
        </h2>
        <p style={{ fontSize: '0.7rem' }}>Ref: {refCode}</p>
      </div>
      <div className="col" style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ cursor: 'pointer' }} onClick={() => handleCloseWager()}>
          Terminar
        </p>
      </div>
    </div>
  )
}
