interface Props {
  totalSpent: number | undefined
  potencialIncomes: number | undefined
}

interface ItemProps {
  title: string
  value: number | undefined
}

const FooterSubmitedItem = ({ title, value }: ItemProps) => {
  return (
    <div className="col" style={{ paddingLeft: '2rem' }}>
      <div>{title}</div>
      <div>
        {value
          ? value.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
          : ''}
      </div>
    </div>
  )
}
export const SubmitedFooterInfo = ({ totalSpent, potencialIncomes }: Props) => {
  return (
    <div className="row">
      <FooterSubmitedItem title="Valor de Aposta" value={totalSpent} />
      <FooterSubmitedItem
        title="Retornos Potenciais"
        value={potencialIncomes}
      />
    </div>
  )
}
