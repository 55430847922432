import { Link } from 'react-router-dom'
import { setModalLogin } from '../../../../../../../core/store/items/app'
import { useAppDispatch } from '../../../../../../../core/store/Reducers'
import './style.scss'

export const SuccessfulSubmit = () => {
  const dispatch = useAppDispatch()
  const handleClickLogin = () => {
    dispatch(setModalLogin(true))
  }
  return (
    <div className="SuccessfulSubmitContainer">
      <h2>Usuário cadastrado com sucesso! </h2>
      <p>Te enviamos um e-mail de confirmação!</p>
      <p>
        É necessário confirmar o seu e-mail para ter acesso a algumas
        funcionalidades
      </p>
      <br />
      <button
        type="button"
        className="main-button"
        onClick={() => handleClickLogin()}
        style={{ marginBottom: '10px', backgroundColor: 'green' }}>
        Fazer Login
      </button>
      <Link className="Link" to="/">
        <button type="button" className="main-button">
          Voltar para a Página Inicial
        </button>
      </Link>
      <b className="resend"> Reenviar e-mail de confirmação </b>
    </div>
  )
}
