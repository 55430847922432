/* eslint-disable prefer-template */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GameItem, GameItemIndexable } from '../types/games'
import { oddChecked, Odds } from '../types/odds'

type VerifyChecked = { [key: string]: number[] }

const initialState = {
  // sport: initialSport,
  items: {} as GameItemIndexable,
}

export const gameListReducer = createSlice({
  name: 'gameslist',
  initialState,
  reducers: {
    resetGameList: () => initialState,
    setGameList: (
      state,
      action: PayloadAction<{ items: GameItem[]; oddsChecked: oddChecked[] }>,
    ) => {
      const { oddsChecked, items } = action.payload
      // console.log('oddsChecked', oddsChecked)
      // console.log('newItems', items)
      // console.log('newItems Length', items.length)
      // console.log('State Size', Object.entries(state.items).length)

      const verifyChecked = {} as VerifyChecked
      oddsChecked.forEach((odd) => {
        if (!verifyChecked[odd.info.betGameId])
          verifyChecked[odd.info.betGameId] = []
        verifyChecked[odd.info.betGameId].push(odd.odd.betGamesOddId)
      })

      // console.log('Verify Checked', verifyChecked)
      // let count = 0
      const StateItemsCopy = { ...state.items }
      items.forEach((newGame) => {
        // let game = StateItemsCopy[newGame.info.betGameId]
        if (StateItemsCopy[newGame.info.betGameId.toString()]) {
          // console.log('JOGO EXISTE')
          // const oldOdds = [] as Odds[]
          // StateItemsCopy[newGame.info.betGameId].odds.forEach((odd) =>
          //   oldOdds.push({ ...odd }),
          // )
          StateItemsCopy[newGame.info.betGameId.toString()] = newGame
          // game.odds = newGame.odds
          if (verifyChecked[newGame.info.betGameId]) {
            StateItemsCopy[newGame.info.betGameId].odds.forEach((odd) => {
              if (
                verifyChecked[newGame.info.betGameId].includes(
                  odd.betGamesOddId,
                )
              ) {
                // console.log('Odd Id', odd.betGameId, 'new odd', odd.checked)
                odd.checked = true
              }
            })
          }
        } else {
          // console.log('JOGO NAO EXISTE')
          // console.log('game', StateItemsCopy[newGame.info.betGameId])
          // console.log('newGame', newGame)
          // count += 1
          // if (count === 8) {
          //   StateItemsCopy['a' + newGame.info.betGameId.toString()] = {
          //     ...newGame,
          //   }
          // } else
          StateItemsCopy[newGame.info.betGameId.toString()] = {
            ...newGame,
          }
        }
      })
      // console.log('StateItemsCopy', StateItemsCopy)
      state.items = StateItemsCopy

      // state.items = action.payload.items
    },
    addNewGame: (state, action: PayloadAction<{ newGame: GameItem }>) => {
      const { newGame } = action.payload
      // console.log('State Before', state.items)
      // console.log('New Game', newGame)

      let game = state.items[newGame.info.betGameId]
      if (game) {
        const oldOdds = [...game.odds]
        game = newGame
        game.odds = newGame.odds
        game.odds.forEach((odd) => {
          oldOdds.forEach((oldOdd) => {
            if (odd.betCnfOddId === oldOdd.betCnfOddId) {
              odd.checked = oldOdd.checked
            }
          })
        })
      } else game = newGame

      // let gameExists = false
      // state.items.forEach((item) => {
      //   if (item.info.betGameId === newGame.info.betGameId) {
      //     gameExists = true

      //     const oldOdds = [...item.odds] // Save to keep checked values from old item

      //     item.odds = newGame.odds
      //     item.odds.forEach((odd) => {
      //       oldOdds.forEach((oldOdd) => {
      //         if (odd.betCnfOddId === oldOdd.betCnfOddId) {
      //           odd.checked = oldOdd.checked
      //         }
      //       })
      //     })
      //   }
      // })

      // if (!gameExists) state.items.push(newGame)

      // console.log('State After', state.items)
    },

    /**
     * Quando o valor do preço da ODD muda
     * @param state
     * @param action
     */
    onChangeOddPrice: (
      state,
      action: PayloadAction<{ odd: Odds; price: number | undefined }>,
    ) => {
      const { betGameId, betGamesOddId } = action.payload.odd
      // const itemIndex = state.items.findIndex(
      //   (i) => i.info.betGameId === betGameId,
      // )
      const { odds } = state.items[betGameId]
      const oddsIndex = odds.findIndex((i) => i.betGamesOddId === betGamesOddId)

      const { odd, price } = action.payload
      console.log('price', price)
      // console.log('odd.rate', odd.rate)
      const prize = price ? odd.rate * price : undefined
      // const auxItem = { ...state.items[itemIndex].odds[oddsIndex] }
      // if (price) auxItem.price = price
      // auxItem.prize = prize
      // state.items[itemIndex].odds[oddsIndex] = auxItem
      state.items[betGameId].odds[oddsIndex].price = price
      state.items[betGameId].odds[oddsIndex].prize = prize
    },

    onChangeAllSimples: (
      state,
      action: PayloadAction<{ price: number | undefined }>,
    ) => {
      const { price } = action.payload
      Object.entries(state.items).map((item) =>
        item[1].odds.forEach((odd) => {
          if (odd.checked) {
            odd.price = price
          }
        }),
      )
    },

    onDeleteWager: (
      state,
      action: PayloadAction<{ oddsList: oddChecked[] }>,
    ) => {
      const { oddsList } = action.payload
      console.log('TRYING TO DELETE', oddsList)
      // console.log('oddsList', oddsList)
      oddsList.forEach((item) => {
        const { betGameId, betGamesOddId } = item.odd
        // const itemIndex = state.items.findIndex(
        //   (i) => i.info.betGameId === betGameId,
        // )
        const { odds } = state.items[betGameId]
        const oddsIndex = odds.findIndex(
          (i) => i.betGamesOddId === betGamesOddId,
        )
        state.items[betGameId].odds[oddsIndex].checked = false
        delete state.items[betGameId].odds[oddsIndex].price
        delete state.items[betGameId].odds[oddsIndex].prize
      })
    },

    onRemountWagers: (
      state,
      action: PayloadAction<{ oddsList: oddChecked[] }>,
    ) => {
      const { oddsList } = action.payload
      // console.log('oddsList', oddsList)
      // oddsList.forEach((item) => {
      //   const { betGameId, betGamesOddId } = item.odd
      //   const itemIndex = state.items.findIndex(
      //     (i) => i.info.betGameId === betGameId,
      //   )
      //   console.log('state.items[itemIndex]', state.items[itemIndex])
      //   const { odds } = state.items[itemIndex]
      //   const oddsIndex = odds.findIndex(
      //     (i) => i.betGamesOddId === betGamesOddId,
      //   )
      //   state.items[itemIndex].odds[oddsIndex] = item.odd
      // })
    },

    changeOddSelection: (state, action: PayloadAction<Odds>) => {
      console.log('State', state.items)
      console.log('ODD', action.payload)
      const { betGameId, betGamesOddId } = action.payload
      // const itemIndex = state.items.findIndex(
      //   (i) => i.info.betGameId === betGameId,
      // )
      const { odds } = state.items[betGameId]
      const oddsIndex = odds.findIndex((i) => i.betGamesOddId === betGamesOddId)
      // altera o odd atual.
      const currentItem = state.items[betGameId].odds[oddsIndex]
      currentItem.checked = !currentItem.checked
      currentItem.price = undefined
      currentItem.prize = undefined
    },
  },
})

export const {
  setGameList,
  resetGameList,
  onChangeAllSimples,
  changeOddSelection,
  onDeleteWager,
  onRemountWagers,
  onChangeOddPrice,
  addNewGame,
} = gameListReducer.actions

export default gameListReducer.reducer
