import { useState } from 'react'
import { PendingTransfer } from '../components/PendingTransfer'

export const BankTransfers = () => {
  const [isPendingTransfer, setIsPendingTransfer] = useState<boolean>(true)

  return (
    <>
      <h1 className="config-title d-none d-md-block">Transferências</h1>
      {isPendingTransfer ? <PendingTransfer /> : null}
    </>
  )
}
