import { useEffect } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { setMenuLeft, setModalLogin } from '../../../../../core/store/items/app'
import { useAppDispatch } from '../../../../../core/store/Reducers'
import './style.scss'

export const VerifyAccount = () => {
  const dispatch = useAppDispatch()
  const buttonLabel = 'ACESSAR SUA CONTA'
  const successStatus = true
  const iconSize = 100

  const chooseIcon = (success: boolean) => {
    if (success) return <AiFillCheckCircle color="green" size={iconSize} />
    return <AiFillCheckCircle />
  }
  const params = new URL(document.location.toString()).searchParams
  // console.log(params.get('agent'))

  const agentCode = params.get('agent') ?? ''

  useEffect(() => {
    dispatch(setMenuLeft(false))
  }, [])
  return (
    <div className="verifyAccountContainer">
      <div>{chooseIcon(successStatus)}</div>
      <div>Status</div>
      <div>Mensagem de erro</div>
      <button
        type="button"
        className="main-button buttonContainer successButton"
        onClick={() => dispatch(setModalLogin(true))}>
        {buttonLabel}
      </button>
    </div>
  )
}
