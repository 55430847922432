import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  RootState,
  useAppSelector,
} from '../../../../../../core/store/Reducers'

type ActiveRouteCenterMenu = 'sports' | 'live' | 'mybets'

const routes = {
  sports: '/',
  live: '/live',
  mybets: '/mybets',
}

export const CenterMenu = () => {
  const user = useAppSelector((state: RootState) => state.user)
  const [activeRoute, setActiveRoute] =
    useState<ActiveRouteCenterMenu>('sports')

  const switchActiveRoute = (pathname: string) => {
    switch (pathname) {
      case '/mybets':
        setActiveRoute('mybets')
        break
      case '/':
        setActiveRoute('sports')
        break
      case '/live':
        setActiveRoute('live')
        break
      default:
        setActiveRoute('sports')
        break
    }
  }

  useEffect(() => {
    const url = new URL(document.location.toString())
    switchActiveRoute(url.pathname)
  }, [])

  return (
    <div id="centerMenu">
      <Link
        className={`d-none d-md-block Link header-title ${
          activeRoute === 'sports' ? 'active-title' : ''
        }`}
        to={routes.sports}
        onClick={() => {
          setActiveRoute('sports')
        }}>
        Esportes
      </Link>
      {/* <Link
        className={`Link header-title ${
          activeRoute === 'live' ? 'active-title' : ''
        }`}
        to={routes.live}
        onClick={() => {
          setActiveRoute('live')
        }}>
        Ao vivo
      </Link> */}

      {user.isLogged ? (
        <Link
          className={`Link header-title ${
            activeRoute === 'mybets' ? 'active-title' : ''
          }`}
          to={routes.mybets}
          onClick={() => {
            setActiveRoute('mybets')
          }}>
          Minhas apostas
        </Link>
      ) : (
        <Link
          to="/signup"
          className="d-md-none header-title t-btn t-btn-transparent">
          Registre-se
        </Link>
      )}
      <Link
        to="/"
        className={`d-block d-md-none Link header-title ${
          activeRoute === 'sports' ? 'active-title' : ''
        }`}
        onClick={() => {
          setActiveRoute('sports')
        }}>
        <img
          alt="sports"
          className="logo "
          src={`${process.env.PUBLIC_URL}/img/logo.png`}
        />
      </Link>
    </div>
  )
}
