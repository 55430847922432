/* eslint-disable jsx-a11y/interactive-supports-focus */

export type GameRoute = {
  label: string
  route: string
  listOfIds: number[]
}

interface Props {
  routes: GameRoute[]
  activeRoute: GameRoute
  handleChangeGameRoute: (route: GameRoute) => void
}

export const GameDetailsNavigator = ({
  routes,
  activeRoute,
  handleChangeGameRoute,
}: Props) => {
  // isWagerCloseBefore
  const isActive = (route: GameRoute) => {
    return route.route === activeRoute.route
  }

  return (
    <div className="gameDetailNavigator">
      {routes.map((routeItem) => {
        return (
          <span
            role="menuitem"
            onClick={() => handleChangeGameRoute(routeItem)}
            onKeyDown={() => handleChangeGameRoute(routeItem)}
            className={`${
              isActive(routeItem) && 'activeDetail' ? 'activeDetail' : ''
            }`}
            key={routeItem.route}>
            {routeItem.label}
          </span>
        )
      })}
    </div>
  )
}
