import { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { PaymentsMethod } from '../../../types/bank'

type FormData = {
  cardNumber: string
  fullname: string
  // startMonth: number
  // startYear: number
  expirationMonth: number
  expirationYear: number
  emissionCode: number
  cvv: number
  depositAmount: string
}

const currentYear = new Date().getFullYear()
const limitYear = currentYear + 50
const months = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
]

interface Props {
  currentMethod: PaymentsMethod
}
const maskOptions = { mask: Number } // make sure options don't change on each render (use constant or useMemo or ref)

export const CreditCardForm = ({ currentMethod }: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>()
  const onSubmit = handleSubmit((data) =>
    console.log('Dados cartão de crédito', data),
  )
  // firstName and lastName will have correct type
  const GenerateYears = () => {
    const allYears = []
    for (let year = currentYear; year < limitYear; year += 1) {
      allYears.push(year)
    }
    return allYears
  }

  const [value, setValueX] = useState('')

  // const [ref] = useIMask(maskOptions, {
  //   // use onAccept callback instead of onChange to change value
  //   onAccept: (e) => {
  //     // setValue(e.target.value)
  //   },
  // })

  // const [opts, setOpts] = useState({ mask: Number })
  // const { ref, maskRef } = useIMask(opts)

  return (
    <Container>
      <form style={{ width: '100%' }} onSubmit={onSubmit}>
        <Row>
          <Col md={12} className="form-field-column">
            <label className="form-label" htmlFor="cardNumber">
              Número do Cartão
            </label>
            {/* <input
              className="form-field"
              // {...register('cardNumber')}
            /> */}
            <div>
              <NumberFormat
                className="form-field"
                placeholder="Cartão de Crédito"
                format="#### #### #### ####"
                onValueChange={(v) => {
                  setValue('cardNumber', v.value)
                }}
              />
            </div>
          </Col>
          <Col md={12} className="form-field-column">
            <label className="form-label" htmlFor="fullname">
              Nome do Titular do Cartão
            </label>
            <input className="form-field" {...register('fullname')} />
          </Col>
          {/* DATAS  */}
          <Col md={6} className="form-field-column">
            <label className="form-label" htmlFor="expirationMonth">
              Mês de Expiração
            </label>
            <select className="form-field" {...register('expirationMonth')}>
              {months.map((month) => {
                return (
                  <option key={month} value={month}>
                    {month}
                  </option>
                )
              })}
            </select>
          </Col>
          <Col md={6} className="form-field-column">
            <label className="form-label" htmlFor="startYear">
              Ano de Expiração
            </label>
            <select className="form-field" {...register('expirationYear')}>
              {GenerateYears().map((year) => {
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                )
              })}
            </select>
          </Col>
          <Col md={12} className="form-field-column">
            <label className="form-label" htmlFor="emissionCode">
              Número de Emissão
            </label>
            <input className="form-field" {...register('emissionCode')} />
          </Col>
          <Col md={12} className="form-field-column">
            <label className="form-label" htmlFor="cvv">
              Número de Segurança do Cartão
            </label>
            <input className="form-field" {...register('cvv')} />
          </Col>
          <Col md={12} className="form-field-column">
            <label className="form-label" htmlFor="depositAmount">
              Valor do Depósito
            </label>
            <div>
              <NumberFormat
                className="form-field"
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                placeholder="Valor da aposta"
                onValueChange={(v) => {
                  setValue('depositAmount', v.value)
                }}
              />
            </div>
          </Col>
          <Col md={12}>
            <button className="submit-button" type="submit">
              Aceitar e Depositar
            </button>
          </Col>
        </Row>
      </form>
    </Container>
  )
}
