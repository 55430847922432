export const AuthStorageUser = () => {
  const userString = localStorage.getItem('user')
  let userObj: any
  if (userString) {
    userObj = JSON.parse(userString)
  }
  return userObj
}

export const AuthStorageToken = () => {
  const tokenString = localStorage.getItem('token')

  if (tokenString === null) {
    // tokenString = "";
  }
  //   localStorage.getItem("token") === null ? "" : localStorage.getItem("token");
  //   localStorage.getItem("token") === null ? "" : localStorage.getItem("token");
  // let userObj: any;
  // if (userString && tokenString) {
  //   userObj = JSON.parse(userString);
  // }
  return tokenString
  // const user = useSelector((state: RootState) => state.user);
}

export default {
  AuthStorageToken,
  AuthStorageUser,
}
