import { offersList } from './data'
import './style.scss'

export interface OfferItem {
  title: string
  description: string
  footer: string
  route: string
}

export interface OfferItemProps {
  offer: OfferItem
}

const OfferItem = ({ offer }: OfferItemProps) => {
  const { title, description, footer } = offer
  return (
    <div className="offer-item">
      <h5>{title}</h5>
      <p>{description}</p>
      <p className="offer-item-footer">
        {footer !== '' ? footer : 'Mais Informacoes'}
      </p>
    </div>
  )
}

export const SideOffers = () => {
  return (
    <div className="side-offers-container">
      <h2>Ofertas</h2>
      {offersList.map((offer) => {
        return <OfferItem key={offer.title} offer={offer} />
      })}
    </div>
  )
}
