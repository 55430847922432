import * as yup from 'yup'

const requiredMsg = 'Esse campo é obrigatório!'
const minPassLengthMsg = 'A senha deve ter no mínimo 6 caracteres'
const maxPassLengthMsg = 'A senha deve ter no máximo 32 caracteres'

export const signInSchema = yup
  .object({
    email: yup.string().required(requiredMsg), // Email or username

    password: yup
      .string()
      .required(requiredMsg)
      // .min(6, minPassLengthMsg)
      .max(32, maxPassLengthMsg),
  })
  .required()
