import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { oddChecked } from '../../Games/types/odds'
import Wagers, { Multiple, Multiples } from '../types/wagers'

const initialState: Wagers = {
  hideWager: false,
  selectionIsMinimized: false,
  cashbackActive: true,
  cardIsExpanded: false,
  notEnoughCash: false,
  totalPrize: 0,
  totalWagers: 0,
  pricesSum: undefined,
  odds: [] as oddChecked[],
  multiples: {} as Multiples,
}

export const wagersReducer = createSlice({
  name: 'wagersReducer',
  initialState,
  reducers: {
    setOdds: (state, action: PayloadAction<oddChecked[]>) => {
      state.odds = action.payload
      state.totalWagers = action.payload.length
    },
    setMultiple: (
      state,
      action: PayloadAction<{ multiple: Multiple; index: number }>,
    ) => {
      const { multiple, index } = action.payload
      state.multiples[index] = multiple
    },
    setCashbackActive: (state, action: PayloadAction<boolean>) => {
      state.cashbackActive = action.payload
    },
    setMinimized: (state, action: PayloadAction<boolean>) => {
      state.selectionIsMinimized = action.payload
    },
    setHideWager: (state, action: PayloadAction<boolean>) => {
      state.hideWager = action.payload
    },
    setNotEnoughCash: (state, action: PayloadAction<boolean>) => {
      state.notEnoughCash = action.payload
    },
    setExpanded: (state, action: PayloadAction<boolean>) => {
      state.cardIsExpanded = action.payload
    },
    setTotalPrize: (state, action: PayloadAction<number | undefined>) => {
      state.totalPrize = action.payload
    },
    setPriceSum: (state, action: PayloadAction<number | undefined>) => {
      state.pricesSum = action.payload
    },
    setTotalWagers: (state, action: PayloadAction<number>) => {
      state.totalWagers = action.payload
    },

    setTotalWagersAndPrize: (
      state,
      action: PayloadAction<{ totalWagers: number; totalPrize: number }>,
    ) => {
      state.totalWagers = action.payload.totalWagers
      state.totalPrize = action.payload.totalPrize
    },
  },
})

export const {
  setMinimized,
  setTotalPrize,
  setExpanded,
  setTotalWagers,
  setTotalWagersAndPrize,
  setOdds,
  setMultiple,
  setPriceSum,
  setNotEnoughCash,
  setHideWager,
  setCashbackActive,
} = wagersReducer.actions

export default wagersReducer.reducer
