import { useEffect } from 'react'
import NumberFormat from 'react-number-format'

interface Props {
  register: any
  name1: string
  name2: string
  defaultValue1?: number
  defaultValue2?: number

  setValue: any
  // label?: string
  // placeholder?: string
  errors: any
  className?: string
  onBlurFunc?: () => void | Promise<void>
  trigger: any
}

export const PhoneInput = ({
  name1,
  name2,
  defaultValue1,
  defaultValue2,
  setValue,
  // placeholder,
  // label,
  register,
  errors,
  className,
  onBlurFunc,
  trigger,
}: // formType,
Props) => {
  // const registerFunction = register as UseFormRegister<typeof formType>
  // const dddMaxLength = ({ value }: NumberFormatValues) => value.length <= 2
  useEffect(() => {
    if (defaultValue1) setValue(name1, defaultValue1)
    else setValue(name1, 55)
    if (defaultValue2) setValue(name2, defaultValue2)
  }, [])

  return (
    <div key={`${name1}${name2}`} className="col-12">
      <div className="row">
        <div className="col-4">
          <div className="inputContainer">
            <NumberFormat
              defaultValue={defaultValue1}
              className="form-field"
              format="+###"
              placeholder=" "
              onValueChange={(v) => {
                trigger(name1)
                setValue(name1, v.value)
              }}
              onBlur={() => {
                trigger(name1)
              }}
            />
            <label className="formPlaceholder" htmlFor={name1}>
              Código pais
            </label>
          </div>
          {errors[name1]?.message && (
            <p className="input-error-warning">{errors[name1]?.message}</p>
          )}
        </div>
        <div className="col-8">
          <div className="inputContainer">
            <NumberFormat
              defaultValue={defaultValue2}
              type="tel"
              className="form-field"
              format="(##) #####-####"
              placeholder=" "
              onValueChange={(v) => {
                trigger(name2)
                setValue(name2, v.value)
              }}
              onBlur={() => {
                trigger(name2)
              }}
            />
            <label className="formPlaceholder" htmlFor={name1}>
              Número de celular
            </label>
          </div>
          <p className="input-error-warning">{errors[name2]?.message}</p>
        </div>
      </div>
    </div>
  )
}
PhoneInput.defaultProps = {
  className: '',
  onBlurFunc: null,
  defaultValue1: 55,
  defaultValue2: null,
}
