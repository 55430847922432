import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { translateOddName } from '../../../../../../../../common/functions/odds'
import { SecondaryOddCard } from '../cards/SecondaryOddCard'
import {
  GameDetailsOdds,
  MarketsList as SpecifierList,
  OddTableProps,
} from '../types'

export const SecondaryOddTable = ({ gameDetails, odds }: OddTableProps) => {
  // console.log('Secondary Odds', odds)
  const { t } = useTranslation()
  const [specifierSize, setSpecifierSize] = useState<number>(3)
  const [subSpecifierList, setSubSpecifierList] = useState<SpecifierList>(
    {} as SpecifierList,
  )
  const [leftRowSize, setLeftRowSize] = useState<number>(4)
  const [rightRowSize, setRightRowSize] = useState<number>(8)
  const [rightColSize, setRightColSize] = useState<number>(6)
  const home = gameDetails.teams[0].name
  const away = gameDetails.teams[1].name

  const separeteOddsBySpecifier = (Odds: GameDetailsOdds[]) => {
    const objSpecifierAux = {} as SpecifierList
    Odds.forEach((odd, index) => {
      if (objSpecifierAux[odd.oddName]) {
        objSpecifierAux[odd.oddName] = [...objSpecifierAux[odd.oddName], odd]
      } else objSpecifierAux[odd.oddName] = [odd]
      setSpecifierSize(index + 1)
    })
    // console.log('Specifier List', objSpecifierAux)
    setSubSpecifierList(objSpecifierAux)
    // return objSpecifierAux
  }

  const setSizes = (leftCol: number, oddsPerRow: number) => {
    setLeftRowSize(leftCol)
    setRightRowSize(12 - leftCol)
    setRightColSize(12 / oddsPerRow)
  }

  const SwitchRowAndColSizes = (oddsPerRow: number) => {
    switch (oddsPerRow) {
      case 2: {
        setSizes(4, oddsPerRow)
        break
      }
      case 3: {
        setSizes(3, oddsPerRow)
        break
      }
      default: {
        break
      }
    }
  }

  useEffect(() => {
    SwitchRowAndColSizes(
      subSpecifierList[Object.keys(subSpecifierList)[0]]?.length,
    )
    // console.log(
    //   'oddsPerRow',
    //   subSpecifierList[Object.keys(subSpecifierList)[0]]?.length,
    // )
    // console.log(subSpecifierList)
  }, [subSpecifierList])

  useEffect(() => {
    separeteOddsBySpecifier(odds)
  }, [odds])

  // console.log('subSpecifierList', subSpecifierList)
  return (
    <>
      {Object.entries(subSpecifierList).map((specifier, index) => {
        return (
          <div key={specifier[0]}>
            {index === 0 && (
              <div className="oddHeaderContainer row g-0">
                <div className={`col-${leftRowSize}`} />
                <div className={`row g-0 col-${rightRowSize}`}>
                  {specifier[1].map((odd) => {
                    return (
                      <div
                        className={`col-${rightColSize}`}
                        key={odd.betGamesOddId}>
                        <span
                          className="d-flex justify-content-center"
                          style={{ alignSelf: 'center', color: '#ffffff' }}>
                          {/* {t(odd.oddHeader)} */}
                          {translateOddName(odd.oddHeader, home, away, t)}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            <div className="row g-0">
              <div
                className={`specifierContainer secondaryCardDetails col-${leftRowSize}`}>
                {translateOddName(specifier[0], home, away, t)}
              </div>
              <div className={`row g-0 col-${rightRowSize}`}>
                {specifier[1].map((odd) => {
                  return (
                    <div key={odd.betGamesOddId} className="col-6">
                      <SecondaryOddCard
                        key={odd.betGamesOddId}
                        odd={odd}
                        teams={gameDetails.teams}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
