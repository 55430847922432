import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../../../../core/store/Reducers'
import './style.scss'

export const SportsMenu = () => {
  const menu = useSelector((state: RootState) => state.menu)
  return (
    <ul className="SportsMenu hideScrolls d-md-none">
      {menu.items.length &&
        menu.items.map((item) => {
          return (
            <li key={item.route}>
              {item.icon && (
                <Link
                  className="Link SportsMenuItem"
                  to={`games/sport/${item.route}`}>
                  {item.icon && <img src={item.icon} alt={item.name} />}
                  <p className="Link">{item.name} </p>
                </Link>
              )}
            </li>
          )
        })}
    </ul>
  )
}
