import axios from 'axios'
import apiHelper from '../../../../../../../core/services/apiHelper'

/**
 *  Conta do usuário
 * @param token
 * @returns
 */
const token = localStorage.getItem('token')

export const getGameDetails = async (gameId: string) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl(`sport/game/${gameId}`),
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}
