export const BankRoutes = [
  {
    label: 'Saldo',
    route: 'balance',
  },
  {
    label: 'Depósito',
    route: 'funds',
  },
  {
    label: 'Histórico',
    route: 'history',
  },
  {
    label: 'Transferências',
    route: 'transfer',
  },
  {
    label: 'Gerenciar Saques',
    route: 'withdraw',
  },
]
