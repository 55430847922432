import { MainInput } from '../../../../../Global/components/Inputs/MainInput'
import { FormSection } from '../../types'

export const PersonalInfo = ({ errors, register }: FormSection) => {
  const pronounList = ['Sr.', 'Sra.', 'Srta.']
  return (
    <div>
      <legend className="form-legend">Informação Pessoal</legend>
      <p className="form-description">
        Por favor introduza informação correta. A verificação da sua identidade
        é necessária para permitir a utilização contínua da sua conta.
      </p>
      <div className="col form-field-padding">
        <label className="form-label" htmlFor="Countries">
          Título
        </label>
        <select className="form-field" {...register(`pronoun`)}>
          {pronounList.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            )
          })}
        </select>
      </div>
      <MainInput
        type="text"
        name="firstname"
        register={register}
        placeholder="Nome"
        errors={errors}
        className="col-md-12"
      />
      <MainInput
        type="text"
        name="lastname"
        register={register}
        placeholder="Sobrenome"
        errors={errors}
        className="col-md-12"
      />
      <MainInput
        type="date"
        name="birthdate"
        label="Data de Nascimento"
        register={register}
        errors={errors}
        className="col-md-12"
      />
      <br />
      <MainInput
        type="text"
        name="cpf"
        placeholder="Número de CPF"
        register={register}
        errors={errors}
        className="col-md-12"
      />
      <hr className="solid" />
    </div>
  )
}
