export const AccountRoutes = [
  {
    label: 'Dados',
    route: 'personal',
  },
  {
    label: 'Endereço Residencial',
    route: 'address',
  },
  {
    label: 'Preferências',
    route: 'preferences',
  },
  {
    label: 'Senha',
    route: 'password',
  },
  {
    label: 'Verificação',
    route: 'verification',
  },
]
