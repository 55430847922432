import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { Transaction } from '../types/bank'
import { getHistory } from '../xhr/bank.xhr'
import './BankHistory.css'

export const BankHistory = () => {
  const user = useAppSelector((state: RootState) => state.user)

  const [history, setHistory] = useState<Transaction[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await getHistory(user.token)
      // console.log(response)
      setHistory(response.data)
    })()
  }, [])

  return (
    <>
      <h1 className="config-title d-none d-md-block">Histórico</h1>
      <div
        className=""
        style={{
          overflowX: 'auto',
          // maxWidth: '100vw',
          // display: 'flex',
        }}>
        <div className="tableRow" style={{ paddingBottom: '20px' }}>
          <div className="tableTitle">Método</div>
          <div className="tableTitle">Valor da transação</div>
          <div className="tableTitle">Data</div>
        </div>
        {history &&
          history.map(
            ({ betCnfPaymentMethodName, price, data, id }: Transaction) => {
              return (
                <div className="tableRow" key={id}>
                  <div className="tableItem col-3">
                    {betCnfPaymentMethodName}
                  </div>
                  <div className="tableItem col-5">
                    {parseFloat(price).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </div>
                  <div className="tableItem col-4">{data}</div>
                </div>
              )
            },
          )}
      </div>
    </>
  )
}
