/* eslint-disable no-param-reassign */
const apiHelper = {
  baseUrl(method?: string) {
    if (!method) {
      method = ''
    }
    return `https://betkingbr.mobtex.com/${method}`
  },
  getMethod(methodName: string) {
    return this.baseUrl() + methodName
  },
  // state2FormData(stateItens: object, blacklist?: string[]): FormData {
  //   // console.log('state2FormData');
  //   // console.log(stateItens);
  //   // console.log(typeof stateItens);
  //   const formData = new FormData();
  //   for (const [key, value] of Object.entries(stateItens)) {
  //     // console.log(key, value);
  //     // se estiver na blacklist não adiciona o item no formulário.
  //     if (blacklist && blacklist.includes(key)) {
  //       continue;
  //     }
  //     // console.log(blacklist);
  //     formData.append(key, value);
  //   }
  //   return formData;
  // },
}
export default apiHelper
