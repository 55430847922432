import { useTranslation } from 'react-i18next'
import { GiSoccerBall } from 'react-icons/gi'
import { Link } from 'react-router-dom'
import { getOddsByMarket } from '../../../../../../../common/functions/odds'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { GameItem } from '../../../Games/types/games'
import { SecondaryOddCard } from '../../../Games/views/GameDetails/cards/SecondaryOddCard'

interface Props {
  CardsList: GameItem[]
}

export const HighlightCardList = ({ CardsList }: Props) => {
  const { t } = useTranslation()
  const sports = useAppSelector((state: RootState) => state.sports)
  const sportActual = sports.items.find((item) => item.route === 'futebol')

  return CardsList.map(({ info, teams, odds }, index) => {
    const { betCnfTournamentId, betGameId } = info
    const hour = info.gameStart.split(' ')[1]
    const gameDate = new Date(info.gameStart)
    gameDate.setHours(0, 0, 0, 0)
    const date = info.gameStart.split(' ')[0]
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const isToday = today.getTime() === gameDate.getTime()
    // const todaysDate = `${today.getDate()}/${today.getMonth() + 1}/${
    //   today.getFullYear().toString().split('20')[1]
    // }`

    // console.log('Today', today, 'date', date)

    return (
      <div
        id={`card${index}`}
        className="highlight-card-container"
        key={info.betGameId}>
        <Link
          to={`/games/sport/${sportActual?.route}/${betCnfTournamentId}/${betGameId}`}
          className="Link">
          <div className="highlight-card-header">
            <div>
              <GiSoccerBall color="#ffffff" />
              <span className="championship">
                {' '}
                {t(info.betCnfTournamentName)}
              </span>
            </div>
            <div>
              {date && (
                <span style={{ paddingRight: '0.5rem' }}>
                  {isToday ? 'Hoje' : date}
                </span>
              )}
              {hour && <span>{hour}</span>}
            </div>
          </div>
          <h6>{teams[0].name}</h6>
          <h6>{teams[1].name}</h6>
        </Link>
        <div className="market-name-container">
          <span className="line-span" />
          <span className="market-title"> Resultado Final</span>
          <span className="line-span" />
        </div>
        <div className="row g-0">
          {getOddsByMarket(1000002, odds).map((odd) => {
            return (
              <SecondaryOddCard
                key={odd.betGamesOddId}
                odd={odd}
                teams={teams}
                showLabel
                shortName
                styles="col-md-4 highlightOdds"
              />
            )
          })}
        </div>
      </div>
    )
  })
}
