import axios from 'axios'
import apiHelper from '../../../../../../../core/services/apiHelper'
import { DepositDefaultType } from '../types/bank'

export const creditsGetAll = async (token: string) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('credits/getAll'),
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const getBalance = async (token: string) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('balance/getAll'),
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const getWithdraw = async (token: string) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('credits/withdraw'),
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const getFunds = async (token: string) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('funds/getAll'),
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const getHistory = async (token: string) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('funds/history'),
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const sendDepositDefault = async (
  token: string,
  data: DepositDefaultType,
) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('funds/save'),
      data,
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}
