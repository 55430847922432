import { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { FaLock } from 'react-icons/fa'

interface Props {
  register: any
  name: string
  label?: string
  placeholder?: string
  errors: any
  hideRightIcon?: boolean
  className?: string
  onBlurFunc?: () => void | Promise<void>
}

export const PasswordInput = ({
  name,
  placeholder,
  label,
  register,
  errors,
  className,
  onBlurFunc,
  hideRightIcon,
}: // formType,
Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  // const registerFunction = register as UseFormRegister<typeof formType>
  const iconSize = 30
  const iconColor = 'orange'
  return (
    <div key={name} className={className ? `col` : `col ${className}`}>
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="inputContainer">
        <div className="leftIconContainer">
          <FaLock
            color={iconColor}
            size="25"
            className="isShowPassword"
            onClick={() => setShowPassword(false)}
          />
        </div>

        <input
          className="form-field inputWithLeftIcon"
          type={showPassword ? 'text' : 'password'}
          placeholder=" "
          onBlur={onBlurFunc ? () => onBlurFunc() : null}
          {...register(`${name}`)}
        />
        {placeholder && (
          <label className="formPlaceholder" htmlFor={name}>
            {placeholder}
          </label>
        )}
        <div
          className={`${hideRightIcon ? 'd-none' : ''}`}
          style={{
            position: 'absolute',
            top: '50%',
            right: '2rem',
            transform: 'translateY(-50%)',
          }}>
          {!showPassword ? (
            <AiOutlineEyeInvisible
              style={{ cursor: 'pointer' }}
              color={iconColor}
              size={iconSize}
              className="isShowPassword"
              onClick={() => setShowPassword(true)}
            />
          ) : (
            <AiOutlineEye
              style={{ cursor: 'pointer' }}
              color={iconColor}
              size={iconSize}
              className="isShowPassword "
              onClick={() => setShowPassword(false)}
            />
          )}
        </div>
      </div>
      {errors[name]?.message && (
        <p className="input-error-warning">{errors[name]?.message}</p>
      )}
    </div>
  )
}
PasswordInput.defaultProps = {
  hideRightIcon: false,
  label: '',
  placeholder: null,
  className: '',
  onBlurFunc: null,
}
