import { TFunction } from 'react-i18next'
import {
  GameItem,
  GameItemIndexable,
  GameItemListIndexable,
} from '../../app/bet/Global/views/Games/types/games'
import { oddChecked, Odds } from '../../app/bet/Global/views/Games/types/odds'
import { HideHighlightOptions } from '../../app/bet/Global/views/Games/views/components/MainTable/GameTable'
import { BetOdd } from '../../app/bet/Global/views/MyBets/data'
import { Multiples } from '../../app/bet/Global/views/Wagers/types/wagers'

export const getOddsByMarket = (marketId: number, odds: Odds[] | undefined) => {
  // console.log('marketId', marketId)
  // console.log('Odds to filter', odds)
  let market = odds?.filter((o) => o.market_id === marketId)
  if (marketId === 1000040) {
    market = market?.filter(
      (o) => o.oddName === 'To Win' || o.oddName === 'Money Line',
    )
  }
  // console.log('FilteredOdds', market)
  if (market) return market
  return []
}

export const getHighlightHome = (gameList: GameItemIndexable) => {
  const gamesHighlighted: GameItem[] = Object.entries(gameList)
    .filter((game) => game[1].info.isHighlightHome === true)
    .map((game) => {
      return game[1]
    })
  return gamesHighlighted
}

export const getGamesBySport = (
  gameItems: GameItemIndexable,
  sportId: string,
  // tournamentId?: string,
  hideHighlight?: HideHighlightOptions,
) => {
  let resultFiltered = [] as GameItem[]

  Object.entries(gameItems)
    .filter((game) => {
      if (game[1]?.info.betCnfSportId === parseInt(sportId, 10)) {
        if (hideHighlight && hideHighlight !== 'none') {
          if (!game[1]?.info[hideHighlight]) {
            return game
          }
        } else {
          return game
        }
      }
      return false
    })
    .forEach((game) => {
      const gameItem = game[1] as GameItem
      resultFiltered.push(gameItem)
    })
  resultFiltered = resultFiltered.sort(
    (b, a) => b.info.gameStartTimeStamp - a.info.gameStartTimeStamp,
  )
  return resultFiltered
}

export const getGamesByTournamentId = (
  gameItems: GameItemIndexable,
  tournamentId: string,
) => {
  const resultFiltered = [] as GameItem[]
  Object.entries(gameItems)
    .filter((gameItem) => gameItem[1].info.betCnfTournamentId === tournamentId)
    .forEach((game) => {
      const gameItem = game[1] as GameItem
      resultFiltered.push(gameItem)
    })
  return resultFiltered
}

export const groupGamesByTournament = (gameItems: GameItem[]) => {
  const groupedGames = {} as GameItemListIndexable
  gameItems.forEach((gameItem) => {
    const { betCnfTournamentId, betCnfTournamentName } = gameItem.info
    if (!groupedGames[betCnfTournamentId]) {
      groupedGames[betCnfTournamentId] = {
        title: betCnfTournamentName,
        list: [gameItem],
      }
    } else {
      groupedGames[betCnfTournamentId].list.push(gameItem)
    }
  })
  return groupedGames
}

export const getOddsCombinations = (array: oddChecked[], type: number) => {
  const combination = [] as number[][]
  const result = [] as number[]
  result.length = type // n=2

  function combine(input: oddChecked[], len: number, start: number) {
    if (len === 0) {
      combination.push([...result])
      return
    }
    for (let i = start; i <= input.length - len; i += 1) {
      result[result.length - len] = input[i].odd.betGamesOddId
      combine(input, len - 1, i + 1)
    }
  }
  combine(array, type, 0)
  // console.log('COMBINATION', combination)
  return combination
}

export const calcRateCombination = (
  oddsChecked: oddChecked[],
  combination: number[],
) => {
  let rate = 0
  combination.forEach((oddId, index) => {
    const oddFind = oddsChecked.find((item) => item.odd.betGamesOddId === oddId)
    if (oddFind) {
      if (index === 0) rate = oddFind.odd.rate
      else rate *= oddFind.odd.rate
    }
  })
  return rate
}

export const getWagersTotalPrice = (
  oddsChecked: oddChecked[],
  multiples: Multiples,
) => {
  let totalPrice = 0
  oddsChecked.forEach((item) => {
    if (item.odd.price) totalPrice += item.odd.price
  })

  Object.entries(multiples).forEach((multiple) => {
    multiple[1].forEach((subMultiple) => {
      if (subMultiple.price) totalPrice += subMultiple.price
    })
  })

  return totalPrice
}

export const getWagersTotalPrize = (
  oddsChecked: oddChecked[],
  multiples: Multiples,
) => {
  let totalPrize = 0
  oddsChecked.forEach((item) => {
    if (item.odd.price) totalPrize += item.odd.price * item.odd.rate
  })

  Object.entries(multiples).forEach((multiple) => {
    multiple[1].forEach((subMultiple) => {
      if (subMultiple.price && subMultiple.rate)
        totalPrize += subMultiple.price * subMultiple.rate
    })
  })

  return totalPrize
}

export const hasLiveGame = (odds: BetOdd[]) => {
  let hasLive = false
  odds.forEach((odd) => {
    if (odd.gameInfo.gameStatus === 'live') hasLive = true
  })
  return hasLive
}

export const translateOddName = (
  label: string,
  home: string,
  away: string,
  t: TFunction<'translation', undefined>,
  shortName?: boolean,
) => {
  const wordsToTranslate = [
    'Draw',
    'or',
    'Yes',
    'No',
    'To Win',
    'Total Points',
    'Correct Set Score',
    'LOSE',
    'WIN',
    ' and ',
    'Match',
    ' to ',
    ' First ',
    'Spread',
    'Money Line',
    'Over',
    'Under',
  ]
  let newLabel = label
  if (shortName) {
    // console.log('home', home)
    // console.log('newLabel', newLabel)
    newLabel = newLabel.replace(
      home.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      '1',
    )
    newLabel = newLabel.replace(
      away.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      '2',
    )
    newLabel = newLabel.replace('Draw', 'X')
  }
  switch (label) {
    case '1': {
      if (shortName) return '1'
      return home
    }

    case 'Draw': {
      if (shortName) return 'X'
      return 'Empate'
    }
    case '2': {
      if (shortName) return '2'
      return away
    }
    default: {
      wordsToTranslate.forEach(async (word) => {
        if (label.includes(word)) newLabel = newLabel.replaceAll(word, t(word))
      })
    }
  }
  return newLabel
}
