import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DefaultOdds } from '../types/odds'

const initialState = {
  default: [] as DefaultOdds[],
}

export const oddsReducer = createSlice({
  name: 'oddsdefaults',
  initialState,
  reducers: {
    setDefaultOdds: (
      state,
      action: PayloadAction<{ default: DefaultOdds[] }>,
    ) => {
      state.default = action.payload.default
    },
  },
})

export const { setDefaultOdds } = oddsReducer.actions

export default oddsReducer.reducer
