import * as yup from 'yup'

const requiredMsg = 'Esse campo é obrigatório!'
const emailMsg = 'O e-mail deve seguir o formato example@email.com'
const minPassLengthMsg = 'A senha deve ter no mínimo 6 caracteres'
const maxPassLengthMsg = 'A senha deve ter no máximo 32 caracteres'
const minPhoneLenghtMsg = 'Número muito curto'
const minCPFLengthMsg = 'CPF muito curto'
const termErrorMsg = 'É necessário aceitar os termos para prosseguir'

export const registerUserSchema = yup
  .object({
    country: yup.number().required(requiredMsg),
    firstname: yup.string().required(requiredMsg),
    lastname: yup.string().required(requiredMsg),
    // Validade to only allow people over 18 years old
    birthdate: yup.string().required(requiredMsg),
    // Validate CPF pattern?
    cpf: yup.string().min(11, minCPFLengthMsg).required(requiredMsg),
    email: yup.string().required(requiredMsg).email(emailMsg),
    // Make a special field for phone??
    countryCode: yup.string().required(requiredMsg),
    phone: yup.string().min(11, minPhoneLenghtMsg).required(requiredMsg),
    address: yup.string().required(requiredMsg),

    password: yup
      .string()
      .required(requiredMsg)
      .min(6, minPassLengthMsg)
      .max(32, maxPassLengthMsg),

    // acceptTerms: yup.string().required(requiredMsg),
    acceptTerms: yup.bool().oneOf([true], termErrorMsg),
  })
  .required()
