import { useEffect } from 'react'
import { setMenuLeft } from '../../../../../core/store/items/app'
import { useAppDispatch } from '../../../../../core/store/Reducers'
import { setHideWager } from '../Wagers/store/wagers'

export const LiveGames = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setMenuLeft(false))
    dispatch(setHideWager(false))
  }, [])
  return (
    <div className="container">
      {/* <h2>Jogos ao vivo</h2> */}
      {/* <div>Lista de jogos ao vivo</div> */}
    </div>
  )
}
