import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MenuItem {
  route: string
  componentItem?: string
  iconType?: string
  name: string
  Icon?: null
  icon?: string
  fileCacheFs?: string
}

const initialState = {
  items: [] as MenuItem[],
}
export const menuReducer = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<{ items: MenuItem[] }>) => {
      state.items = action.payload.items
    },
  },
})
export const { setMenu } = menuReducer.actions

export default menuReducer.reducer
