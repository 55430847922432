import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { setPrefs } from '../../../../../core/store/items/user'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../core/store/Reducers'
import { ModalForgot } from '../../../Unauth/views/Forgot/ModalForgot'
import { ModalLogin } from '../../../Unauth/views/Login/ModalLogin'
import { oddChecked } from '../../views/Games/types/odds'
import {
  setOdds,
  setTotalWagers,
  setTotalWagersAndPrize,
} from '../../views/Wagers/store/wagers'
import { WagersSelections } from '../../views/Wagers/views/WagersSelections'
import { HeaderDefault } from '../Header/HeaderDefault'
import UserMenu from '../menu/AuthUserMenu/UserMenu'
import MenuMain from '../menu/MenuMain/MenuMain'
import './MainLayout.css'

interface Props {
  isAuth: boolean
}
const MainLayout = ({ isAuth }: Props) => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const {
    isShowModalLogin,
    isShowModalForgot,
    isShowRightMenu,
    isShowLeftMenu,
    isShowLeftMenuMobile,
  } = useAppSelector((state) => state.app)

  useEffect(() => {
    const prefs = localStorage.getItem('prefs')
    // console.log(prefs)
    const updatePrefsWithStorage = async () => {
      if (prefs && prefs !== 'undefined') {
        const prefsObj = JSON.parse(prefs)
        await dispatch(setPrefs(prefsObj))
        i18n.changeLanguage(prefsObj.language)
      }
    }
    updatePrefsWithStorage()
  }, [])

  const centerContentSizeClass = isShowLeftMenu ? 'col-md-10' : 'col-md-12'
  let leftContentSizeClass = isShowLeftMenu
    ? 'col-2  d-none d-md-block'
    : 'd-none'

  if (isShowLeftMenuMobile) {
    leftContentSizeClass = 'col-12'
  }

  // GAME functions

  const wagers = useAppSelector((state: RootState) => state.wagers)
  const gameList = useAppSelector((state: RootState) => state.gameList)
  useEffect(() => {
    // console.log('mudou game list')
    ;(async () => {
      let totalPrize = 0
      let pricesSum = 0
      const wagersSelectionTmp = [] as oddChecked[] // cria uma relação
      await Object.entries(gameList.items).forEach(async (item) => {
        const checkedOddsItens = item[1].odds.filter(
          (odd) => odd.checked === true,
        )
        if (checkedOddsItens.length) {
          checkedOddsItens.forEach((odd) => {
            // console.log('TOTAL PRIZE BEFORE', totalPrize)
            // console.log(odd.price, odd.rate)
            if (odd.price) {
              totalPrize += odd.price * odd.rate
              pricesSum += odd.price
            }
            // console.log('TOTAL PRIZE AFTER', totalPrize)
            wagersSelectionTmp.push({
              info: item[1].info,
              teams: item[1].teams,
              odd,
            })
          })
        }
      })
      // await dispatch(setPriceSum(pricesSum))
      // Causing 1 rerender
      // console.log('wagersSelectionTmp', wagersSelectionTmp)
      await dispatch(setOdds(wagersSelectionTmp)) // Puts checked odds on wager
      await dispatch(
        setTotalWagersAndPrize({
          totalPrize,
          totalWagers: wagersSelectionTmp.length,
        }),
      )
    })()
    // console.log('Game List', gameList)
  }, [gameList])

  useEffect(() => {
    ;(async () => {
      let totalChecked = 0
      await Object.entries(gameList.items).map(async (item) =>
        item[1].odds.forEach((odd) => {
          if (odd.checked) {
            totalChecked += 1
          }
        }),
      )
      await dispatch(setTotalWagers(totalChecked))
    })()
  }, [gameList])

  return (
    <>
      <HeaderDefault />
      <div className="container-fluid g-0" id="mainContent">
        <div
          className="row g-0"
          style={{ justifyContent: 'center', backgroundColor: '#000000' }}>
          <div className={leftContentSizeClass} id="leftBar">
            <MenuMain />
          </div>
          <div
            className={`col-12 g-0 ${centerContentSizeClass}`}
            id="centerContent">
            {/* <Loading /> */}
            <Outlet />
            {/* {isAuth ? (
              <div id="centerContentAuth">
                <Outlet />
              </div>
            ) : (
              <Outlet />
            )} */}
          </div>
        </div>
      </div>
      {isShowModalLogin && <ModalLogin />}
      {isShowModalForgot && <ModalForgot />}
      {isShowRightMenu && <UserMenu />}
      {wagers.totalWagers && <WagersSelections />}
    </>
  )
}
export default MainLayout
