/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useTranslation } from 'react-i18next'
import { translateOddName } from '../../../../../../../../common/functions/odds'
import { setModalLogin } from '../../../../../../../../core/store/items/app'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../core/store/Reducers'
import { changeOddSelection } from '../../../store/gameList'
import { GameItemTeams } from '../../../types/games'
import { Odds } from '../../../types/odds'
import '../../Games.scss'
import './style.scss'

type Props = {
  odd: Odds
  teams: GameItemTeams[]
  styles?: string
  showLabel?: boolean
  shortName?: boolean
  // onOddClick: (odd: Odds) => void
}
export const SecondaryOddCard = ({
  odd,
  styles,
  showLabel,
  teams,
  shortName,
}: Props) => {
  const { t } = useTranslation()
  const { oddHandicap } = odd
  const classChecked = odd.checked ? 'on_wager' : ''
  const labelChecked = odd.checked ? 'label_on_wager' : 'label_secondary_card'
  const user = useAppSelector((state: RootState) => state.user)
  const dispatch = useAppDispatch()
  const onOddClick = async () => {
    if (!user.isLogged) {
      dispatch(setModalLogin(true))
    } else {
      await dispatch(changeOddSelection(odd))

      // console.log(wagers.totalPrize)
    }
  }

  return (
    <div
      onClick={() => {
        onOddClick()
      }}
      key={odd.betCnfOddId}
      className={`OddCard card-live-game-tobet ${classChecked} col ${styles} ${
        showLabel ? 'OddWithLabel' : ''
      }`}>
      {showLabel && (
        <p
          className={`OddLabel ${labelChecked}`}
          style={{ paddingRight: '0.6rem' }}>
          {translateOddName(
            odd.oddName,
            teams[0].name,
            teams[1].name,
            t,
            shortName,
          )}
        </p>
      )}
      {oddHandicap.length ? (
        <p
          className={`OddLabel ${labelChecked}`}
          style={{ paddingRight: '0.6rem' }}>
          {translateOddName(
            oddHandicap,
            teams[0].name,
            teams[1].name,
            t,
            shortName,
          )}
        </p>
      ) : null}
      <span>{odd.rate.toFixed(2)}</span>
      {/* <p>{odd.rate}</p> */}
    </div>
  )
}

SecondaryOddCard.defaultProps = {
  styles: '',
  showLabel: false,
  shortName: false,
}
