import { formatCurrency } from '../../../../../../../../../../common/functions/formatting'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../../../core/store/Reducers'
import { setCashbackActive } from '../../../../../store/wagers'
import './style.scss'

export const CashbackToggle = () => {
  const dispatch = useAppDispatch()
  const wager = useAppSelector((state: RootState) => state.wagers)
  const wallet = useAppSelector((state: RootState) => state.wallet)
  const cashbackFormated = formatCurrency(wallet.cashback)
  const cashbackActiveLabel = wager.cashbackActive ? 'USAR ' : 'NÃO USAR'
  const handleChangeChecked = (checked: boolean) => {
    console.log('checked', checked)
    dispatch(setCashbackActive(checked))
  }
  console.log(wager.cashbackActive)
  return (
    <div>
      {wallet.cashback > 0 ? (
        <div
          className="form-field-column"
          key="notification"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5em 0.3em',
          }}>
          <p className="cashbackTogleLabel">
            <b>{cashbackActiveLabel}</b>
            {` saldo de cashback na aposta`}
            <p> Cashback disponível: {cashbackFormated}</p>
          </p>
          <input
            defaultChecked={wager.cashbackActive}
            onChange={(e) => handleChangeChecked(e.target.checked)}
            type="checkbox"
            className="input-radio-checked input-radio-checked-min"
          />
        </div>
      ) : (
        <div style={{ padding: '0.6em 1em' }}>
          Você ainda não tem saldo de cashback, aposte e ganhe
        </div>
      )}
    </div>
  )
}
