import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Notify } from '../../../../../../../../../common/components/toats/notify'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../../../core/store/Reducers'
import { SubmitButton } from '../../../../../../../Global/components/buttons/SubmitButton'
import { CPFInput } from '../../../../../../../Global/components/Inputs/CPFInput'
import { MainInput } from '../../../../../../../Global/components/Inputs/MainInput'
import { NumberInput } from '../../../../../../../Global/components/Inputs/NumberInput'
import { DepositResponseType, PaymentsMethod } from '../../../types/bank'
import { sendDepositDefault } from '../../../xhr/bank.xhr'
import { PixPayment } from '../../Payment/Pix'
// import '../forms.css'

interface Props {
  currentMethod: PaymentsMethod
}

type FormData = {
  ownerName: string
  cpf: string
  depositAmount: string
  pixFromOtherUser: boolean
}

const requiredMsg = 'Esse campo é obrigatório!'
const minDepositMsg = 'O valor mínimo para depósito é R$ 1,00'
const numberTypeMsg = 'O valor ter que ser um número'
const minLenght = 'Muito curto'

const schema = yup
  .object({
    cpf: yup.string().required(requiredMsg).min(11, minLenght),
    depositAmount: yup
      .number()
      .typeError(numberTypeMsg)
      // .min(5, minDepositMsg)
      .positive()
      .required(requiredMsg),

    // .test('test-name', minDepositMsg, function (value) {
    //   if (value) return value > 1
    //   return true
    // }),
  })
  .required()

export const FundDefaultForm = ({ currentMethod }: Props) => {
  const user = useAppSelector((state: RootState) => state.user)
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      ownerName: user.info.name,
      cpf: user.info.cpf,
    },
  })
  const [formResponse, setFormResponse] = useState<DepositResponseType>(
    {} as DepositResponseType,
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [disableFields, setDisableFields] = useState(true)

  const handleClearForm = () => {
    setFormResponse({} as DepositResponseType)
    setDisableSubmit(false)
    reset()
  }

  const renderPayment = () => {
    switch (currentMethod.id) {
      case 1:
        return (
          <PixPayment
            paymentData={formResponse}
            handleClearForm={handleClearForm}
          />
        )

      default:
        return null
    }
  }

  const onSubmit = async (data: FormData) => {
    // console.log(data)
    setLoading(true)
    setDisableSubmit(true)
    const auxData = { ...data, betCnfPaymentMethodId: currentMethod.id }
    const response = await sendDepositDefault(user.token, auxData)
    if (response.success) {
      Notify({ type: 'success', message: response.msg })
    } else {
      setDisableSubmit(false)
      Notify({ type: 'error', message: response.msg })
    }
    setFormResponse(response)
    setLoading(false)
  }
  // console.log('depositAmount', watch('depositAmount'))
  // deve mostrar o checkbox de mudar o CPF e nome do cliente para o depósito ?
  // TODO colocar no redux
  const isDisplayAccountDepositOtherPerson = false
  useEffect(() => {
    if (watch('pixFromOtherUser')) {
      setDisableFields(false)
    } else {
      // console.log('user.info.cpf', user.info.cpf)
      setValue('cpf', user.info.cpf)
      setValue('ownerName', user.info.name)
      setDisableFields(true)
    }
  }, [watch('pixFromOtherUser')])

  return (
    <div>
      {!formResponse.success ? (
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit(onSubmit)}
          noValidate>
          <div className="row">
            {/* <div
              className="col-12"
              style={{
                textAlign: 'justify',
                textJustify: 'inter-word',
                paddingBottom: '10px',
              }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: currentMethod.addFundsInstructions,
                }}
                className="payMsgRed"
              />
            </div> */}
            <MainInput
              type="text"
              name="ownerName"
              register={register}
              placeholder="Nome completo"
              // defaultValue={user.info.name}
              disabled={disableFields}
              errors={errors}
              className="col-12"
            />
            <CPFInput
              name="cpf"
              errors={errors}
              control={control}
              // defaultValue={user.info.cpf}
              disabled={disableFields}
              setValue={setValue}
              trigger={trigger}
            />

            {isDisplayAccountDepositOtherPerson && (
              <div className="col-12">
                <input
                  type="checkbox"
                  // name="pixFromOtherUser"
                  {...register('pixFromOtherUser')}
                  onChange={(e) => {
                    setValue('pixFromOtherUser', e.target.checked)
                    trigger('pixFromOtherUser')
                  }}
                  // value={value.toString()}
                  id="acceptTerms"
                  className={`form-check-input ${
                    errors.pixFromOtherUser ? 'is-invalid' : ''
                  }`}
                />
                <span style={{ paddingLeft: '10px' }}>
                  A conta pertence a outra pessoa{' '}
                </span>
              </div>
            )}
            <NumberInput
              prefix="R$ "
              name="depositAmount"
              placeholder="Valor do depósito"
              errors={errors}
              setValue={setValue}
              trigger={trigger}
            />
            <SubmitButton
              loading={loading}
              text="Depositar"
              disableSubmit={disableSubmit || Object.keys(errors).length > 0}
            />
          </div>
        </form>
      ) : (
        renderPayment()
      )}
    </div>
  )
}
