import './style.scss'

interface NoBetsAvailableProps {
  description: string
}

export const NoBetsAvailable = ({ description }: NoBetsAvailableProps) => {
  return (
    <div className="NoBetsAvailable">
      <h3>
        No momento não existem apostas para serem exibidas nessa categoria
      </h3>
      <p>{description}</p>
    </div>
  )
}
