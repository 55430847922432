import * as React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'

// type FormData = {
//   firstName: string
//   lastName: string
// }

export const PendingTransfer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = handleSubmit((data) => console.log(data))
  // firstName and lastName will have correct type

  return (
    <div className="container g-0">
      <div className="row">
        <div className="primaryParagraph">
          <div className="paraBulletList">
            <span id="ctl00_Main_WithdrawCancel_Info" className="info">
              Esta funcionalidade oferece uma variedade de opções para ajudar a
              gerenciar seus saques:
            </span>
            <span id="ctl00_Main_WithdrawCancel_Info1" className="li ml25">
              Poderá financiar a sua conta ao cancelar saques pendentes
              disponíveis. Todos os saques cancelados (e taxas associadas,
              quando aplicáveis) serão creditados no seu saldo de Esportes.
            </span>
            <span id="ctl00_Main_WithdrawCancel_Info2" className="li ml25">
              A funcionalidade de cancelar saques pode ser desativada
              imediatamente. A ativação terá efeito após 24 horas.
            </span>
          </div>
          <div className="NoPendingWithdrawalMessage">
            <span id="ctl00_Main_WithdrawCancel_NoPW">
              Você não tem saques pendentes.
            </span>
          </div>

          <hr className="solid" />
          <h3 className="pageHeader">
            &quot;Cancelar Saque&quot; está atualmente ativado
          </h3>
          <div className="fl w100 ">
            <span className="info">
              A desativação é instantânea, mas a ativação entrará em vigor após
              24 horas.
              {/* <a
                id="ctl00_Main_WithdrawCancel_lkCancelRequest"
                className="lkCancelRequest"></a> */}
            </span>
            <Col
              md={12}
              style={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}>
              <button
                className="submit-button"
                // style={{ width: '100%', padding: '10px' }}
                type="submit">
                Desativar Função &quot;Cancelar Saque&quot;
              </button>
            </Col>
            <div className="primaryParagraph">
              <span id="ctl00_Main_WithdrawCancel_ltRG">
                Jogo Responsável
                <br />
                Se quiser saber mais sobre as opções disponíveis, visite nossa
                página de{' '}
                <a
                  id="lkClkHr"
                  rel="noreferrer"
                  href="http://help.bet365.com/br/responsible-gambling"
                  target="_blank">
                  Jogo Responsável
                </a>
                .
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
