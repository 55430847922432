import { useEffect } from 'react'
import { AuthStorageToken } from '../../../../../auth/helpers/AuthStorage'
import {
  getBaseAuthAttr,
  getBaseUnauthAttr,
} from '../../../../../auth/xhr/authCheck.xhr'
import { Loading } from '../../../../../core/containers/Loading/Loading'
import { setCloseIsCheckRemoteUserLoggedIn } from '../../../../../core/store/items/app'
import {
  Country,
  setCountriesList,
} from '../../../../../core/store/items/countries'
import { setMenu } from '../../../../../core/store/items/menu'
import { setLoggedUser } from '../../../../../core/store/items/user'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../core/store/Reducers'
import { assignWallet } from '../../../Auth/views/Wallet/store/wallet'
import { setDashboardItens } from '../Dashboard/store/dashboard'
import { setGameList } from '../Games/store/gameList'
import { setMarkets } from '../Games/store/markets'
import { setSports } from '../Sports/store/sport'
import { getCoutriesRemote } from './countries.xhr'

const CheckRemoteLoggedIn = () => {
  const dispatch = useAppDispatch()
  // let menu = [] as DrawerItem[];
  const tokenString = AuthStorageToken()
  const wagers = useAppSelector((state: RootState) => state.wagers)

  const checkLoggedIn = async () => {
    let isLoadRemoteUnauthParams = true

    if (tokenString) {
      const resultAuth = await getBaseAuthAttr(tokenString)
      // console.log(resultAuth.dashboard)
      // usuário está logado
      if (!resultAuth.disconnect) {
        isLoadRemoteUnauthParams = false

        await dispatch(
          setLoggedUser({
            address: resultAuth.user.address,
            user: resultAuth.user.info,
            token: tokenString,
          }),
        )
        await dispatch(
          setSports({
            items: resultAuth.menu,
          }),
        )
        await dispatch(
          setMenu({
            items: resultAuth.menu,
          }),
        )

        await dispatch(
          assignWallet({
            data: resultAuth.wallet,
          }),
        )
        await dispatch(setMarkets(resultAuth.markets))
        await dispatch(setDashboardItens(resultAuth.dashboard))

        // console.log('Result', resultAuth.dashboard)
        await dispatch(
          setGameList({
            items: resultAuth.dashboard.highlight,
            oddsChecked: wagers.odds,
          }),
        )
        const countriesResponse = await getCoutriesRemote()
        if (countriesResponse.data.success)
          await dispatch(
            setCountriesList(countriesResponse.data.data as Country[]),
          )
      }
    }

    // não está logado, busca as informações de um usuário deslogado.
    if (isLoadRemoteUnauthParams) {
      const resultUnauth = await getBaseUnauthAttr()
      console.log('MENU', resultUnauth.menu)
      await dispatch(
        setMenu({
          items: resultUnauth.menu,
        }),
      )
      await dispatch(
        setSports({
          items: resultUnauth.menu,
        }),
      )
      await dispatch(setMarkets(resultUnauth.markets))
    }

    await dispatch(setCloseIsCheckRemoteUserLoggedIn())
  }

  useEffect(() => {
    checkLoggedIn()
  }, [])

  return <Loading />
}

export default CheckRemoteLoggedIn
