import { Navigate } from 'react-router-dom'
import { GameNavigator } from '../../Auth/views/GameNavigator'
import MainLayout from '../../Global/layout/MainLayout/MainLayout'
import { Dashboard } from '../../Global/views/Dashboard/views/Dashboard'
import GameDetails from '../../Global/views/Games/views/GameDetails/GameDetails'
import { LiveGames } from '../../Global/views/Live/LiveGames'
import { Championship } from '../../Global/views/Sports/views/Championship/Championship'
import { Sports } from '../../Global/views/Sports/views/Sports'
import { SporstNavigator } from '../../Global/views/Sports/views/SportsNavigator'
import { SportTables } from '../../Global/views/Sports/views/SportTables/SportTables'
import SignUp from '../views/SignUp/SignUp'
import { VerifyAccount } from '../views/VerifyAccount/VerifyAccount'

const RoutesUnauth = {
  path: '/',
  element: <MainLayout isAuth={false} />,
  children: [
    {
      path: '/',
      element: <Dashboard />,
    },
    {
      path: '/sport/:sportRoute',
      element: <Sports />,
    },
    {
      path: 'signup',
      element: <SignUp />,
    },
    {
      path: '/live',
      element: <LiveGames />,
    },
    {
      path: '/mybets',
      element: <Navigate to="/" />,
    },
    {
      path: '/logout',
      element: <Navigate to="/" replace />,
    },
    {
      path: '/sign/active',
      element: <VerifyAccount />,
    },
    // {
    //   path: 'search',
    //   element: <Search />,
    // },
    {
      path: '/games',
      element: <GameNavigator />,
      children: [
        {
          path: '/games/sport',
          element: <SporstNavigator />,
          children: [
            { path: '/games/sport/:sportRoute', element: <Sports /> },
            {
              path: '/games/sport/:sportRoute/:championshipId',
              element: <Championship />,
            },
            {
              path: '/games/sport/:sportRoute/lists/:listType',
              element: <SportTables />,
            },
            {
              path: '/games/sport/:sportRoute/:championshipId/:gameId',
              element: <GameDetails />,
            },
          ],
        },
      ],
    },
  ],
}

export default RoutesUnauth
