import { useEffect, useState } from 'react'
import { SecondaryOddCard } from '../cards/SecondaryOddCard'
import { OddTableProps } from '../types'

export const DefaultOddTable = ({ gameDetails, odds }: OddTableProps) => {
  const [colSize, setColSize] = useState<number>(4)
  const [colSmSize, setColSmSize] = useState<number>(colSize)
  useEffect(() => {
    if (odds.length === 2 || odds.length === 4) {
      // Mudar para par ou impar
      setColSize(6)
      setColSmSize(6)
    }
    if (odds[0].oddName.length > 10) setColSmSize(12)
    // console.log('oddName', odds[0].oddName.length)
  }, [odds])

  return (
    // <div className="gridOddsList">
    <div className="row g-0">
      {odds.map((odd) => {
        // console.log(odd)
        // return <GameDetailsCardOdds key={odd.betGamesOddId} odd={odd} />
        return (
          <SecondaryOddCard
            key={odd.betGamesOddId}
            odd={odd}
            teams={gameDetails.teams}
            showLabel
            styles={`col-md-${colSize} col-${colSmSize}`}
          />
        )
      })}
    </div>
  )
}
