/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import i18next from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setLanguage } from '../../../../../../../core/store/items/user'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'

type Language = 'ptBR' | 'en'

export const Preferences = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const preferences = useAppSelector((state: RootState) => state.user.prefs)
  const prefs = localStorage.getItem('prefs')
  const [isActive, setIsActive] = useState(false)
  const [selected, setSelected] = useState('')
  const [languageSelected, setLanguageSelected] = useState<Language>(
    preferences.language as Language,
  )
  const LanguageList = [
    { label: 'Português', value: 'ptBR' },
    { label: 'Inglês', value: 'en' },
  ]

  const toogleCard = (page: any) => {
    setIsActive(!isActive)
    setSelected(page)
  }

  const changeLanguage = (value: Language) => {
    if (prefs) {
      const prefsObj = JSON.parse(prefs)
      prefsObj.language = value
      localStorage.setItem('prefs', JSON.stringify(prefsObj))
    } else {
      const newPrefs = { ...preferences }
      newPrefs.language = value
      localStorage.setItem('prefs', JSON.stringify(newPrefs))
    }
    dispatch(setLanguage(value))
    i18next.changeLanguage(value).then((translate) => {
      translate('key') // -> same as i18next.t
    })
  }

  return (
    <div className="UserMenuDropDown-dados-flex">
      <div className="UserMenuDropDown-dados-flex-item text-center flex-item-100">
        <div
          role="main"
          className="UserMenuDropDown-config-flex-select"
          onClick={() => toogleCard('lang')}
          onKeyPress={() => toogleCard('lang')}>
          <select
            className="form-field"
            value={languageSelected}
            onChange={(e) => {
              setLanguageSelected(e.target.value as Language)
              changeLanguage(e.target.value as Language)
            }}>
            {LanguageList.map((lang) => {
              return (
                <option key={lang.value} value={lang.value}>
                  {t(lang.label)}
                </option>
              )
            })}
          </select>
          {/* <div className="UserMenuDropDown-config-flex">
            <div className="UserMenuDropDown-config-label">
              <h5>Label</h5>
              <h3>Portuques</h3>
            </div>
            <div className="UserMenuDropDown-config-end">
              <button type="button">
                <AiFillCaretDown />
              </button>
            </div>
          </div>
          <Collapse isOpened={!!(selected === 'lang' && isActive)}>
            <div className="UserMenuDropDown-config-flex card-toogle  card-toogle-open">
              <ul className="list-style">
                <li>Brasil</li>
                <li>Argentina</li>
              </ul>
            </div>
          </Collapse> */}
        </div>
      </div>
    </div>
  )
}

export default Preferences
