import { useEffect, useState } from 'react'
import { GrFormClose } from 'react-icons/gr'
import { ClipLoader } from 'react-spinners'
import { AgentResponse } from '../../SignUp'
import { FormSection } from '../../types'
import { GetAgentInfo } from '../../xhr/SignUp.xhr'

interface AgentFormInfo extends FormSection {
  setValue: any
  watch: any
  setDisableSubmit: any
}
export const AgentInfo = ({
  watch,
  setValue,
  register,
  setDisableSubmit,
}: AgentFormInfo) => {
  const [agent, setAgent] = useState<AgentResponse>({} as AgentResponse)
  const [disableAgent, setDisableAgent] = useState<boolean>(false)
  const [agentNotFound, setAgentNotFound] = useState(false)
  const [agentIsLoading, setAgentIsLoading] = useState<boolean>(false)
  // const a
  const params = new URL(document.location.toString()).searchParams
  // console.log(params.get('agent'))

  const agentCode = params.get('agent') ?? ''
  // console.log('agentCode', agentCode)

  const GetAgent = async (code: string) => {
    try {
      await setAgentIsLoading(true)
      await setDisableSubmit(true)
      await setDisableAgent(true)
      const response: AgentResponse = await GetAgentInfo(code)
      await setDisableSubmit(false)
      await setDisableAgent(false)
      await setAgentIsLoading(false)

      // console.log(response)
      if (response.success) {
        setAgent(response)
        setValue('agentId', response.id)
        setValue('agentCode', watch('agentCode').toUpperCase())
        setDisableAgent(true)
        setAgentNotFound(false)
      } else {
        setDisableAgent(false)
        setAgentNotFound(true)
      }
    } catch (err) {
      console.log(err)
      await setAgentIsLoading(false)
    }
  }

  const handleBlur = async (code: string) => {
    if (code) await GetAgent(code)
    await setDisableSubmit(false)
  }

  const handleRemoveAgent = () => {
    setAgent({} as AgentResponse)
    setDisableAgent(false)
    setValue('agentId', 0)
    setValue('agentCode', '')
  }

  useEffect(() => {
    if (agentCode) {
      GetAgent(agentCode)
      setValue('agentCode', agentCode)
    }
  }, [agentCode])

  useEffect(() => {
    if (watch('agentCode') === '') setAgentNotFound(false)
  }, [watch('agentCode')])

  return (
    <div>
      <div key={agentCode} className="col-md-12">
        {!disableAgent && (
          <div className="inputContainer" style={{ marginBottom: '5px' }}>
            <input
              autoComplete="off"
              onFocus={() => setDisableSubmit(true)}
              disabled={disableAgent}
              className="form-field"
              type="text"
              placeholder=" "
              {...register('agentCode')}
              onBlur={() => handleBlur(watch('agentCode'))}
            />
            <label className="formPlaceholder" htmlFor="agentCode">
              Código de Agente
            </label>
          </div>
        )}
        {agentIsLoading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1.5rem',
              paddingRight: '1rem',
              color: 'orange',
            }}>
            <p> Carregando</p>
            <ClipLoader
              color="orange"
              loading={agentIsLoading}
              // css={override}
              size={40}
            />
          </div>
        )}
        {watch('agentCode') && agentNotFound && (
          <p className="input-error-warning">Agente não encontrado</p>
        )}
      </div>
      {agent && disableAgent && !agentIsLoading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem 0',
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
            }}>{`AGENTE: ${agent.name?.toUpperCase()}`}</div>
          <div>
            <GrFormClose
              onClick={() => handleRemoveAgent()}
              className="dangerIcon"
              style={{ fontSize: '1.5rem', color: 'red' }} // Style not working
            />
          </div>
        </div>
      )}
      <input
        className="form-field d-none"
        type="text"
        placeholder="Código de Agente"
        {...register('agentId')}
      />
    </div>
  )
}
