import { MainInput } from '../../../../../Global/components/Inputs/MainInput'
import { FormSection } from '../../types'

export const ContactInfo = ({ errors, register }: FormSection) => {
  return (
    <div>
      <legend className="form-legend">Informação para contato</legend>
      <MainInput
        type="email"
        name="email"
        register={register}
        placeholder="Email"
        errors={errors}
        className="col-md-12"
      />
      <MainInput
        type="text"
        name="phone"
        label="Número de Telefone"
        register={register}
        errors={errors}
        className="col-md-12"
      />
      <hr className="solid" />
    </div>
  )
}
