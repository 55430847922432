import SportItem from '../../../app/bet/Global/views/Sports/types/sport'

interface Props {
  sport: SportItem
}

const PageTitle = ({ sport }: Props) => {
  return (
    <div
      className="pagestitle"
      style={{
        backgroundImage:
          "url('https://betkingbr.mobtex.com/public/img/bg/d46736f2-b727-42a8-936c-5744f6ee7c61.jpg')",
      }}>
      <div className="card-offer-text">
        <span>{sport.name}</span>
        {/* <h1>{sport.name}</h1> */}
      </div>
    </div>
  )
}

export default PageTitle
