import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../../../../../common/functions/formatting'
import {
  RootState,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'

export const CashbackHeader = () => {
  const wallet = useAppSelector((state: RootState) => state.wallet)
  const cash = formatCurrency(wallet.cash)
  const cashback = formatCurrency(wallet.cashback)

  return (
    <Link to="/bank/funds" className="col-6  col-sm-4  cashbackHeader ">
      <p className="col-12 ">Saldo: {cash}</p>
      <p className="col-12">Cashback: {cashback}</p>
    </Link>
  )
}
