import { FormSection } from '../../types'

interface AcceptTerms extends FormSection {
  value: boolean
  setValue: any
  trigger: any
}

export const AcceptTerms = ({
  errors,
  register,
  value,
  setValue,
  trigger,
}: AcceptTerms) => {
  return (
    <div>
      <div className="row">
        <div className="col-1">
          <input
            type="checkbox"
            name="acceptTerms"
            onChange={(e) => {
              setValue('acceptTerms', e.target.checked)
              trigger('acceptTerms')
            }}
            // value={value.toString()}
            {...register('acceptTerms')}
            id="acceptTerms"
            className={`form-check-input ${
              errors.acceptTerms ? 'is-invalid' : ''
            }`}
          />
        </div>
        <span
          className="form-description col-11"
          style={{ textAlign: 'justify' }}>
          Tenho mais de 18 anos e li, compreendo e aceito os{' '}
          <a href="www.google.com">termos e condições</a>,
          <a href="www.google.com">Regras</a>,{' '}
          <a href="www.google.com">Políticas de Cookies</a> e as políticas
          relacionadas a Verificação de <a href="www.google.com">idade</a> e{' '}
          <a href="www.google.com"> identidade</a>
        </span>
      </div>
      <p className="input-error-warning">{errors.acceptTerms?.message}</p>
    </div>
  )
}
