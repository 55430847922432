import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreditsItem, PaymentsMethod } from '../types/bank'

export const initialBalance: CreditsItem = {
  id: 0,
  name: '',
  balance: 0.0,
}

export const initialState = {
  paymentMethods: [] as PaymentsMethod[],
}

export const paymentReducer = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentMethods: (
      state,
      action: PayloadAction<{ paymentMethods: PaymentsMethod[] }>,
    ) => {
      state.paymentMethods = action.payload.paymentMethods
    },
  },
})

export const { setPaymentMethods } = paymentReducer.actions

export default paymentReducer.reducer
