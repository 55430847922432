/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// import countriesList from '../../../../../common/Data/countriesArray.json'
import { setMenuLeft } from '../../../../../core/store/items/app'
// import { updatePreferences } from '../../xhr/dados.xhr'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../core/store/Reducers'
import { setHideWager } from '../../../Global/views/Wagers/store/wagers'
import { FormSignLarge } from './components/FormTypes/Large/FormSignLarge'
import { FormSignSmall } from './components/FormTypes/Minimal/FormSignSmall'
import './SignUp.css'

export type AgentResponse = {
  name: string
  id: number
  success: boolean
}

const SignUp = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setMenuLeft(false))
    dispatch(setHideWager(true))
  }, [location.pathname])

  const app = useAppSelector((state: RootState) => state.app)

  const { loginFormType } = app

  // alert(loginFormType)
  const getFormSign = () => {
    switch (loginFormType) {
      case 'large':
        return <FormSignLarge />
      default:
        return <FormSignSmall />
    }
  }
  return (
    <div
      style={{
        backgroundColor: '#ebeded',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
      }}>
      <div className="signUpContainer">
        <div className="row signUpRow g-2">{getFormSign()}</div>
      </div>
    </div>
  )
}

export default SignUp
