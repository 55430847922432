export const Verification = () => {
  return (
    <div className="text-usage container g-0">
      <h1 className="minimalTitle d-none d-md-block">Verificação</h1>
      {/* <h5 className="config-subttitle">Verificação de Sua Conta</h5> */}
      <p
        style={{
          padding: 3,
        }}>
        A sua conta encontra-se verificada de acordo com o Processo de
        Identificação.
      </p>
      <p>
        Por favor considere que, em certas circunstâncias, é possível que seja
        requerida informação adicional da sua parte, em concordância com as
        condições da nossa licença. Entraremos em contato com você se este for o
        caso.
      </p>
    </div>
  )
}
