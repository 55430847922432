import axios from 'axios'
import apiHelper from '../../../../../auth/services/apiHelper'
import { GameItemInfo, GameItemTeams } from '../Games/types/games'

/**
 * Retorna os atributos quando o usuário está logado.
 * @param token
 * @returns
 */

export type SearchItem = {
  info: GameItemInfo
  teams: GameItemTeams[]
}

const token = localStorage.getItem('token')

export const searchGamesAPI = async (search: string) => {
  console.log('Query =', search)
  // const formData = new FormData()

  // formData.append('q', search)
  const data = {
    q: search,
  }

  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('search'),
      data,
    })
    .then((r) => {
      return r.data.data as SearchItem[]
    })
    .catch((error) => {
      return error
    })
  return response
}
