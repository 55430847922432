/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { formatCurrency } from '../../../../../../../common/functions/formatting'
import { hasLiveGame } from '../../../../../../../common/functions/odds'
import { Bet } from '../../data'
import { finishBet } from '../../xhr/myBets'

interface Props {
  bet: Bet
}

interface FooterItemProps {
  title: string
  value: number | string
  className?: string
}

interface FooterButtonProps {
  bet: Bet
}
type ButtonState = 'initial' | 'confirm' | 'sending' | 'success' | 'error'

const FooterItem = ({ title, value, className }: FooterItemProps) => {
  return (
    <div className={`bet-card-footer-item ${className}`}>
      <p> {title}</p>
      <p>{formatCurrency(value)}</p>
    </div>
  )
}
FooterItem.defaultProps = {
  className: 'col-6 col-md-4',
}

const FooterButton = ({ bet }: FooterButtonProps) => {
  const [isLive, setisLive] = useState<boolean>(false)
  const initialLabel = isLive ? 'Encerrar Aposta' : 'Cancelar Aposta'
  const sendingLabel = isLive ? 'Encerrando Aposta' : 'Cancelando Aposta'
  const successLabel = isLive ? 'Aposta Encerrada' : 'Aposta Cancelada'
  const errorLabel = isLive ? 'Falha ao Encerrar' : 'Falha ao Cancelar'
  const confirmButtonLabel = 'Confirmar'
  const initialBoldLabel = bet.betInfo.returnOffered
    ? formatCurrency(bet.betInfo.returnOffered)
    : ''
  const [buttonText, setButtonText] = useState<string>(initialLabel)
  const [buttonBoldText, setButtonBoldText] = useState<string>(initialBoldLabel)
  const [buttonState, setButtonState] = useState<ButtonState>('initial')
  useEffect(() => {
    setisLive(hasLiveGame(bet.odds))
  }, [])

  const chooseButtonLabel = () => {
    switch (buttonState) {
      case 'initial': {
        setButtonText(initialLabel)
        setButtonBoldText(initialBoldLabel)
        break
      }
      case 'confirm': {
        setButtonText(confirmButtonLabel)
        setButtonBoldText(initialBoldLabel)
        break
      }
      case 'sending': {
        setButtonText(sendingLabel)
        setButtonBoldText('')
        break
      }
      case 'success': {
        setButtonText(successLabel)
        setButtonBoldText('')
        break
      }
      case 'error': {
        setButtonText(errorLabel)
        setButtonBoldText('')
        break
      }
      default: {
        setButtonText(initialLabel)
        setButtonBoldText(initialBoldLabel)
      }
    }
  }
  const handleFinishBet = async () => {
    try {
      setButtonState('sending')
      const response = await finishBet(bet)
      console.log(response)
      setButtonState('success')
    } catch (err) {
      setButtonState('error')
      console.log(err)
    }
  }
  const handleClickButton = async () => {
    switch (buttonState) {
      case 'initial': {
        setButtonState('confirm')
        break
      }
      case 'confirm': {
        await handleFinishBet()
        break
      }
      default: {
        setButtonState('initial')
      }
    }
  }

  useEffect(() => {
    chooseButtonLabel()
  }, [buttonState])

  return (
    <div className="col-12 col-sm-6 col-lg-4">
      <div
        role="button"
        className="button-container"
        onClick={() => handleClickButton()}>
        <p>
          {buttonText}
          <b>{buttonBoldText}</b>
        </p>
        {buttonState === 'sending' && <ClipLoader color="orange" />}
      </div>
    </div>
  )
}

export const BetCardFooter = ({ bet }: Props) => {
  const { betStatus } = bet.betInfo
  const hasOfferedReturns = bet.betInfo.returnOffered
  const itemClassname = hasOfferedReturns ? 'col-6 col-sm-3 col-lg-4' : 'col-6'
  const returnTitle =
    betStatus === 'lose' || betStatus === 'win' ? 'Retornos Obtido' : 'Retornos'

  return (
    <div className="bet-card-footer row g-0 justify-content-center pt-2 pb-2">
      <FooterItem
        title="Aposta"
        value={bet.betInfo.price}
        className={itemClassname}
      />
      <FooterItem
        title={returnTitle}
        value={bet.betInfo.potencialReturns}
        className={itemClassname}
      />
      {!!bet.betInfo.returnOffered &&
        bet.betInfo.returnOffered !== 0 &&
        betStatus !== 'lose' &&
        betStatus !== 'win' && <FooterButton bet={bet} />}
    </div>
  )
}
