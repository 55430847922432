import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import '../../../../common/config.css'
import { setMenuLeft } from '../../../../core/store/items/app'
import { useAppDispatch } from '../../../../core/store/Reducers'
import { MenuLeft } from '../../Global/layout/menu/MenuLeft/menuLeft'
import { setHideWager } from '../../Global/views/Wagers/store/wagers'

type AuthRoute = {
  label: string
  route: string
}

interface Props {
  title: string
  routes: AuthRoute[]
}

// type OutletContextType = { currentRoute: menuLeft; isMobile: boolean }

export const AuthNavigator = ({ title, routes }: Props) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [menuMobileOpen, setMenuMobileOpen] = useState(false)
  const [isMobile, setisMobile] = useState(false)
  const [currentSection, setCurrentSection] = useState('')
  const [currentRoute, setCurrentRoute] = useState(routes[0])

  const handleToggleMenuMobile = () => {
    setMenuMobileOpen(!menuMobileOpen)
    setisMobile(true)
  }

  console.log(routes)

  const getCurrentRoute = () => {
    // console.log('ROUTES', routes)
    const current = routes.filter(
      (route) => route.route === location.pathname.split('/')[2],
    )
    // console.log('Current', current)
    setCurrentRoute(current[0])
  }

  useEffect(() => {
    dispatch(setMenuLeft(false))
    dispatch(setHideWager(true))
    getCurrentRoute()
    setCurrentSection(location.pathname.split('/')[2])
  }, [location.pathname])

  // console.log('Title', location.pathname.split('/')[2])
  const maxWidth = currentSection === 'balance' ? '830px' : '530px'
  return (
    <div
      className="config-container"
      style={{ overflowY: 'auto', height: '100%' }}>
      <div className="container  g-4 col-12 col-md-11 col-lg-8">
        <div
          className="row"
          style={{
            padding: '1.5rem 0rem',
          }}>
          <div
            className="col-12 col-md-3"
            style={{ margin: '0px', padding: '0px' }}>
            <h1 className="navgator-title d-none d-md-block">{title}</h1>
            <MenuLeft
              items={routes}
              currentRoute={currentRoute}
              selectedPage={location.pathname.split('/')[2]}
              title={title}
              menuMobileOpen={menuMobileOpen}
              handleToggleMenuMobile={handleToggleMenuMobile}
              isMobile={isMobile}
            />
          </div>
          <div className="col-12 col-md-9" style={{ maxWidth }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
  // return (
  //   <div className="card-sub-pages-flex">
  //     <div className="card-sub-pages-flex-item navbar-left">
  //       <h1>Banco</h1>
  //       <MenuLeft items={menuItems} selectedPage={location.pathname} />
  //     </div>
  //     <div className="card-sub-pages-flex-item">
  //       <Outlet />
  //     </div>
  //   </div>
  // )
}
