import axios from 'axios'
import apiHelper from '../../../../../../auth/services/apiHelper'

/**
 * Retorna os atributos quando o usuário está logado.
 * @param token
 * @returns
 */

export const getSportConf = async (
  token: string,
  route: string | undefined,
) => {
  const formData = new FormData()

  // alert(route);
  if (route) {
    formData.append('route', route)
  }

  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('sport/item'),
      data: formData,
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export default getSportConf
