import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaUser } from 'react-icons/fa'
import * as yup from 'yup'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import { SubmitButton } from '../../../../Global/components/buttons/SubmitButton'
import { CPFInput } from '../../../../Global/components/Inputs/CPFInput'
import { MainInput } from '../../../../Global/components/Inputs/MainInput'
import { PasswordInput } from '../../../../Global/components/Inputs/PasswordInput'
import { PhoneInput } from '../../../../Global/components/Inputs/PhoneInput'
import { updatePersonalData } from '../xhr/dados.xhr'

export type PersonalFormData = {
  phone: string
  email: string
  password: string
  cpf: string
}

export const PersonalData = () => {
  const dispatch = useAppDispatch()

  const user = useAppSelector((state: RootState) => state.user)
  // console.log('CPF', user.info.cpf)
  const [loading, setLoading] = useState<boolean>(false)
  const requiredMsg = 'Esse campo é obrigatório!'
  const emailMsg = 'O e-mail deve seguir o formato example@email.com'
  const minLenghtMsg = 'Muito curto'

  const iconColor = 'orange'
  const iconSize = 20

  const schema = yup
    .object({
      countryCode: yup.string().required(requiredMsg),
      phone: yup.string().required(requiredMsg).min(11, minLenghtMsg),
      email: yup.string().required(requiredMsg).email(emailMsg),
      password: yup.string().required(requiredMsg).min(1, minLenghtMsg),
    })
    .required()

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    control,
  } = useForm<PersonalFormData>({ resolver: yupResolver(schema) })

  const onSubmit = async (data: PersonalFormData) => {
    console.log(data)
    setLoading(true)
    const response = await updatePersonalData(user.token, data)
    setLoading(false)
    console.log(response)
    // const response = await updatePersonalData(user.token, auxData)
    // if (!response.success) alert(response.msg)
    // else console.log(response)
  }
  // console.log('USER', user)
  return (
    <>
      <h1 className="minimalTitle">Dados Pessoais</h1>
      <div className="container g-0">
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit(onSubmit)}
          noValidate>
          <div className="row">
            <PhoneInput
              name1="countryCode"
              name2="phone"
              defaultValue2={parseInt(user.info.phone, 10)}
              setValue={setValue}
              register={register}
              errors={errors}
              trigger={trigger}
              className="row"
            />

            <MainInput
              leftIcon={<FaUser size={iconSize} color={iconColor} />}
              type="text"
              name="email"
              defaultValue={user.info.email}
              register={register}
              placeholder="E-mail"
              errors={errors}
              className="col-12"
            />

            <CPFInput
              name="cpf"
              errors={errors}
              control={control}
              setValue={setValue}
              trigger={trigger}
              disabled={user.info.cpf?.length >= 11}
              defaultValue={user.info.cpf}
            />

            <PasswordInput
              name="password"
              register={register}
              placeholder="Senha"
              errors={errors}
              className="col-12"
              hideRightIcon
            />

            <SubmitButton
              loading={loading}
              text="Atualizar"
              disableSubmit={Object.entries(errors).length > 0 || loading}
            />
          </div>
        </form>
      </div>
    </>
  )
}
