import axios from 'axios'
import apiHelper from '../../../../../../core/services/apiHelper'
import { PasswordFormData } from '../items/ChangePassword'
import { AddressFormData } from '../items/Endereco'
import { PersonalFormData } from '../items/PersonalData'
import { PreferencesFormData } from '../items/Preferences/Preferences'

/**
 *  Conta do usuário
 * @param token
 * @returns
 */

export const updatePersonalData = async (
  token: string,
  data: PersonalFormData,
) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('account/generalData'),
      data,
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const updateAddress = async (token: string, data: AddressFormData) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('account/address'),
      data,
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const updatePassword = async (token: string, data: PasswordFormData) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('funds/save'),
      data,
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}

export const updatePreferences = async (
  token: string,
  data: PreferencesFormData,
) => {
  const response = await axios
    .request({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      url: apiHelper.baseUrl('funds/save'),
      data,
    })
    .then((r) => {
      return r.data
    })
    .catch((error) => {
      return error
    })
  return response
}
