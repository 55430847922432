import { MainInput } from '../../../../../Global/components/Inputs/MainInput'
import { FormSection } from '../../types'

export const LoginInfo = ({ errors, register }: FormSection) => {
  return (
    <div>
      <legend className="form-legend">Criar Login</legend>
      <MainInput
        type="text"
        name="username"
        register={register}
        placeholder="Nome de Usuário"
        errors={errors}
        className="col-md-12"
      />

      <MainInput
        type="password"
        name="password"
        register={register}
        placeholder="Senha"
        errors={errors}
        className="col-md-12"
      />
      <hr className="solid" />
    </div>
  )
}
