/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Notify } from '../../../../../../common/components/toats/notify'
import {
  onHideModalLogin,
  setModalForgot,
  setModalLogin,
} from '../../../../../../core/store/items/app'
import { setLoggedIn } from '../../../../../../core/store/items/user'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../core/store/Reducers'
import { SubmitButton } from '../../../../Global/components/buttons/SubmitButton'
import { MainInput } from '../../../../Global/components/Inputs/MainInput'
import { PasswordInput } from '../../../../Global/components/Inputs/PasswordInput'
import { signInSchema } from '../validation'
import { onPostFormLoginRequest } from '../xhr/auth.xhr'

export interface LoginFormData {
  email: string
  password: string
}

export const FormLogin = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const user = useAppSelector((state: RootState) => state.user)
  const { t, i18n } = useTranslation()
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormData>({
    resolver: yupResolver(signInSchema),
  })

  const onPostFormLogin = async (formData: LoginFormData) => {
    setLoading(true)
    if (formData) {
      const resultLogged = await onPostFormLoginRequest(formData)
      //   setOnPostError(undefined)
      if (resultLogged.data.success) {
        dispatch(onHideModalLogin())
        await dispatch(
          setLoggedIn({
            token: resultLogged.data.token,
            info: resultLogged.data.user,
            isLogged: true,
            prefs: resultLogged.data.prefs,
          }),
        )
        Notify({ type: 'success', message: resultLogged.data.msg })

        // i18n.changeLanguage(resultLogged.data.prefs.language)
        i18n.changeLanguage(user.prefs.language)
      } else {
        console.log('resultLogged', resultLogged)
        Notify({ type: 'error', message: resultLogged.data.msg })
      }
    }
    setLoading(false)
  }

  const handleOpenForgot = () => {
    dispatch(setModalForgot(true))
    dispatch(setModalLogin(false))
  }

  return (
    <form id="formBody" onSubmit={handleSubmit(onPostFormLogin)}>
      <MainInput
        type="text"
        name="email"
        register={register}
        placeholder="Email ou nome de usuário"
        errors={errors}
        className="col-md-12"
      />
      <PasswordInput
        name="password"
        register={register}
        placeholder="Senha"
        errors={errors}
        className="col-md-12"
      />
      <div className="formLoginInputBox">
        <SubmitButton
          loading={loading}
          text="ENTRAR"
          disableSubmit={Object.keys(errors).length > 0 || loading}
        />
      </div>
      {/* {onPostError && <LoginErrorBox />} */}
      <div id="modalBoxForgot">
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleOpenForgot()
          }}
          className="Link"
          // to="/forgot"
        >
          Esqueceu a senha?
        </span>
      </div>
    </form>
  )
}
