import * as yup from 'yup'

const requiredMsg = 'Esse campo é obrigatório!'
const emailMsg = 'O e-mail deve seguir o formato example@email.com'
const minPassLengthMsg = 'A senha deve ter no mínimo 6 caracteres'
const maxPassLengthMsg = 'A senha deve ter no máximo 32 caracteres'
const lenghtCodeMsg = 'O código deve ter exatamente 4 caracteres'
const termErrorMsg = 'É necessário aceitar os termos para prosseguir'

export const registerUserSchema = yup
  .object({
    country: yup.number().required(requiredMsg),
    firstname: yup.string().required(requiredMsg),
    lastname: yup.string().required(requiredMsg),
    // Validade to only allow people over 18 years old
    birthdate: yup.string().required(requiredMsg),
    // Validate CPF pattern?
    cpf: yup.string().required(requiredMsg),
    email: yup.string().required(requiredMsg).email(emailMsg),
    // Make a special field for phone??
    houseNumber: yup.string().required(requiredMsg),
    phone: yup.string().required(requiredMsg),
    streetName: yup.string().required(requiredMsg),
    // Find by postal code?
    postalCode: yup.string().required(requiredMsg),
    // Verify if it already exists on database
    username: yup.string().required(requiredMsg),
    // Verify password pattern
    password: yup
      .string()
      .required(requiredMsg)
      .min(6, minPassLengthMsg)
      .max(32, maxPassLengthMsg),
    secretCode: yup
      .string()
      .required(requiredMsg)
      .min(4, lenghtCodeMsg)
      .max(4, lenghtCodeMsg),
    confirmSecretCode: yup
      .string()
      .required(requiredMsg)
      .oneOf([yup.ref('secretCode'), null], 'Os códigos devem ser iguais')
      .min(4, lenghtCodeMsg)
      .max(4, lenghtCodeMsg),
    acceptTerms: yup.bool().oneOf([true], termErrorMsg),
    sendPromotions: yup.boolean().nullable().required(requiredMsg),
  })
  .required()
