/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import NumberFormat from 'react-number-format'
import apiHelper from '../../../../../../../../auth/services/apiHelper'
import { formatCurrency } from '../../../../../../../../common/functions/formatting'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../core/store/Reducers'
import { decreaseCash } from '../../../../../../Auth/views/Wallet/store/wallet'
import { onChangeOddPrice } from '../../../../Games/store/gameList'
import { oddChecked, Odds, OddsPersisted } from '../../../../Games/types/odds'
import {
  addErrorMarkOnInputOddPrice,
  removeErrorMarkOnInputOddPrice,
} from '../../../helpers/oddsCheckeds'
import { setNotEnoughCash } from '../../../store/wagers'
import { sanitizeKeyboardInnerText } from '../KeyBoard/helpers/keyboard'
import { KeyBoard } from '../KeyBoard/KeyBoard'
import { CashbackToggle } from './components/Cashback/Cashback'
import { SubmitedFooterInfo } from './SubmitedFooterInfo'
import './WagersFooter.css'

interface Props {
  total: number
  oddsChecked: oddChecked[]
  refWagerModal: React.MutableRefObject<HTMLDivElement | undefined>
  hasGrow: boolean
  handleSubmitState: () => void
  submited: boolean
}

const WagersFooter = ({
  total,
  oddsChecked,
  refWagerModal,
  hasGrow,
  handleSubmitState,
  submited,
}: Props) => {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state: RootState) => state.wallet)
  const btnSubmitDefaultLabel = 'Fazer aposta'
  const [btnSubmitLabel, setBtnSubmitLabel] = useState(btnSubmitDefaultLabel)
  const [pricesSum, setpricesSum] = useState<number>(0)

  const [disableSubmit, setDisableSubmit] = useState(true)
  const [fixedDecimal, setFixedDecimal] = useState<boolean>(false)
  const wagers = useAppSelector((state: RootState) => state.wagers)
  const user = useAppSelector((state: RootState) => state.user)
  const refOddPrice = useRef<HTMLInputElement | null>(null)
  const [isLoaded, setIsLoaded] = useState(false)
  // const isMobile1 = true
  const item = oddsChecked[0]

  const onSubmit = async () => {
    if (!wagers.pricesSum) return
    if (wallet.cash + wallet.cashback < wagers.pricesSum) {
      console.log('Sem saldo o suficiente')
      dispatch(setNotEnoughCash(true))
      return
    }

    const wagersToClose = [] as OddsPersisted[]
    // Handle post simple
    oddsChecked.forEach(({ odd }) => {
      if (odd.price) {
        wagersToClose.push({
          betGamesOddId: [odd.betGamesOddId],
          price: odd.price,
          rate: odd.rate,
        })
      }
    })
    // Handle post multiples
    Object.entries(wagers.multiples).forEach((multiple) => {
      multiple[1].forEach(({ combination, price, rate }) => {
        if (price) {
          wagersToClose.push({
            betGamesOddId: combination,
            price,
            rate: rate ?? 0,
          })
        }
      })
    })

    // console.log(wagersToClose)
    if (!wagersToClose.length) {
      addErrorMarkOnInputOddPrice({ refWagerModal })

      // alert('Erro!')
    } else {
      removeErrorMarkOnInputOddPrice({ refWagerModal })

      const formData = new FormData()
      formData.append('wagers', JSON.stringify(wagersToClose))
      formData.append('cashbackActive', JSON.stringify(wagers.cashbackActive))

      console.log('formData', formData)
      axios
        .request({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user.token}`,
          },
          url: apiHelper.baseUrl('wagers/save'),
          data: formData,
        })
        .then((r) => {
          handleSubmitState()
          if (wagers.pricesSum) {
            dispatch(
              decreaseCash({
                amount: wagers.pricesSum,
                cashbackActive: wagers.cashbackActive,
              }),
            )
          }
          // console.log('data', r.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  useEffect(() => {
    const handleDisableSubmit = () => {
      if (oddsChecked[0]) {
        if (!hasGrow) {
          if (oddsChecked[0].odd.price === undefined) {
            setDisableSubmit(true)
            // return
          } else {
            setDisableSubmit(false)
          }
        } else setDisableSubmit(false)
      }
    }
    handleDisableSubmit()
  }, [oddsChecked, hasGrow])

  useEffect(() => {
    // console.log('Disable Submit', disableSubmit)
  }, [disableSubmit])

  useEffect(() => {
    // console.log(wagers.totalPrize)
    if (total > 1) {
      setBtnSubmitLabel('Fazer aposta')
    } else {
      setBtnSubmitLabel(btnSubmitDefaultLabel)
    }
  }, [total])

  const handlerPriceChange = async (odd: Odds, price: number | undefined) => {
    await dispatch(
      onChangeOddPrice({
        odd,
        price,
      }),
    )
  }

  useEffect(() => {
    function handlerMobileTinyKeyboard(e: { target: any }) {
      if (refWagerModal.current) {
        // INICIO - TECLADO DA LINHA EM QUESTÃO - ATUAL
        const keyboardElActual =
          refWagerModal.current.querySelector<HTMLDivElement>(
            'div[class=keyboard_tiny]',
          )

        if (keyboardElActual) {
          // mostre o teclado...
          keyboardElActual.style.display = 'block'
          // keyboardElActual.style.maxHeight = 'auto'
          // keyboardElActual.style.transition = 'max-height 1.5s ease-in'
          // keyboardElActual.style.transform = `transition: 1 1`

          // todos os botoões do teclado atual
          const allButtonActualKeyboard =
            keyboardElActual.querySelectorAll<HTMLButtonElement>('button')

          // valor final da ODD digitada em cada botão é armazenada neste campo
          let priceUserInput = ''

          const decimalSize = 2
          const decimalSeparator = ','
          // intera todos botões e binda o clique deles
          allButtonActualKeyboard.forEach((btnKeyboard) => {
            btnKeyboard.addEventListener('click', function () {
              const innerText = btnKeyboard.dataset.value ?? ''
              // console.log('innerText')
              // console.log(innerText)
              // console.log(priceUserInput)
              // console.log(priceUserInput.length)
              // console.log([',', '0'].indexOf(innerText))

              if (innerText === 'end') {
                keyboardElActual.style.display = 'none'
                if (!e.target.value) return

                e.target.value = parseFloat(e.target.value.replace(',', '.'))
                  .toFixed(2)
                  .replace('.', ',')
                console.error(typeof e.target.value)
                return
              }

              console.log(innerText)
              priceUserInput = sanitizeKeyboardInnerText(
                priceUserInput,
                innerText,
                decimalSeparator,
                decimalSize,
              )

              e.target.value = priceUserInput
              // atualiza o estado no redux com o novo valor
              handlerPriceChange(
                item.odd,
                parseFloat(priceUserInput.replace(',', '.')),
              )
            })
          })
        }
      }
    }
    if (isLoaded && refOddPrice && refOddPrice.current) {
      refOddPrice.current.addEventListener('click', handlerMobileTinyKeyboard)
    }
    return () => {
      if (isLoaded && refOddPrice && refOddPrice.current) {
        refOddPrice.current.removeEventListener(
          'click',
          handlerMobileTinyKeyboard,
        )
      }
    }
    //   // if (index === 0) setFixedDecimal(true)
  }, [isLoaded])

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    // console.log('PRICE SUM', wagers.pricesSum)
    if (wagers.pricesSum) setpricesSum(wagers.pricesSum)
    else setpricesSum(0)
  }, [wagers.pricesSum])

  const classButtonColSize = total === 1 && !hasGrow ? 'col-6' : 'col-12'
  return (
    <div id="wager_footer" className="container g-0">
      {!submited ? (
        <div className="row g-0">
          <CashbackToggle />
          {total === 1 && !hasGrow && (
            <div className="col-6 footer-col">
              {isMobile ? (
                <div className="input-box">
                  <span className="prefix">R$</span>
                  <input
                    style={{ textAlign: 'left' }}
                    ref={refOddPrice}
                    readOnly={isMobile}
                    className="oddPriceFooter oddPrice oddPriceMobile"
                    placeholder="Valor da aposta"
                    defaultValue=""
                  />
                </div>
              ) : (
                <NumberFormat
                  getInputRef={refOddPrice}
                  readOnly={isMobile}
                  className="oddPriceFooter"
                  isNumericString
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={fixedDecimal}
                  onBlur={() => setFixedDecimal(true)}
                  onFocus={() => setFixedDecimal(false)}
                  onValueChange={(v) => {
                    handlerPriceChange(oddsChecked[0].odd, v.floatValue)
                  }}
                  defaultValue={oddsChecked[0].odd.price}
                  prefix="R$ "
                  placeholder="Valor da aposta"
                />
              )}
            </div>
          )}
          <div className={`footer-col ${classButtonColSize}`}>
            <button
              disabled={disableSubmit}
              type="submit"
              value="Submit"
              onClick={onSubmit}>
              <div style={{ display: 'flex' }}>
                <h4 className="buttonFooterLabel">{btnSubmitLabel}</h4>
                {/* {pricesSum && ( */}
                {total > 1 && hasGrow && (
                  <h4
                    className="buttonFooterLabel"
                    style={{
                      color: 'white',
                      paddingLeft: '10px',
                    }}>{`${formatCurrency(pricesSum ?? 0)}`}</h4>
                )}
                {/* )} */}
              </div>
              <div className="potencialProfit">
                {wagers.totalPrize
                  ? `Potencial Retorno ${formatCurrency(wagers.totalPrize)}`
                  : ''}
              </div>
            </button>
          </div>
        </div>
      ) : (
        <SubmitedFooterInfo
          totalSpent={wagers.pricesSum}
          potencialIncomes={wagers.totalPrize}
        />
      )}
      {isMobile && !hasGrow && !submited && (
        <div className="keyboard_tiny">
          <KeyBoard full />
        </div>
      )}
    </div>
  )
}

export default WagersFooter
// const forwardedRef = React.forwardRef(WagersFooter)
// export default forwardedRef

// wrapping the Input component with forwardRef
// const WagersFooter = React.forwardRef(WagersFooterItem)

// // Exporting the wrapped component
// export default WagersFooter
