/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaUser } from 'react-icons/fa'
import { Notify } from '../../../../../../../../common/components/toats/notify'
import { setModalLogin } from '../../../../../../../../core/store/items/app'
import { useAppDispatch } from '../../../../../../../../core/store/Reducers'
import { SubmitButton } from '../../../../../../Global/components/buttons/SubmitButton'
import { CPFInput } from '../../../../../../Global/components/Inputs/CPFInput'
import { MainInput } from '../../../../../../Global/components/Inputs/MainInput'
import { PasswordInput } from '../../../../../../Global/components/Inputs/PasswordInput'
import { PhoneInput } from '../../../../../../Global/components/Inputs/PhoneInput'
import { RegisterFormData } from '../../../types'
import { SignUser } from '../../../xhr/SignUp.xhr'
import { AcceptTerms } from '../../FormSections/AcceptTerms'
import { AgentInfo } from '../../FormSections/AgentInfo'
import { SuccessfulSubmit } from '../../Submit/SuccessfulSubmit'
import './style.scss'
import { registerUserSchema } from './yup'

export const FormSignSmall = () => {
  const dispatch = useAppDispatch()
  // const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [submited, setSubmited] = useState<boolean>(false)

  const iconColor = 'orange'
  const iconSize = 20

  const onSubmit = async (dataUser: RegisterFormData) => {
    setLoading(true)
    setDisableSubmit(true)
    // console.log(dataUser)
    if (dataUser) {
      const signResponse = await SignUser(dataUser)

      if (signResponse.success) {
        setSubmited(true)

        // Toast
        // Notify({ type: 'success', message: signResponse.msg })
      } else {
        setDisableSubmit(false)

        // Toast

        Notify({ type: 'error', message: signResponse.msg })
      }
    }
    setLoading(false)
  }

  const {
    register,
    trigger,
    watch,
    setValue,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<RegisterFormData>({
    resolver: yupResolver(registerUserSchema),
    defaultValues: {
      country: 33,
      pronoun: 'Sr.',
    },
  })

  const handleClickLogin = () => {
    dispatch(setModalLogin(true))
  }

  return (
    <div
      style={{ backgroundColor: '#ebeded', overflowY: 'auto', height: '100%' }}>
      <div className="container signUpContainer">
        <div className="signUpRow">
          {submited ? (
            <SuccessfulSubmit />
          ) : (
            <form
              style={{ width: '100%' }}
              onSubmit={handleSubmit(onSubmit)}
              noValidate>
              <div className="row">
                <h1 className="minimalTitle">Abrir conta</h1>
                <MainInput
                  leftIcon={<FaUser size={iconSize} color={iconColor} />}
                  type="text"
                  name="email"
                  register={register}
                  placeholder="E-mail"
                  errors={errors}
                  className="col-12"
                />
                <PasswordInput
                  name="password"
                  register={register}
                  placeholder="Criar senha"
                  errors={errors}
                  className="col-12"
                />
                <MainInput
                  type="text"
                  name="firstname"
                  register={register}
                  placeholder="Primeiro nome"
                  errors={errors}
                  className="col-12"
                />
                <MainInput
                  type="text"
                  name="lastname"
                  register={register}
                  placeholder="Sobrenome"
                  errors={errors}
                  className="col-12"
                />
                <CPFInput
                  name="cpf"
                  errors={errors}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                />
                <MainInput
                  type="date"
                  name="birthdate"
                  register={register}
                  label="Data de nascimento"
                  errors={errors}
                  className="col-12"
                />
                <MainInput
                  type="text"
                  name="address"
                  register={register}
                  placeholder="Número da casa e nome da rua"
                  errors={errors}
                  className="col-12"
                />
                <PhoneInput
                  name1="countryCode"
                  name2="phone"
                  setValue={setValue}
                  register={register}
                  errors={errors}
                  trigger={trigger}
                  className="row"
                />
                <AgentInfo
                  register={register}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  setDisableSubmit={setDisableSubmit}
                />
                <AcceptTerms
                  register={register}
                  errors={errors}
                  value={watch('acceptTerms')}
                  setValue={setValue}
                  trigger={trigger}
                />
                <SubmitButton
                  disableSubmit={disableSubmit}
                  text="Abrir conta"
                  loading={loading}
                />

                <div className="loginTip">
                  <p>
                    Já possui conta?{' '}
                    <b onClick={() => handleClickLogin()}>Faça login</b>
                  </p>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
