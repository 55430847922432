import { useEffect } from 'react'
import {
  Country,
  setCountriesList,
} from '../../../../../../../core/store/items/countries'
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../core/store/Reducers'
import { getCoutriesRemote } from '../../../../../Global/views/CheckRemoteLoggedIn/countries.xhr'
import { FormSection } from '../../types'

interface CountryInfoType extends FormSection {
  defaultValue?: string
}

export const CountryInfo = ({ register, defaultValue }: CountryInfoType) => {
  const dispatch = useAppDispatch()
  const countryState = useAppSelector((state: RootState) => state.countries)
  const countriesList = countryState.countries
  const getCountryList = async () => {
    const countriesResponse = await getCoutriesRemote()
    console.log('countriesResponse', countriesResponse)
    if (countriesResponse.data.success)
      await dispatch(setCountriesList(countriesResponse.data.data as Country[]))
  }
  useEffect(() => {
    getCountryList()
  }, [])
  return (
    <div>
      <div className="col form-field-padding">
        <label className="form-label" htmlFor="Countries">
          País de Residência
        </label>
        <select
          style={{ backgroundColor: '#ffffff' }}
          className="form-field"
          defaultValue={defaultValue}
          {...register(`countryId`)}>
          {countriesList.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

CountryInfo.defaultProps = {
  defaultValue: 33,
}
