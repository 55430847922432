export const Offers = (): JSX.Element => {
  return (
    <div className="UserMenuDropDown-dados-flex">
      <div className="UserMenuDropDown-dados-flex-item text-center flex-item-100">
        <div className="UserMenuDropDown-config-flex">
          <h4 style={{ fontSize: '1rem' }}>
            No momento não existe ofertas disponíveis
          </h4>
        </div>
      </div>
    </div>
  )
}
